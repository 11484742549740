<template>
  <div v-if="hasAnalyse" class="card-box" :class="isSelected? 'selected': ''" style="display: inline-block; cursor: pointer; margin: 5px 10px;" @click.prevent="settingSelectedAnalyse">
    <span style="display: block"> {{ analyseLibelle }}</span>
    <small class="text-right" style="font-size: 10px">{{ analyse.status }}</small>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        uid: { type: Object, required: true }
    },
    data(){
        return {

        }
    },
    methods: {
        ...mapMutations({
            setAnalyseSelected: 'analyse/SET_SELECTED_ANALYSE'
        }),
        settingSelectedAnalyse(){
            // Logic to select analyse
            console.log("Selected analyse", this.uid);
            this.$emit('selectedAnalyse', this.uid);
            this.setAnalyseSelected(this.uid);
        }
    },
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses',
            typeRecherches: 'sanitaire/typeRecherches',
            examens: 'sanitaire/examens',
            categories: 'sanitaire/categorieAnalyses',
            selectedAnalyse: 'analyse/selectedAnalyse'
        }),
        analyse(){
            return this.analyses.find(a => a.uid === this.uid);
        },
        hasAnalyse(){
            return this.analyse !== null && this.analyse !== undefined
        },
        examen(){
            return this.examens.find(e => e.uid === this.analyse.examen);
        },
        hasExamen(){return this.examen !== null && this.examen !== undefined},
        typeRecherche(){
            return this.typeRecherches.find(t => t.uid === this.analyse.typeRecherche);
        },
        hasTypeRecherche(){return this.typeRecherche !== null && this.typeRecherche !== undefined},
        categorie(){
            return this.categories.find(c => c.uid === this.analyse.categorie);
        },
        hasCategorie(){return this.categorie !== null && this.categorie !== undefined},
        analyseLibelle(){
            if(this.hasExamen) return this.examen.libelle
            if(this.hasTypeRecherche) return this.typeRecherche.libelle
            if(this.hasCategorie) return this.categorie.libelle
            return "Non spécifié"
        },
        isSelected(){
            return this.selectedAnalyse === this.uid
        }

    }
}
</script>

<style scoped>

.selected {
    background-color: #1C6100;
    color: white;
    border: 1px solid #1C6100;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    z-index: 1;
    position: relative;
}

</style>