<template>
    <div 
            v-if="hasSubject" 
            class="closedSession" 
            @click.prevent="showSessionMessages"
        >
        <div class="sender" style="width: 28%;">
            <avatar :user-uid="sender?  sender.uid : 'Unknown'" size="xs" />
            <small class="text-muted font-10 text-truncate mb-0">{{ senderFullname }}</small>
        </div>
        <div class="user-body" style="width: 68%; margin: 0px 0px 0px 10px;">
            <p class="text-muted font-11 strong" style="margin: 0px">{{ subject.libelle }}</p>
            <small class="text-muted font-11 text-truncate mb-0" style="display: block;">{{ lastMessage?.content }}</small>    
            <small class="text-right text-muted font-10">{{ lastMessage?.createdAt|time }}</small> 
        </div>
        
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { GET_CLIENT_LAST_MESSAGE } from '../../graphql/chat';
import Avatar from '../defaultAvatar.vue'
export default {
    props: {
        session: {type: Object, required: true}
    },
    components: {Avatar},
    data() {
        return {
            loadLastMessage: true,
            lastMessage: null,
            error_msg: null
        }
    },
    methods:{
        ...mapMutations({
            setCurrentSession: 'chat/SET_CURRENT_SESSION'
        }),
        showSessionMessages(){
            this.setCurrentSession(this.session.uid)
        }
    },
    mounted() { this.loadLastMessage = false},
    apollo: {
        loadUserLastMessage: {
          query: GET_CLIENT_LAST_MESSAGE,
          skip () {
            return this.loadLastMessage
          },
          variables(){
            return {
                "session": this.session.uid,
            }
          },
          error (error) {
            this.error_msg = JSON.stringify(error.message)
            this.loadLastMessage = true
          },
          update ({lastSessionMessage}) {
            if(lastSessionMessage){
              this.lastMessage = lastSessionMessage
            }
            this.loadLastMessage = true
          }
        }
    },
    computed: {
        ...mapGetters({
            subjects: 'chat/subjects',
            users: 'auth/users'
        }),
        subject(){
            return this.subjects.find(item => item.uid === this.session.subject)
        },
        hasSubject(){
                return this.subject !== null && this.subject !== undefined
        },
        sender(){
            if(this.lastMessage === null || this.lastMessage === undefined) return null
            return this.users.find(item => item.uid === this.lastMessage.sender)
        },
        
        senderFullname(){
            if(this.sender!== null){
                return this.sender.fullname
            }
            return 'Annonyme'
        }
    }

}
</script>
<style lang="scss" scoped>
.closedSession{
    background-color: #fff;
    border-radius: 10px;
    align-items: center; 
    justify-content: start; 
    padding: 5px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    border: 1px solid rgba(0,0,0,0.15);
    cursor: pointer;
}
</style>