<template>
<div class="single-team" v-if="hasTeam">
    <div class="d-flex">
    <div class="team-left">
        <avatar size="lg" :user-uid="generateRandomUid"/>
        <p>{{ teamMembers.length }} membres</p>
    </div>
    <div class="team-right">
        <h6>{{ team.libelle }}</h6>
        <!-- <p>FORMED IN 20th Apr'20</p> -->
        <!-- <button class="primary">
        Connect
        </button> -->
        <ul>
            <li class="text-success-teal">
                <strong>Actifs</strong> : {{ activeMembers.length  }}
            </li>
            <li class="text-info">
                <strong>Connectés</strong> : {{ connectedTeamMembers.length }}
            </li>
        </ul>
    </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '../../defaultAvatar.vue'
export default {
    props: {
        typeUid: {type: String, required: true}
    },
    components: {
        Avatar
    },
    computed: {
        ...mapGetters({
            typeComptes: 'auth/typeComptes',
            users: 'auth/users',
            connectedUsers: 'chat/connectedUsers'
        }),
        team(){ return this.typeComptes.find(item => item.uid === this.typeUid)},
        hasTeam(){return this.team !== null && this.team !== undefined},
        teamMembers(){
            return [...this.users].filter(user => user.typeCompte === this.typeUid)
        },
        connectedTeamMembers(){
            return this.connectedUsers.filter(user => this.teamMembers.find(teamMember => teamMember.uid === user.uid))
        },
        activeMembers(){ return this.teamMembers.filter(item => item.active)},
        generateRandomUid() { // Générer un UID aléatoire en utilisant des méthodes natives 
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) { const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8); return v.toString(16); }); 
        }
    }
}
</script>