const state = {
    // Define your application state here
    subjects: [],
    sessions: [],
    messages:  [],
    connectedUsers: [],
    activeUser: null,
    currentSession: null,
    unreadMessages: 0
}


const getters = {
    // Define your getters here
    subjects: state => state.subjects,
    sessions: state => state.sessions,
    messages: state => state.messages,
    activeUser: state => state.activeUser,
    currentSession: state => state.currentSession,
    connectedUsers: state => state.connectedUsers,
    unreadMessages: state => state.unreadMessages
}



const mutations = {
    SET_SUBJECTS: (state, list) => {state.subjects = list},
    ADD_SUBJECT: (state, subject) => {state.subjects.push(subject)},
    UPDATE_SUBJECT: (state, subject) => {state.subjects = state.subjects.filter(item => item.uid !== subject.uid); state.subjects.push(subject)},
    DELETE_SUBJECT: (state, subject) => {state.subjects = state.subjects.filter(item => item.uid !== subject.uid)},


    SET_SESSIONS: (state, list) => {state.sessions = list},
    UPDATE_SESSION: (state, session) => {state.sessions = state.sessions.filter(item => item.uid !== session.uid); state.sessions.push(session)},
    ADD_SESSION: (state, session) => {state.sessions.push(session)},
    
    SET_MESSAGES: (state, list) => {state.messages = list},
    ADD_MESSAGE: (state, message) => {state.messages.push(message)},
    UPDATE_MESSAGE: (state, message) => {state.messages = state.messages.filter(item => item.uid !== message.uid); state.messages.push(message)},
    DELETE_MESSAGE: (state, message) => {state.messages = state.messages.filter(item => item.uid !== message.uid)},

    SET_ACTIVE_USER: (state, user) => {state.activeUser = user},
    DELETE_ACTIVE_USER: (state) => {state.activeUser = null},

    SET_CURRENT_SESSION: (state, session) => {state.currentSession = session},

    SET_CONNECTED_USERS: (state, list) => {state.connectedUsers = list},
    DISCONNECT_USER: (state, user) => {state.connectedUsers = state.connectedUsers.filter(item => item.uid !== user)},
    INCREMENT_UNREAD_MESSAGES: (state) => {state.unreadMessages++},
    RESET_UNREAD_MESSAGES: (state) => {state.unreadMessages = 0}

}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}