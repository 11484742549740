import { render, staticRenderFns } from "./detailAction.vue?vue&type=template&id=468e5f77&scoped=true"
import script from "./detailAction.vue?vue&type=script&lang=js"
export * from "./detailAction.vue?vue&type=script&lang=js"
import style0 from "./detailAction.vue?vue&type=style&index=0&id=468e5f77&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468e5f77",
  null
  
)

export default component.exports