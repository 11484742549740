<template>
  <div>
    <router-link
      :to="{name: 'market-product', params: {uid: object.uid}}"
      class="btn btn-secondary btn-sm"
    >
      <i class="icofont icofont-eye" />
    </router-link>
  </div>
</template>

<script>
export default {
    props: {
        object: {type: Object, required: true}
    }

}
</script>