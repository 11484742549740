<template>
  <div>
    <h4>Categories d'analyse</h4>
    
    <data-table
      :data-source="categories"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-categorie-analyse"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
        
    <modal
      title="Ajout d'une categorie"
      id="add-categorie-analyse"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-type">Type</label>
          <select
            id="add-type"
            name="add-type"
            class="form-control"
            v-model="type"
            v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in typesCanHaveCategories"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="hasRecherche"
              id="type-recherche"
            >
            <label
              class="form-check-label"
              for="type-recherche"
            >
              Comporte des Types de recherche?
            </label>
          </div>
        </div>
        <div class="form-group" v-if="!hasRecherche">
          <label for="add-montant">Montant</label>
          <input
            id="add-montant"
            name="add-montant"
            type="text"
            class="form-control"
            v-model="montant"
          >
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
    <modal
      title="Modification de la categorie d'analyse"
      id="update-categorie-analyse"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editting"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="update-type">Type</label>
          <select
            id="update-type"
            name="update-type"
            class="form-control"
            v-model="type"
            v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in typesCanHaveCategories"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="hasRecherche"
            id="type-recherche"
          >
          <label
            class="form-check-label"
            for="type-recherche"
          >
            Comporte des Types de recherche?
          </label>
        </div>
        <div class="form-group" v-if="!hasRecherche">
          <label for="update-montant">Montant</label>
          <input
            id="update-montant"
            name="update-montant"
            type="text"
            class="form-control"
            v-model="montant"
          >
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../../../components/dataTable/local.vue'
import {  BOOLEAN_TYPE, COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
import Modal from '../../../../components/modal.vue'
import { ADD_CATEGORIE_ANALYSE, UPDATE_CATEGORIE_ANALYSE } from '../../../../graphql/sanitaire'
const typeAnalyse = () => import('../../../../components/admin/sanitaire/categorieAnalyse/typeAnalyse.vue')
const Actions = () => import('../../../../components/admin/sanitaire/categorieAnalyse/actions.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            hasRecherche: false,
            type: null,
            montant: 0,
            has_error: false,
            error_msg : null
        }
    },
    watch: {
        selectedCategorie: {
            handler(){
                if(this.selectedCategorie !== null) {
                    this.libelle = this.selectedCategorie.libelle
                    this.montant = this.selectedCategorie.montant
                    this.hasRecherche = this.selectedCategorie.hasRecherche
                    this.type = this.selectedCategorie.typeAnalyse
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedObject(null)
            this.libelle = null
            this.hasRecherche = false
            this.type = null
            this.montant = 0
            this.has_error = false
            this.error_msg = null
        },
        adding(){
            let data = {
                libelle: this.libelle,
                hasRecherche : this.hasRecherche,
                montant: parseFloat(this.montant),
                typeAnalyse: this.type
            }
            this.$apollo.mutate({
                mutation: ADD_CATEGORIE_ANALYSE,
                variables: {
                    "categorie": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Categorie analyse ${this.libelle} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editting(){
            let data = {
                libelle: this.libelle,
                hasRecherche : this.hasRecherche,
                typeAnalyse: this.type,
                montant: parseFloat(this.montant)
            }
            this.$apollo.mutate({
                mutation: UPDATE_CATEGORIE_ANALYSE,
                variables: {
                    "categorie": {
                        ...data
                    },
                    "uid": this.selectedCategorie.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Type analyse ${this.libelle} on ${this.selectedCategorie.uid} updated successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            types: 'sanitaire/typeAnalyses',
            categories: 'sanitaire/categorieAnalyses',
            selectedCategorie: 'selectedObject'
        }),
        typesCanHaveCategories(){
            return [...this.types.filter(item => item.hasCategorie)]
        },
        headers(){
            return  [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Montant', name: 'montant', type: TEXT_TYPE, sortable: true},
                {label: 'Type Analyse', name: 'type_analyse', type: COMPONENT_TYPE, component: typeAnalyse},
                {label: 'Has Type Recherche', name: 'hasRecherche', type: BOOLEAN_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        }
    }
}
</script>

<style>

</style>