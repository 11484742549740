<template>
  <div class="layout-spacing">
    <a class="widget quick-category">
      <div class="quick-category-head">
        <span class="quick-category-icon qc-danger rounded-circle">
          <i class="las la-first-aid" />
        </span>
        <div class="ml-auto">
          <!-- <div class="quick-comparison qcompare-danger">
                        <span>20%</span>
                        <i class="las la-arrow-up"></i>
                    </div> -->
                   
        </div>
      </div>
           
      <div class="quick-category-content">
        <h3>{{ anamneses.length }}</h3>
        <p class="font-17 text-danger mb-0">Consultations</p>
        <div class="chart"> 
          <apexchart
            type="donut"
            width="210"
            :options="chartOptions"
            :series="chartData"
          />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import apexchart from 'vue-apexcharts'
export default {
    components: {apexchart},
    data(){
        return {
            chartMotifs: [],
            chartData: []
        }
    },
    watch: {
        updateChart: {
            handler(){
                this.initChart()
            },
            deep: true
        }
    },
    mounted(){
        this.initChart()
    },  
    methods: {
        initChart(){
            this.chartData = []
            this.chartMotifs = []
            this.motifs.forEach(item => {
                this.chartMotifs.push(item.libelle)
                this.chartData.push([...this.anamneses].filter(i => i.motif === item.uid).length)
            })
        }
    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux',
            motifs: 'sanitaire/motifConsultations',
            anamneses: 'sanitaire/anamneses'
        }),
        updateChart(){
            return {a: this.anamneses, m: this.motifs}
        },
        chartOptions(){
            return {
                chart: {
                    width: 210,
                    type: 'donut',
                    
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: false
                            }
                        },
                        startAngle: 0,
                        endAngle: 360
                    }
                },
                labels: this.chartMotifs,
                dataLabels: {
                    enabled: true
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    show: false,
                    formatter: function(val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                title: {
                    enabled: false,

                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                        width: 200
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                }]
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.quick-category-content{
    position: relative;
    .chart{
        position: absolute;
        top: -90px;
        left: 25%;
        min-height: 300px;
    }
}

</style>