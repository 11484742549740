<template>
  <div class="subject" :class="isCurrentSession? 'activeSession' : ''" v-if="hasSubject" @click.prevent="clickActiveSession">
    {{ subject.libelle }}
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        session: {type: Object, required: true}
    },
    methods: {
        ...mapMutations({
            setCurrentSession: 'chat/SET_CURRENT_SESSION'
        }),
        clickActiveSession(){
            this.setCurrentSession(this.session.uid);
            this.$emit('activeSession', this.session);
        }
    },
    computed: {
        ...mapGetters({
            subjects: 'chat/subjects',
            currentSession: 'chat/currentSession'
        }),
        subject(){
            return this.subjects.find(subject => subject.uid === this.session.subject);
        },
        hasSubject(){
            return this.subject !== null && this.subject !== undefined
        },
        isCurrentSession(){
            return this.currentSession === this.session.uid;
        }
    }

}
</script>
<style scoped>
.subject{
    display: inline;
    margin: 5px;
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
    
}
.activeSession{
    background-color: #B50404;
    color: white
}
</style>