<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="notifications-table-widget">
      <div class="widget-heading">
        
      </div>
      <div class="widget-content">
        <div class="table-responsive">
          <div class="row"  style="margin-bottom: 10px;">
            <div class="col-12 text-right actions">
              <a href="javascript:void(0)" class="btn btn-outline-danger btn-sm" @click.prevent="deleteAllMyNotifications">
                Supprimer toutes mes notifications
              </a>
              <a v-if="is_super_admin" class="btn btn-outline-secondary btn-sm" href="javascript:void(0)" @click.prevent="setAllAsRead">
                Set ALL as Read
              </a>
              <a v-if="is_super_admin" class="btn btn-outline-danger btn-sm" href="javascript:void(0)" @click.prevent="deleteAllNotification">
                Delete ALL Notification
              </a>
            </div>
          </div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>
                  <div class="th-content">
                    Source
                  </div>
                </th>
                <th>
                  <div class="th-content">
                    Message
                  </div>
                </th>
                <th>
                  <div class="th-content">
                    Time
                  </div>
                </th>
                <th>
                  <div class="th-content">
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <notification-tr
                v-for="(n, index) in sortedNotifications"
                :key="index"
                :notification="n"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import Navbar from '../components/navbar.vue'
import NotificationTr from '../components/user/notificationTr.vue'
import { SET_ALL_NOTIFICATION_AS_READ, DELETE_ALL_NOTIFICATION, DELETE_ALL_MY_NOTIFICATION } from '../graphql/user';
export default {
    components: {Navbar, NotificationTr},
    data() {
        return {
          navbarItems: [ { libelle: 'Notification' } ],
            pageIcon: 'la-rocketchat',
            pageTitle: 'Notification',
            pageDescription: 'Discussion instantanée', 
        }
    },
    methods: {
      setAllAsRead() {
        this.$apollo.mutate({
            mutation: SET_ALL_NOTIFICATION_AS_READ,
            update: () => {
                console.log(`Done successfully!`)
            }
          }).catch((error) => {
            console.error(error)
        })
      },
      deleteAllNotification() {
        this.$apollo.mutate({
            mutation: DELETE_ALL_NOTIFICATION,
            update: () => {
                console.log(`Done successfully!`)
            }
          }).catch((error) => {
            console.error(error)
        })
      },
      deleteAllMyNotifications() {
        this.$apollo.mutate({
            mutation: DELETE_ALL_MY_NOTIFICATION,
            update: () => {
                console.log(`Done successfully!`)
            }
          }).catch((error) => {
            console.error(error)
        })
      }
    },
    computed: {
      ...mapGetters({
        notifications: 'notification/notifications',
        is_super_admin: 'auth/is_super_admin'
      }),
      sortedNotifications(){
        return [...this.notifications].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
      }
    }
}
</script>