<template>
  <div>
    {{ type }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return  {

        }
    },
    computed: {
        ...mapGetters({
            types: 'garderie/types',
        }),
        type(){
            let t = this.types.find(item => item.uid === this.object.type)
            return t? t.libelle: '-'
        }
    }

}
</script>