<template>
  <div>
    <a 
      href="#" 
      style="display: none;" 
      id="showNewChat" 
      data-toggle="modal"
      data-target="#newChatModal"
    ></a>
    <modal
      id="newChatModal"
      :title="modalTitle"
      :footer="false"
      size="x-large"
    >
    <div class="row" v-if="hasChatPayload">
      <div class="col-7">
          <h5 style="margin-bottom: 10px;">Informations du client</h5>
          <ClientBox :uid="chatRequestPayload.clientUID" />
      </div>
      <div class="col-5">
        
        <ClientAniamux :uid="chatRequestPayload.clientUID" />
      </div>
    </div>
    <div class="row" style="margin-top: 20px;" v-if="hasChatPayload">
      <div class="col-12 text-center actions">
        <a @click="acceptChat" class="btn btn-primary">Accepter</a>
        <a @click="closeBox" class="btn btn-secondary">Fermer</a>
      </div>
    </div>
  </modal>
  </div>
</template>

<script>
import socket from '../../../plugins/socket';
import modal from '../../modal.vue';
import ClientBox from './client.vue';
import ClientAniamux from './animaux.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
    components: { modal, ClientBox, ClientAniamux },
    data(){
        return {
            isModalVisible: false,
            chatRequestPayload: null,
            modalTitle: '',
        }
    },
    watch: {
        chatRequestPayload: {
          handler(){
            const s = this.chatRequestPayload ? this.chatRequestPayload.subject : null
            if(s){
              const subject = this.subjects.find(sub => sub.uid === s)
              this.modalTitle = (subject)?  `Demande de discussion - ${subject.libelle}` : `Demande de discussion`
            }
          },
          immediate: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE'
        }),
        closeBox(){
            this.done()
            this.chatRequestPayload = null;
            this.isModalVisible = false;
            this.modalTitle = '';
        },
        acceptChat() {
            if (this.chatRequestPayload) {
                this.done()
                socket.emit("accept_chat", {
                    agentUID: this.me.uid,
                    sessionUID: this.chatRequestPayload.sessionUID,
                });
                this.$router.push({
                    name: 'chat',
                    params: {
                    clientUID: this.chatRequestPayload.clientUID,
                    sessionUID: this.chatRequestPayload.sessionUID,
                    },
                });
            }
        },
        
    },
    mounted(){
        socket.on("new_chat_request", (payload) => {
          if(this.chatRequestPayload && this.chatRequestPayload.sessionUID === payload.sessionUID) return
          this.chatRequestPayload = payload;
          console.log(payload)
          document.getElementById('showNewChat').click();
            
          // if(confirm("Nouvelle demande de Chat. Accepter?")){
            //     socket.emit("accept_chat", ({agentUID: this.me.uid, sessionUID: payload.sessionUID}))
            //     this.$router.push({ name: 'chat', params: { clientUID: payload.clientUID, sessionUID: payload.sessionUID } })
            // }
        })
    }, 
    computed: {
        ...mapGetters({
            me: 'auth/me',
            subjects: 'chat/subjects'
        }),
        hasChatPayload(){
            return this.chatRequestPayload !== null && this.chatRequestPayload !== undefined
        } 
    },

}
</script>