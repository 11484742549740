<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="car-box">
        <detail-sanitaire
            :animal="animal?.uid"
            :create-by="animal?.proprio"
            :create-at="createAt"
        >
        <template slot="header">
            <h4>{{ subject }}</h4>
        </template>
        <template slot="content">
            <div class="row info">
                <div class="col-12">
                <h6> Dates de la garde </h6>
                <p>{{ gStartDate|capitalize }} -  {{ gEndDate|capitalize }}</p>
                </div>
            </div>
            <div class="row info">
                <div class="col-6">
                  <h6> Type de garde </h6>
                  <p>{{ typeDeGarde }}</p>
                </div>
                <div class="col-6">
                  <h6> Cabine </h6>
                  <p>{{ cabine }}</p>
                </div>
            </div>
            <div class="row info">
                <div class="col-12">
                  <h6> Montant </h6>
                  <p>{{ garde?.amount }} Fcfa</p>
                </div>
            </div>
            <div class="row info">
                <div class="col-6">
                  <h6> Accompagné l'animal avec ma nourriture </h6>
                  <p>{{ garde?.withFood? 'Oui' : 'Non' }}</p>
                </div>
                <div class="col-6">
                  <h6> Heure de repas </h6>
                  <p>{{ garde?.heuresRepas}}</p>
                </div>
            </div>
            <div class="row info">
                <div class="col-6">
                  <h6> Promenade </h6>
                  <p>{{ promenade? 'Oui' : 'Non' }}</p>
                </div>
                <div class="col-6" v-if="promenade">
                  <h6> Heures de promenade </h6>
                  <p>{{ garde?.heuresPromenade}}</p>
                </div>
            </div>
            <div class="row info">
                <div class="col-6">
                  <h6> Vidéo surveillance </h6>
                  <p>{{ monitoring? 'Oui': 'Non' }}</p>
                </div>
                <div class="col-6" v-if="monitoring">
                  <h6> Heures des vidéos surveillance </h6>
                  <p>{{ garde?.heuresMonitoring }}</p>
                </div>
            </div>
            
            <div class="row info">
                <div class="col-12">
                <h6> Status </h6>
                <p>{{ status }}</p>
                </div>
            </div>
            
            <div class="row" style="margin: 50px 20px;" v-if="status === 'OPEN'">
                <div class="col-12 text-center actions">
                    <a 
                        href="javascript:void(0)"
                        class="btn btn-success btn-lg"
                        data-toggle="modal"
                        data-target="#add-activity"
                    >
                        Ajouter une activité
                    </a>
                    <a 
                        href="javascript:void(0)"
                        class="btn btn-danger btn-lg"
                        @click="confirmCloseGarde"   
                    >
                      Clôturer la garde
                    </a>
                </div>
            </div>
        </template>
        </detail-sanitaire>
        <Modal
            title="Ajouter une activité"
            id="add-activity"
            size="large"
            :footer="false"
        >
            <div class="row">
              <div class="alert alert-danger" v-if="has_error">
                <p>{{ error_msg }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="add-activity">Type d'activité</label>
                  <select
                    id="add-activity"
                    name="add-activity"
                    class="form-control"
                    v-select="{placeholder: 'Selectionnez le type d\'activité'}"
                    v-model="operation.type"
                  >
                    <option></option>
                    <option v-for="type in allowedActivities" :key="type.uid" :value="type.uid">{{ type.libelle }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="add-operation-date">Date et heure de l'activité</label>
                  <input class="form-control" v-model="operation.dateOperation" id="add-operation-date" name="add-operation-date" type="text"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="add-event">Evenement</label>
                  <select 
                    id="add-event"
                    name="add-event"
                    class="form-control"
                    v-select="{placeholder: 'Selectionnez l\'evenement lié au l\'activité'}"
                    v-model="operation.evenement"
                  >
                    <option></option>
                    <option v-for="ev in activityAllowedEvents" :key="ev.uid" :value="ev.uid">{{ ev.libelle }} ({{ ev.startDate|humanDateTime}} - {{ ev.endDate|humanDateTime }})</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="add-motif">Observation</label>
                        <textarea class="form-control" row="2" v-model="operation.observation" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group actions text-right">
                        <a href="javascript:void(0)" @click.prevent="addOperation" class="btn btn-primary">Ajouter</a>
                        <a href="javascript:void(0)" @click.prevent="cancleOperationAdd"  class="btn btn-black">Annuler</a>
                    </div>
                </div>
            </div>

        </Modal>
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/navbar.vue';
import DetailSanitaire from '../../components/sanitaire/detailSanitaire.vue'
import { Select } from '../../directive/select';
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
require("flatpickr/dist/themes/material_green.css");
import Modal from '../../components/modal.vue'
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment'
import { ADD_GARDE_OPERATION, CLOSE_GARDE } from '../../graphql/garderie';
export default {
    components: {Navbar, DetailSanitaire, Modal},
    directives: {Select},
    data(){
        return {
            navbarItems: [
                {   
                    libelle: "Garderie", 
                    route: 'garderie'
                },
                {
                    libelle: 'Garde',
                    route: 'garde'
                },
                {
                    libelle: 'Detail'
                }
            ],
            pageIcon: 'la-calendar',
            pageTitle: 'Détail de la garde',
            pageDescription: 'Détail de la garde CASEAC',
            loading: false,
            has_error: false,
            error_msg: null,
            operation: {
              type: null,
              evenement: null,
              dateOperation: null,
              observation: null
            }
        }
    },
    mounted(){
      let options = {
        enableTime: true,
        "locale": French,
        disable: [ 
          { 
            from: new Date(), 
            to: "9999-12-31" 
          }
        ]
      }
      flatpickr("#add-operation-date", options)
    },
    methods: {
        ...mapMutations({
            done: 'DONE'
        }),
        getTomorrow(){ 
          const today = new Date(); 
          const tomorrow = new Date(); 
          tomorrow.setDate(today.getDate() + 1); 
          return tomorrow; 
        },
        initOperationForm(){
          this.operation = {
              type: null,
              evenement: null,
              dateOperation: null,
              observation: null
            }
        },
        async addOperation(){
          let data = {
            garde: this.garde.uid,
            type: this.operation.type,
            event: this.operation.evenement,
            dateOperation: this.operation.dateOperation,
            observation: this.operation.observation
          }
          try {
                this.loading = true
                await this.$apollo.mutate({
                    mutation: ADD_GARDE_OPERATION,
                    variables: {
                        "operation": {...data}
                    },
                    update: (cache, { data: { addGardeOperation } }) => {
                        this.has_error = false
                        if(addGardeOperation) {
                            console.log(addGardeOperation)
                            this.initOperationForm()
                            this.done()
                        }
                        else{
                            console.log(`Error in adding of garde`)
                        } 
                    }
                });
            } catch (error) {
                console.error(error);
                this.has_error = true;
                this.error_msg = error.message || 'An error occurred while estimating the garde.';
            }finally{
                this.loading = false
            }
          
        },
        cancleOperationAdd(){
          this.done()
        },
        async confirmCloseGarde() { 
          if (confirm("Êtes-vous sûr de vouloir clôturer la garde ?")) { 
            // Logique pour clôturer la garde 
            console.log("La garde a été clôturée."); 
            try {
                this.loading = true
                await this.$apollo.mutate({
                    mutation: CLOSE_GARDE,
                    variables: {
                        "uid": this.garde.uid
                    },
                    update: (cache, { data: { closeGarde } }) => {
                        this.has_error = false
                        if(closeGarde) {
                            console.log(closeGarde)
                            this.$router.push({ name: 'garde' })
                        }
                        else{
                            console.log(`Error in adding of garde`)
                        } 
                    }
                });
            } catch (error) {
                console.error(error);
                this.has_error = true;
                this.error_msg = error.message || 'An error occurred while estimating the garde.';
            }finally{
                this.loading = false
            }

          } else { 
            console.log("La garde n'a pas été clôturée."); 
          }
        }
    }, 
    computed: {
        ...mapGetters({
            gardes: 'garderie/gardes',
            types: 'garderie/types',
            activities: 'garderie/activities',
            cabines: 'garderie/cabines',
            animaux: 'identification/animaux',
            events: 'events/events',
        }),
        gStartDate(){
            return this.hasGarde? moment(this.garde.startDate).format('dddd DD-MM-YYYY') : null
        },
        gEndDate(){
            return this.hasGarde? moment(this.garde.endDate).format('dddd DD-MM-YYYY') : null
        },
        monitoring(){return this.hasGarde?  this.garde.monitoring : false},
        promenade(){return this.hasGarde?   this.garde.promenade: false},
        nbPromenade(){return this.hasGarde? this.garde.nbPromenade : 0},
        nbRepas(){return this.hasGarde? this.garde.nbPromenade : 0},
        garde(){
            return this.gardes.find(item => item.uid === this.$route.params.uid)
        },
        hasGarde(){
            return this.garde !== null && this.garde !== undefined
        },
        animal(){
            if(this.hasGarde) return this.animaux.find(item => item.uid === this.garde.animal)               
            return null
        },
        subject(){
          if(this.animal) return `Garde de ${this.animal.name} ${this.gStartDate} - ${this.gEndDate}`
          return '-'
        },
        createAt(){
            return (this.hasGarde)? this.garde.createdAt : null
        },
        
        status(){
            return (this.hasGarde)? this.garde.status : null
        },
        cabine(){
            if (this.hasGarde) {
              const c = this.cabines.find(item => item.uid === this.garde.cabine)
              return (c)? c.libelle : '-'
            }
            return '-'
        },
        typeDeGarde(){
          if(this.hasGarde){
            const t = this.types.find(item => item.uid === this.garde.type)
            return (t)? t.libelle : '-'
          }
          return '-'
        },
        event(){
            return (this.hasGarde)? this.garde.event : null
        },
        allowedActivities(){
          return [...this.activities].filter(item => ['nourriture', 'monitoring', 'promenade'].includes(item.code))
        },
        activityAllowedEvents(){
          console.log(this.operation.dateOperation)
          return [...this.events].filter(item => {
            const info = JSON.parse(item.infoUtile)
            return !item.accomplished 
            && info.garde 
            && info.reference === this.garde.uid 
            && this.operation.type === info.activity
            && moment(this.operation.dateOperation.split(' ')[0]).isSame(moment(item.startDate), 'day')
          })
        }
    }
}
</script>