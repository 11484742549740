<template>
  <div>
    <navbar
        :items="navbarItems"
        :fonticon="pageIcon"
        :navbartitle="pageTitle"
        :navbardescription="pageDescription"
      />
      <div class="card-box">
        <form  class="form-horizontal" @submit.prevent="addGarde">
            <div
                class=" alert alert-danger"
                v-if="has_error"
            >
                <p>{{ error_msg }}</p>
            </div>
            <div class="row">
                <div class="col-12">
                    <animal-detail :show-detail="true" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="demand">Demande</label>
                        <select v-select="{placeholder: 'Selectionnez le timing de la demande'}" v-model="garde.demande" class="form-control" id="demand" name="demand">
                            <option value=""></option>
                            <option v-for="d in animalDemandes" :key="d.uid" :value="d.uid">{{ getTypeGarde(d.typeGarde) }} {{ d.startDate|systemDateTime }} - {{  d.endDate|systemDateTime }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-4" v-if="selectedDemand.monitoring">
                    <div sytle="padding: 40px;">
                        <i class="icofont icofont-camera icofont-4x"></i>
                    </div>
                </div>
                <div class="col-4" v-if="selectedDemand.withFood">
                    <i class="icofont icofont-food icofont-4x"></i>
                </div>
                <div class="col-4" v-if="selectedDemand.promenade">
                    <div sytle="padding: 40px;">
                        <i class="icofont icofont-road icofont-4x"></i>
                    </div>
                </div>
            </div> -->
            <div class="row" >
                <div class="col-12">
                    <div class="form-group">
                        <label for="nbRepas">Nombre de repas/Jr</label>
                        <input type="number" v-model="garde.nbRepas" class="form-control" id="nbRepas" name="nbRepas" placeholder="Nombre de repas/jr">
                    </div>
                </div>
            </div>
            <div class="row" v-if="garde.nbRepas">
                <div class="col" v-for="n in parseInt(garde.nbRepas)" :key="n">
                    <div class="form-group">
                        <label :for="`heure-repas-${n}`">Heure de Repas {{ n }}</label>
                        <input type="time" v-model="garde.heuresNouriture[n - 1]" class="form-control" :id="`heure-repas-${n}`" :name="`heure-repas-${n}`" placeholder="Heure de Repas">
                    </div>
                </div>
            </div>

            <div class="row" v-if="selectedDemand.promenade">
                <div class="col-12">
                    <div class="form-group">
                        <label for="nbPromenade">Nombre de promenade/jr</label>
                        <input type="number" :value="selectedDemand.nbPromenade" class="form-control" id="nbPromenade" name="nbPromenade" placeholder="Nombre de promenade/jr" disabled>
                    </div>
                </div>
            </div>
            <div class="row" v-if="selectedDemand.promenade">
                <div class="col" v-for="n in parseInt(selectedDemand.nbPromenade)" :key="n">
                    <div class="form-group">
                        <label :for="`heure-promenade-${n}`">Heure de promenade {{ n }}</label>
                        <input type="time" v-model="garde.heuresPromenade[n - 1]" class="form-control" :id="`heure-promenade-${n}`" :name="`heure-promenade-${n}`" placeholder="Heure de promenade">
                    </div>
                </div>
            </div>

            <div class="row" v-if="selectedDemand.monitoring">
                <div class="col">
                    <div class="form-group">
                        <label for="heure-monitoring">Heure de vidéo</label>
                        <input type="time" v-model="garde.heuresMonitoring[0]" class="form-control" id="heure-monitoring" name="heure-monitoring" placeholder="Heure de vidéo">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="cabine">Cabine de garde</label>
                        <select class="form-control" id="cabine" name="cabine" v-model="garde.cabine" v-select="{placeholder: 'Choisissez la cabine de garde'}">
                            <option value=""></option>
                            <option v-for="cabine in availableCabines" :key="cabine.uid" :value="cabine.uid">{{ cabine.libelle }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row" v-if="gardeEstimations.length > 0" style="margin-top: 40px; margin-bottom: 40px;">
                <div class="col-12">
                    <h4>Estimation du montant de la Garde</h4>
                    <table class="table table-hover table-stripped table-bordered">
                        <thead>
                            <tr>
                                <th>Intitulé</th>
                                <th>Prix Unit.</th>
                                <th>Quantité.</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(g, index) in gardeEstimations" :key="index">
                                <td>{{ g.libelle }}</td>
                                <td>{{ g.unitPrice }}</td>
                                <td>{{ g.quantity }}</td>
                                <td>{{ g.total }}</td>
                            </tr>
                            <tr>
                                <td colspan="3">Total</td>
                                <td>{{ totalEstimations }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-right">
                    <a 
                        href="javascript:void(0)" 
                        class="btn btn-danger"
                        :class="estimating? 'disabled': ''"
                        style="margin-right: 20px"
                        @click.prevent="estimateGarde"
                    >
                        <span
                            class="spinner-border m-1"
                            role="status"
                            v-if="estimating"
                        /> 
                        <span v-else> Estimer la Garde </span>
                    </a>

                    <button
                        type="submit"
                        class="btn btn-primary"
                        :class="loading? 'disabled': ''"
                    >
                        <span
                        class="spinner-border m-1"
                        role="status"
                        v-if="loading"
                        /> 
                        <span v-else> Commencer la garde </span>
                    </button>
                </div>
            </div>
        </form>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment'
import Navbar from '../../components/navbar.vue';
import AnimalDetail from '../../components/identification/animal/detailInform.vue'
import { ADD_GARDE, ESTIMATE_GARDE } from '../../graphql/garderie';
export default {
    components: {Navbar, AnimalDetail},
    data(){
        return {
            navbarItems: [
                {   
                    libelle: "Garderie", 
                    route: 'garderie'
                },
                {
                    libelle: 'Gardes',
                    route: 'garde',
                },
                {
                    libelle: 'Ajout'
                }
            ],
            pageIcon: 'la-calendar',
            pageTitle: 'Ajout de garde',
            pageDescription: 'Ajouter une garde CASEAC',
            animalDemandes: [],
            availableCabines: [],
            selectedDemand: {
                monitoring: false,
                withFood: false,
                promenade: false,
                nbPromenade: 0,
                startDate: null,
                endDate: null
            },
            garde:{
                demande: null,
                heuresPromenade: [],
                heuresNouriture: [],
                heuresMonitoring: [],
                typeGarde: null,
                nbRepas: null,
                cabine: null
            },
            gardeType: null,
            gardeEstimations:  [],
            has_error:false,
            error_msg: null,
            loading: false,
            estimating: false
            
        }
    },
    watch: {
        selectedAnimal(){
            this.animalDemandes = []
            const currentDate = moment();
            if(this.selectedAnimal){
                this.animalDemandes = [...this.demandes].filter(item => {
                    return item.animal === this.selectedAnimal 
                    && item.status === 'Confirmed' 
                    && currentDate.isBetween(moment(item.startDate), moment(item.endDate), 'day', '[]')
                })
            }
        },
        garde:{
            handler(){
                if(this.garde.demande){
                    const sd = this.demandes.find(item => item.uid === this.garde.demande)
                    this.selectedDemand.monitoring = sd.monitoring
                    this.selectedDemand.withFood = sd.withFood
                    this.selectedDemand.promenade = sd.promenade
                    this.selectedDemand.nbPromenade = sd.nbPromenade
                    this.selectedDemand.startDate = sd.startDate
                    this.selectedDemand.endDate = sd.endDate
                    this.gardeType = sd.typeGarde
                    this.availableCabines = [...this.cabines].filter(item => {
                        if(sd.monitoring) return item.type === this.gardeType && item.monitoring && item.libre
                        return item.type === this.gardeType && item.libre
                    })
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
          setLoading: 'SET_LOADING'
        }),

        getTypeGarde(uid){
            const t = this.types.find(item => item.uid  === uid)
            console.log(t)
            if(t) return t.libelle
            return ''
        },

        async estimateGarde(){
            try {
                this.estimating = true
                this.gardeEstimations = []
                
                await this.$apollo.mutate({
                    mutation: ESTIMATE_GARDE,
                    variables: {
                        "garde": {...this.gardeData}
                    },
                    update: (cache, { data: { getGardeAmount } }) => {
                        this.has_error = false
                        if(getGardeAmount) {
                            console.log(getGardeAmount)
                            this.gardeEstimations = JSON.parse(getGardeAmount)
                        }
                        else{
                            console.log(`Error in estimation of garde`)
                        } 
                    }
                });
            } catch (error) {
                console.error(error);
                this.has_error = true;
                this.error_msg = error.message || 'An error occurred while estimating the garde.';
            } finally{
                this.estimating = false
            }



            
        },
        async addGarde(){
            try {
                this.loading = true
                await this.$apollo.mutate({
                    mutation: ADD_GARDE,
                    variables: {
                        "garde": {...this.gardeData}
                    },
                    update: (cache, { data: { addGarde } }) => {
                        this.has_error = false
                        if(addGarde) {
                            console.log(addGarde)
                            this.$router.push({ name: 'garde' })
                        }
                        else{
                            console.log(`Error in adding of garde`)
                        } 
                    }
                });
            } catch (error) {
                console.error(error);
                this.has_error = true;
                this.error_msg = error.message || 'An error occurred while estimating the garde.';
            }finally{
                this.loading = false
            }
        }
    },
    computed: {
        ...mapGetters({
            cabines: 'garderie/cabines',
            types: 'garderie/types',
            demandes: 'garderie/demandes',
            animaux: 'identification/animaux',
            selectedAnimal: 'identification/selectedAnimal',
        }),
        
        totalEstimations(){
            if(this.gardeEstimations.length === 0) return 0
            return this.gardeEstimations.reduce((acc, service) => acc + service.total, 0);
        },
        gardeData(){
            return {
                demande: this.garde.demande,
                animal: this.selectedAnimal,
                heuresPromenade: JSON.stringify(this.garde.heuresPromenade),
                heuresRepas: JSON.stringify(this.garde.heuresNouriture),
                heuresMonitoring: JSON.stringify(this.garde.heuresMonitoring),
                type: this.gardeType,
                nbRepas: parseInt(this.garde.nbRepas),
                cabine: this.garde.cabine,
                monitoring: this.selectedDemand.monitoring,
                withFood: this.selectedDemand.withFood,
                promenade: this.selectedDemand.promenade,
                nbPromenade: parseInt(this.selectedDemand.nbPromenade),
                startDate: this.selectedDemand.startDate,
                endDate: this.selectedDemand.endDate
            }
        }
    }

}
</script>