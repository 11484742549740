<template>
  <div class="layout-spacing">
    <div class="widget widget-activity-four">
      <div class="widget-heading">
        <h5 class="">
          Chirurgie Stat
        </h5>
      </div>
      <div class="widget-content">
        <div class="chart"> 
          <apexchart
            type="pie"
            width="350"
            :options="chartOptions"
            :series="chartData"
          />
        </div>
        <div class="row">
          <div
            class="categorie"
            v-for="(t,i) in filtredTypes"
            :key="i"
            :style="style"
          >
            {{ t.libelle }}
            <span :style="spanStyle">{{ typePercentage(t) }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apexchart from 'vue-apexcharts'
export default {
    components: {apexchart},
    data(){
        return {
            chartTypes: ["Petitie Chirurgie", "Chirurgie Lourde"],
            chartData: [],
            showDetail: false,
            categorieFaible: null,
            color: null
        }
    },
    watch: {
        updateChart: {
            handler(){
                this.initChart()
            },
            deep: true
        },
        categorieFaible(){
            console.log(`Categorie de chirurgie change to ${this.categorieFaible}`)
        }
    },
    mounted(){
        this.initChart()
    }, 
    methods: {
        typePercentage(type){
            let nb = 0
            this.chirurgies.forEach(item => {
                if(item.type === type.uid) nb = nb+1
            })
            let nbTotal = this.chirurgies.length
            if(parseInt(nbTotal) === 0) return 0
            return (nb *100 /parseInt(nbTotal)).toFixed(2)
        },
        
        initChart(){
            this.chartData = [0,0]
            this.chirurgies.forEach(item => {
                let t = this.types.find(i => i.uid === item.type)
                if(t.faible){
                    this.chartData[0] = parseInt(this.chartData[0]) + 1
                }else{
                    this.chartData[1] = parseInt(this.chartData[1]) + 1
                }
            })
        }
    },
    computed: {
        ...mapGetters({
            types: 'sanitaire/typeChirurgies',
            chirurgies: 'sanitaire/chirurgies'
        }),
        filtredTypes(){
            return [...this.types].filter(item => item.faible === this.categorieFaible)
        },
        updateChart(){
            return {c: this.chirurgies, t: this.types}
        },
        style(){
            return {
                "background": `${this.color}`,
                "border": `1px solid ${this.color};`
            }
        },
        spanStyle(){
            return {
                "color": `${this.color}`
            }
        },
        chartOptions(){
            return {
                chart: {
                    width: 350,
                    type: 'pie',
                    events: {
                        dataPointSelection :  (event, chartContext, config) => { 
                            this.categorieFaible = config.dataPointIndex === 0 
                            this.color = (config.dataPointIndex === 0 )? '#1C6100' : '#b50404'
                            this.showDetail = true
                        }
                    },
                },
                legend: {
                    show: true,
                    position: 'top'
                },
                
                colors:['#1C6100', '#b50404'],
                labels: this.chartTypes,
                responsive: [
                    {
                        breakpoint: 1400,
                        options: {
                            chart: {
                            width: 300
                            },
                            legend: {
                                show: true,
                                position: 'top'
                            }
                        }
                    }   
                ]
            }
        }
    }

}
</script>

<style lang="scss">
.widget-content{
    .chart{
        text-align: center;
    }
    .categorie{
        font-size: 10px;
        margin: 5px;
        padding: 10px;
        text-align: center;
        color: white;
        span{
            border-radius: 60%;
            padding: 10px;
            background: white;
        }
    }
}
</style>