<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="card-box">
        <data-table
        :data-source="sortedAppointments"
        :headers="headers"
        able-class="table-striped table-hover"
    >
      <template slot="actions">
        
        <a 
            href="javascript:void(0)" 
            class="btn btn-outline-primary btn-sm"
            @click.prevent="filter=null"
        >
            Tout ({{ appointments.length }})
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-outline-warning btn-sm"
          @click.prevent="filter='Pending'"
        >
          En attente ({{ pendingAppointments.length }})
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-outline-secondary btn-sm"
          @click.prevent="filter='Confirmed'"
        >
          Confirmed ({{ confirmAppointments.length }})
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-outline-success btn-sm"
          @click.prevent="filter='Honor'"
        >
          Honor ({{ honorAppointments.length }})
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-outline-dark btn-sm"
          @click.prevent="filter='Canceled'"
        >
          Cancel ({{ canceledAppointments.length }})
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-outline-danger btn-sm"
          @click.prevent="filter='No-show'"
        >
          No-show ({{ noShowAppointments.length }})
        </a>
      </template>
    </data-table>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
import Navbar from '../../components/navbar.vue';
import DataTable from '../../components/dataTable/local.vue'
import { COMPONENT_TYPE,  TEXT_TYPE } from '../../components/dataTable/dataType';
const Animal = () => import('../../components/sanitaire/animal.vue')
const AppointmentDate = () => import('../../components/events/appointment/date.vue')
const User = () => import('../../components/events/appointment/user.vue')
const Subject = () => import ('../../components/events/appointment/subject.vue')
const Action = () => import('../../components/events/appointment/actions.vue')
export default {
    components: {Navbar, DataTable},
    data(){
        return {
            navbarItems: [
                {   
                    libelle: "Rendez-vous", 
                    route: 'rendez_vous'
                },
                {
                    libelle: 'Appointment'
                }
            ],
            pageIcon: 'la-calendar',
            pageTitle: 'Appointment',
            pageDescription: 'List des demandes de rendez-vous client CASEAC',
            filter: null
            
        }
    },
    computed: {
        ...mapGetters({
            appointments: 'events/appointments',
            is_super_admin: 'auth/is_super_admin',
        }),
        sortedAppointments(){
            if(this.filter !== null) return [...this.appointments].filter(item => item.status === this.filter).sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
            return [...this.appointments].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        confirmAppointments(){
            return [...this.appointments].filter(item => item.status === 'Confirmed')
        },
        pendingAppointments(){
            return [...this.appointments].filter(item => item.status === 'Pending')
        },
        canceledAppointments(){
            return [...this.appointments].filter(item => item.status === 'Canceled')
        },
        noShowAppointments(){
          return [...this.appointments].filter(item => item.status === 'No-show')
        },
        honorAppointments(){
          return [...this.appointments].filter(item => item.status === 'Honor')
        },
        
        headers(){
            return [
                // {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Date', name: 'date', type: COMPONENT_TYPE, component: AppointmentDate},
                {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                {label: 'Objet', name: 'subject', type: COMPONENT_TYPE, component: Subject},
                {label: 'Utilisateur', name: 'user', type: COMPONENT_TYPE, component: User},
                {label: 'Status', name: 'status', type: TEXT_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Action}
                
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.actions{
  a{
    font-size: 10px !important;
    padding: 2px !important;
  }
  
}
</style>