import gql from 'graphql-tag'

export const ADD_TYPE_GARDE = gql`
mutation Mutation($libelle: String!, $code: String!){
    addGardeType(libelle: $libelle, code: $code)
}
`

export const UPDATE_TYPE_GARDE = gql`
  mutation Mutation($uid: String!, $libelle: String!, $code: String!){
    updateGardeType(uid: $uid, libelle: $libelle, code: $code)
  }
`
export const DELETE_TYPE_GARDE = gql`
mutation Mutation($uid: String!) {
    deleteGardeType(uid: $uid)
}
`

export const SUBSCRIBER_TYPE_GARDE = gql`
subscription Subscription {
    garde_type {
        type
        data {
            uid
            libelle
            code
        }
    }
}
`

export const ADD_CABINE = gql`
mutation Mutation($cabine: GardeCabineInput!){
    addGardeCabine(cabine: $cabine)
}
`

export const UPDATE_CABINE = gql`
  mutation Mutation($uid: String!, $cabine: UpdateGardeCabineInput!){
    updateGardeCabine(uid: $uid, cabine: $cabine)
  }
`
export const DELETE_CABINE = gql`
mutation Mutation($uid: String!) {
    deleteGardeCabine(uid: $uid)
}
`

export const SUBSCRIBER_CABINE = gql`
subscription Subscription {
    garde_cabine {
        type
        data {
            uid
            libelle
            type
            libre
            monitoring
            url
        }
    }
}
`



export const ADD_GARDE_ACTIVITY = gql`
mutation Mutation($activity: GardeActivityInput!){
    addGardeActivity(activity: $activity)
}
`

export const UPDATE_GARDE_ACTIVITY = gql`
  mutation Mutation($uid: String!, $activity: GardeActivityInput!){
    updateGardeActivity(uid: $uid, activity: $activity)
  }
`
export const DELETE_GARDE_ACTIVITY = gql`
mutation Mutation($uid: String!) {
    deleteGardeActivity(uid: $uid)
}
`

export const SUBSCRIBER_GARDE_ACTIVITY = gql`
subscription Subscription {
    garde_activity {
        type
        data {
            uid
            libelle
            code
            amount
        }
    }
}
`

export const ADD_GARDE_PENSION = gql`
mutation Mutation($pension: GardePensionInput!){
    addGardePension(pension: $pension)
}
`

export const UPDATE_GARDE_PENSION = gql`
  mutation Mutation($uid: String!, $pension: GardePensionInput!){
    updateGardePension(uid: $uid, pension: $pension)
  }
`
export const DELETE_GARDE_PENSION = gql`
mutation Mutation($uid: String!) {
    deleteGardePension(uid: $uid)
}
`

export const SUBSCRIBER_GARDE_PENSION = gql`
subscription Subscription {
    garde_pension {
        type
        data {
            uid
            minPoids
            maxPoids
            amount
            espece
        }
    }
}
`


export const ADD_GARDE = gql`
mutation Mutation($garde: GardeInput!){
    addGarde(garde: $garde)
}
`

export const ESTIMATE_GARDE = gql`
mutation Mutation($garde: GardeInput!){
    getGardeAmount(garde: $garde)
}
`

export const UPDATE_GARDE = gql`
  mutation Mutation($uid: String!, $garde: GardeInput!){
    updateGarde(uid: $uid, garde: $garde)
  }
`
export const DELETE_GARDE = gql`
mutation Mutation($uid: String!) {
    deleteGarde(uid: $uid)
}
`

export const CLOSE_GARDE = gql`
mutation Mutation($uid: String!) {
    closeGarde(uid: $uid)
}
`

export const ADD_GARDE_OPERATION = gql`
  mutation Mutation($operation: GardeOperationInput!){
    addGardeOperation(operation: $operation)
}`

export const UPDATE_GARDE_OPERATION = gql`
mutation Mutation($uid: String!, $operation: GardeOperationInput!){
    updateGardeOperation(uid: $uid, operation: $operation)
}`

export const DELETE_GARDE_OPERATION = gql`
mutation Mutation($uid: String!) {
    deleteGardeOperation(uid: $uid)
}`

export const SUBSCRIBER_GARDE_OPERATION = gql`
subscription Subscription {
    garde_operation {
      type
      data {
        uid
        libelle
        garde
        event
        dateOperation
        observation
      }
    }
}`


export const SUBSCRIBER_GARDE = gql`
subscription Subscription {
    garde {
        type
        data {
            uid
            animal
            demande
            monitoring
            withFood
            promenade
            nbPromenade
            nbRepas
            type
            heuresPromenade
            heuresRepas
            heuresMonitoring
            amount
            startDate
            endDate
            cabine
            status
            createdAt
        }
    }
}
`


export const CONFIRM_DEMANDE = gql`
  mutation Mutation($uid: String!){
    confirmGardeDemande(uid: $uid)
  }
`
export const REJECT_DEMANDE = gql`
  mutation Mutation($uid: String!, $motif: String!){
    rejectGardeDemande(uid: $uid, motif: $motif)
  }
`

export const DELETE_DEMANDE = gql`
  mutation Mutation($uid: String!){
    confirmGardeDemande(uid: $uid)
  }
`

export const SUBSCRIBER_DEMANDE = gql`
subscription Subscription {
    garde_demande {
        type
        data {
            uid
            animal
            startDate
            endDate
            monitoring
            status
            withFood
            promenade
            nbPromenade
            createdAt
            typeGarde
        }
    }
}
`