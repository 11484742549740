const load  = (component) => {
    return () => import(`../views/${component}.vue`)
}

export default[
    {
        path: '/chat',
        name: 'chat',
        component: load('Chat')
    },
    {
        path: '/chat/:clientUID/:sessionUID',
        name: 'chat',
        component: load('Chat')
    },
    {
        path: '/direct-sms',
        name: 'direct_sms',
        component: load('DirectSms')
    },

    /** Admin */

    {
        path: '/admin/messagerie/chat-subjet',
        name: 'admin-messagerie-subject',
        component: load('Admin/Chat/Subject')
    },
]