<template>
  <div>
    <!-- <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    /> -->
    <div class="row layout-spacing pt-2 mt-4">
      <div class="col-12">
        <div class="cover-image-area">
          <img
            :src="cover"
            class="cover-img"
          >
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 mb-4">
        <div class="profile-left">
          <div class="image-area">
            <avatar :user-uid="me.uid" size="xl" />
            <a
              href="javascript:void(0)"
              class="follow-area"
              @click.prevent="selectPhoto"
            > 
              <i class="las la-pen" />
            </a>
            <form
                action=""
                style="display: none;"
              >
                <input
                  type="file"
                  @change="uploadPhoto"
                  id="selectPhotoProfile"
                  ref="file"
                >
              </form>
          </div>
          <div class="info-area">
            <h6>{{ fullname }}</h6>
            <p>{{ typeCompte }}</p>
            <!-- <button>Follow</button> -->
          </div>
          <div class="profile-numbers">
            <div class="single-number">
              <a>
                <span class="number">42</span>
                <span class="number-detail">Projects</span>
              </a>
            </div>
            <div class="single-number">
              <a>
                <span class="number">9.5K</span>
                <span class="number-detail">Products</span>
              </a>
            </div>
            <div class="single-number">
              <a>
                <span class="number">04</span>
                <span class="number-detail">Tasks</span>
              </a>
            </div>
          </div>
          <div class="profile-tabs">
            <div
              class="nav flex-column nav-pills mb-sm-0 mb-3 mx-auto"
              id="v-border-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                class="nav-link active"
                id="v-border-pills-home-tab"
                data-toggle="pill"
                href="#v-border-pills-home"
                role="tab"
                aria-controls="v-border-pills-home"
                aria-selected="true"
              >
                Home
              </a>
              <a
                class="nav-link"
                id="v-border-pills-team-tab"
                data-toggle="pill"
                href="#v-border-pills-team"
                role="tab"
                aria-controls="v-border-pills-team"
                aria-selected="false"
              >
                Equipes
              </a>
              <!-- <a
                class="nav-link"
                id="v-border-pills-work-tab"
                data-toggle="pill"
                href="#v-border-pills-work"
                role="tab"
                aria-controls="v-border-pills-work"
                aria-selected="false"
              >
                Work in progress
              </a> -->
              <!-- <a
                class="nav-link"
                id="v-border-pills-products-tab"
                data-toggle="pill"
                href="#v-border-pills-products"
                role="tab"
                aria-controls="v-border-pills-products"
                aria-selected="false"
              >
                Products
              </a> -->
              <a
                class="nav-link"
                id="v-border-pills-orders-tab"
                data-toggle="pill"
                href="#v-border-pills-orders"
                role="tab"
                aria-controls="v-border-pills-orders"
                aria-selected="false"
                v-if="is_super_admin"
              >
                Switch Account
              </a>
              <a
                class="nav-link"
                id="v-border-pills-settings-tab"
                data-toggle="pill"
                href="#v-border-pills-settings"
                role="tab"
                aria-controls="v-border-pills-settings"
                aria-selected="false"
              >
                Paramètre
              </a>
            </div>
          </div>
          <!-- <div class="highlight-info">
                  <img src="assets/img/company-1.jpg" />
                  <div class="highlight-desc">
                      <p>WS Retailer</p>
                      <p>Top seller of the month</p>
                  </div>
              </div> -->
        </div>
      </div>
      <div class="col-xl-9 col-lg-8 col-md-8">
        <div class="row tab-area-content">
          <div class="col-xl-7 col-lg-12 col-md-12 mb-4">
            <div
              class="tab-content"
              id="v-border-pills-tabContent"
            >
              <div
                class="tab-pane fade show active"
                id="v-border-pills-home"
                role="tabpanel"
                aria-labelledby="v-border-pills-home-tab"
              >
                <div class="profile-shadow">
                  <h6 class="font-15 mb-3">
                    Utilisateurs en lingne
                  </h6>
                  <online-users />
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-team"
                role="tabpanel"
                aria-labelledby="v-border-pills-team-tab"
              >
                <div class="media">
                  <div class="profile-shadow w-100">
                    <h6 class="font-15 mb-3">
                      Equipes actifs
                    </h6>
                    <Team v-for="t in typeComptes" :key="t.uid" :typeUid="t.uid"/>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-work"
                role="tabpanel"
                aria-labelledby="v-border-pills-work-tab"
              >
                <div class="media">
                  <div class="profile-shadow w-100">
                    <h6 class="font-15 mb-3">
                      Work in progress
                    </h6>
                    <div class="progress-list">
                      <div class="single-progress">
                        <div class="progress-details">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-2 font-12 text-success-teal">
                              Resolved
                            </h6>
                            <p class="mb-2 font-12 text-success-teal strong">
                              69000
                            </p>
                          </div>
                          <div class="progress-stats">
                            <div class="progress">
                              <div
                                class="progress-bar bg-gradient-success position-relative"
                                role="progressbar"
                                style="width: 69%"
                                aria-valuenow="69"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="success-teal" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="single-progress">
                        <div class="progress-details">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-2 font-12 text-secondary">
                              In Progress
                            </h6>
                            <p class="mb-2 font-12 text-secondary strong">
                              2500
                            </p>
                          </div>
                          <div class="progress-stats">
                            <div class="progress">
                              <div
                                class="progress-bar bg-gradient-secondary  position-relative"
                                role="progressbar"
                                style="width: 20%"
                                aria-valuenow="20"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="secondary" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="single-progress">
                        <div class="progress-details">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-2 font-12 text-warning">
                              Pending
                            </h6>
                            <p class="mb-2 font-12 text-warning strong">
                              8500
                            </p>
                          </div>
                          <div class="progress-issues-stats">
                            <div class="progress">
                              <div
                                class="progress-bar bg-gradient-warning position-relative"
                                role="progressbar"
                                style="width: 11%"
                                aria-valuenow="11"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="warning" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="single-progress">
                        <div class="progress-details">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-2 font-12 text-danger">
                              Failed
                            </h6>
                            <p class="mb-2 font-12 text-danger strong">
                              8500
                            </p>
                          </div>
                          <div class="progress-issues-stats">
                            <div class="progress">
                              <div
                                class="progress-bar bg-gradient-danger position-relative"
                                role="progressbar"
                                style="width: 11%"
                                aria-valuenow="11"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="danger" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-products"
                role="tabpanel"
                aria-labelledby="v-border-pills-products-tab"
              >
                <div class="searchable-items card-box grid">
                  <div class="items">
                    <div class="item-content">
                      <div class="product-info">
                        <img
                          src="assets/img/product-5.jpg"
                          alt="avatar"
                        >
                        <div class="user-meta-info">
                          <p class="product-name">
                            Product 1
                          </p>
                          <p class="product-category">
                            Category One
                          </p>
                        </div>
                      </div>
                      <div class="product-price">
                        <p class="product-category-addr">
                          <span>Price: </span>$1001
                        </p>
                      </div>
                      <div class="product-rating">
                        <p class="product-rating-inner">
                          <span>Rating: </span>
                          <a class="d-flex align-center">
                            5  <img
                              src="assets/img/star.png"
                              class="avatar-xxs ml-2"
                              alt="star"
                            >
                          </a>
                        </p>
                      </div>
                      <div class="product-stock-status">
                        <p class="product-stock-status-inner">
                          <small class="badge badge-success">In Stock</small>
                        </p>
                      </div>
                      <div class="action-btn">
                        <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact" />
                        <i class="lar la-trash-alt text-danger font-20 mr-2" />
                      </div>
                    </div>
                  </div>
                  <div class="items">
                    <div class="item-content">
                      <div class="product-info">
                        <img
                          src="assets/img/product-1.jpg"
                          alt="avatar"
                        >
                        <div class="user-meta-info">
                          <p class="product-name">
                            Product 2
                          </p>
                          <p class="product-category">
                            Category two
                          </p>
                        </div>
                      </div>
                      <div class="product-price">
                        <p class="product-category-addr">
                          <span>Price: </span>$1975
                        </p>
                      </div>
                      <div class="product-rating">
                        <p class="product-rating-inner">
                          <span>Rating: </span>
                          <a class="d-flex align-center">
                            3  <img
                              src="assets/img/star.png"
                              class="avatar-xxs ml-2"
                              alt="star"
                            >
                          </a>
                        </p>
                      </div>
                      <div class="product-stock-status">
                        <p class="product-stock-status-inner">
                          <small class="badge badge-danger">No Stock</small>
                        </p>
                      </div>
                      <div class="action-btn">
                        <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact" />
                        <i class="lar la-trash-alt text-danger font-20 mr-2" />
                      </div>
                    </div>
                  </div>
                  <div class="items">
                    <div class="item-content">
                      <div class="product-info">
                        <img
                          src="assets/img/product-2.jpg"
                          alt="avatar"
                        >
                        <div class="user-meta-info">
                          <p class="product-name">
                            Product 3
                          </p>
                          <p class="product-category">
                            Category Three
                          </p>
                        </div>
                      </div>
                      <div class="product-price">
                        <p class="product-category-addr">
                          <span>Price: </span>$2455
                        </p>
                      </div>
                      <div class="product-rating">
                        <p class="product-rating-inner">
                          <span>Rating: </span>
                          <a class="d-flex align-center">
                            4 <img
                              src="assets/img/star.png"
                              class="avatar-xxs ml-2"
                              alt="star"
                            >
                          </a>
                        </p>
                      </div>
                      <div class="product-stock-status">
                        <p class="product-stock-status-inner">
                          <small class="badge badge-danger">No Stock</small>
                        </p>
                      </div>
                      <div class="action-btn">
                        <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact" />
                        <i class="lar la-trash-alt text-danger font-20 mr-2" />
                      </div>
                    </div>
                  </div>
                  <div class="items">
                    <div class="item-content">
                      <div class="product-info">
                        <img
                          src="assets/img/product-3.jpg"
                          alt="avatar"
                        >
                        <div class="user-meta-info">
                          <p class="product-name">
                            Product 4
                          </p>
                          <p class="product-category">
                            Category Four
                          </p>
                        </div>
                      </div>
                      <div class="product-price">
                        <p class="product-category-addr">
                          <span>Price: </span>$1655
                        </p>
                      </div>
                      <div class="product-rating">
                        <p class="product-rating-inner">
                          <span>Rating: </span>
                          <a class="d-flex align-center">
                            2 <img
                              src="assets/img/star.png"
                              class="avatar-xxs ml-2"
                              alt="star"
                            >
                          </a>
                        </p>
                      </div>
                      <div class="product-stock-status">
                        <p class="product-stock-status-inner">
                          <small class="badge badge-success">In Stock</small>
                        </p>
                      </div>
                      <div class="action-btn">
                        <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact" />
                        <i class="lar la-trash-alt text-danger font-20 mr-2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-orders"
                role="tabpanel"
                aria-labelledby="v-border-pills-orders-tab"
              >
                <div class="media">
                  <div class="profile-shadow w-100">
                    <switch-account />                    
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-settings"
                role="tabpanel"
                aria-labelledby="v-border-pills-settings-tab"
              >
                <div class="media">
                  <div class="profile-shadow w-100">
                    <h6 class="font-15 mb-3">
                      Settings
                    </h6>
                    <div
                      class="tab-pane rightbar-tab active"
                      id="settings-tab"
                      role="tabpanel"
                    >
                      <h6 class="right-bar-heading p-2 px-3 mt-2 text-uppercase">
                        Account Setting
                      </h6>
                      <div class="px-2">
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Sync Contacts
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input type="checkbox">
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Auto Update
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input type="checkbox">
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Recieve Notifications
                          </p>
                        </div>
                      </div>
                      <h6 class="right-bar-heading p-2 px-3 mt-2 text-uppercase">
                        Mail Setting
                      </h6>
                      <div class="px-2">
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Mail Auto Responder
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Auto Trash Delete
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input type="checkbox">
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Custom Signature
                          </p>
                        </div>
                      </div>
                      <h6 class="right-bar-heading p-2 px-3 mt-2 text-uppercase">
                        Chat Setting
                      </h6>
                      <div class="px-2">
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Show Online
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Chat Notifications
                          </p>
                        </div>
                      </div>
                      <div class="px-2 text-center pt-4">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                        >
                          Set Default
                        </a>
                        <button
                          class="ripple-button ripple-button-primary btn-sm"
                          type="button"
                        >
                          <div class="ripple-ripple js-ripple">
                            <span class="ripple-ripple__circle" />
                          </div>
                          Ripple Effect
                        </button> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <info-general />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import navbar from '../../components/navbar.vue';
const cover = require('../../assets/img/profileCover.png')
import SwitchAccount from '../../components/user/profile/switch.vue'
import Avatar from '../../components/defaultAvatar.vue'
import InfoGeneral from '../../components/user/profile/infoGeneral.vue'
import OnlineUsers from '../../components/user/profile/onlineUsers.vue'
import Team from '../../components/user/profile/team.vue'
import { mapGetters } from 'vuex';
import axios from 'axios';
import { BASE } from '../../constants/app';
import { UPDATE_PHOTO_PROFILE } from '../../graphql/user'
export default {
    components: {Avatar, SwitchAccount, InfoGeneral, OnlineUsers, Team },
    data(){
        return {
          cover,
          navbarItems: [
            {
                libelle: 'Utilisateurs',
                route: 'users'
            },
            {
              libelle: 'Profile'
            }
          ],
          photo: null,
          loading: false,
          pageIcon: 'la-user',
          pageTitle: 'Profile',
          pageDescription: 'Information sur le compte de l\'utilisateur',
        }
    },
    methods: {
      selectPhoto(){
        console.log('click sur edition photo profile')
        document.getElementById('selectPhotoProfile').click()
      },
      uploadPhoto(){
        this.photo = this.$refs.file.files[0];
        const formData = new FormData();
        formData.append('file', this.photo);
        this.loading = true
        // formData.append('oldfile', this.photoName);
        const headers = { 
            'Content-Type': 'multipart/form-data',
            'authorization': 'Bearer '+ this.token
        };
        let url =  (process.env.NODE_ENV === 'production')? `https://${BASE}/upload`: `http://${BASE}/upload`
        axios.post(url, formData, { headers }).then(({data}) => {
            this.hasPhoto = true
            console.log(data)
            this.$apollo.mutate({
                mutation: UPDATE_PHOTO_PROFILE,
                variables: {
                    "photo": JSON.stringify(data),
                    "uid": this.me.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Phto updated successfully.`)
                }
              }).catch((error) => {
                console.error(error)
                this.loading = false
            })
        }, error => {
            console.error(error)
        }).finally(() => {
            this.loading = false
        });
      }
    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
            me: 'auth/me',
            typeComptes: 'auth/typeComptes',
            is_super_admin: 'auth/is_super_admin'
        }),
        hasUser(){
          return this.me !== null
        },
        fullname(){
          if(this.hasUser) return this.me.fullname
          return '-'
        },
        hasPhoto(){
          if(this.hasUser) return this.me.photo !== null
          return false
        },
        typeCompte(){
          if(this.hasUser) return this.typeComptes.find(item => item.uid === this.me.typeCompte).libelle
          return '-'
        },
        
    }
}
</script>

<style lang="scss" scoped>
.profile-left{
  position: relative;
  top: -200px;
  left: 10px;
  background: white;
  box-shadow: none;
}
</style>