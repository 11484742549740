<template>
    <div class="d-flex align-items-center overflow-auto">
        <online-user v-for="u in filterOnlineUsers" :key="u" :user-uid="u" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import OnlineUser from './onlineUser.vue'
export default {
    components: {OnlineUser},
    computed:{
        ...mapGetters({
            onlineUsers: 'chat/connectedUsers'
        }),
        filterOnlineUsers(){
            return Array.from(new Set(this.onlineUsers.map(item =>  item.uid)))
        }
    }
}
</script>