export const LIST_OF_LABEL = () => {
    return [
        {type: 'red', libelle: 'Rouge'},
        {type: 'warning', libelle: 'Orange'},
        {type: 'primary', libelle: 'Bleu'},
        {type: 'success', libelle: 'Vert'},
    ].sort((a,b) => a.libelle.localeCompare(b.libelle))
}

export const EVENT_DURATION = 30
export const EVENT_ALL_DAY_DURATION = 1439
export const EVENT_UPDATE_TOLERANCE = 36000
export const EVENT_RAPPEL_TOLERANCE  = 300

export const RAPPEL_DELAYS =[
    { label: '5 min avant', value: 5 },
    { label: '10 min avant', value: 10 },
    { label: '15 min avant', value: 15 },
    { label: '30 min avant', value: 30 },
    { label: '45 min avant', value: 45 },
    { label: '1 h avant', value: 60 },
    { label: '2 h avant', value: 120 },
    { label: '5 h avant', value: 300 },
    { label: '10 h avant', value: 600 },
    { label: '1 Jour avant', value: 3600 }
]