<template>
  <div class="layout-spacing" style="width: 250px; display: inline-flex; margin: 10px; border-radius: 20px; cursor: pointer" v-if="cabine !== null">
    <div class="widget" :class="cabineStatusClass" style="width: 100%;">
      <div style="min-height: 100px; text-align: center; justify-items: center;">
          <h2 class="text-center">{{ cabine.libelle }}</h2>
          <div class="row" v-if="hasAnimal">
              <div class="col-4"> 
                  <img :src="photo" width="100" height="100"/>
              </div>
              <div class="col-8">
                  <h6 style="color: white; font-weight: 700;">{{ animal.name }}</h6>
                  <small style="font-size: 12px; color: white; display: block;">{{ garde.startDate | humanDate }}</small> 
                  <small style="font-size: 12px; color: white; display: block;">au</small>
                  <small style="font-size: 12px; color: white;">{{ garde.endDate | humanDate }}</small>
              </div>
          </div>
          <div style="position: absolute; bottom: 10px; right: 10px; color: white; font-size: 12px" v-if="cabine.monitoring">
            Sous vidéo surveillance
          </div>
          <!-- {{ garde }} -->
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const animalPlaceholder = require('../../../assets/img/animalPlaceholder.png')
export default {
    props: {
        cabineUid: {type: String, required: true}
    },
    data() {
      return {
        cabine: null
      }
    },
    mounted() {
      this.setCabine();
    },
    watch: {
      cabines: {
        deep: true,
        handler() {
            this.setCabine();
        }
      }
    },
    methods: {
      setCabine(){
        this.cabine = this.cabines.find(cabine => cabine.uid === this.cabineUid)
      }
    },
    computed: {
       ...mapGetters({
            types: 'garderie/types',
            gardes: 'garderie/gardes',
            cabines: 'garderie/cabines',
            animaux: 'identification/animaux'
       }),
       
       garde(){
        return this.gardes.find(garde => garde.cabine === this.cabine.uid && garde.status === 'OPEN')
       },
       hasGarde(){return this.garde !== null && this.garde !== undefined},
       animal(){
            if(!this.hasGarde) return null
            return this.animaux.find(animal => animal.uid === this.garde.animal) 
       },
       hasAnimal(){
         return this.animal!== null && this.animal!== undefined;
       },
       photo(){
            if(this.hasAnimal) return this.animal.photo !== null ? JSON.parse(this.animal.photo).url : animalPlaceholder
            return animalPlaceholder 
        },
       cabineStatusClass() {
         return !this.cabine.libre ? 'cabine-occupee' : 'cabine-libre'; }
    }

}
</script>

<style> 
.cabine-libre { background-color: green; } 
.cabine-occupee { background-color: red; } 
</style>