<template>
    <div>
      <navbar
        :items="navbarItems"
        :fonticon="pageIcon"
        :navbartitle="pageTitle"
        :navbardescription="pageDescription"
      />
      <div class="car-box">
          <detail-sanitaire
              :animal="animal?.uid"
              :create-by="animal?.proprio"
              :create-at="createAt"
          >
          <template slot="header">
              <h4>{{ subject }}</h4>
          </template>
          <template slot="content">
              <div class="row info">
                  <div class="col-12">
                  <h6> Dates de la garde </h6>
                  <p>{{ gStartDate|capitalize }} -  {{ gEndDate|capitalize }}</p>
                  </div>
              </div>
              <div class="row info">
                  <div class="col-12">
                  <h6> Retour Vidéo </h6>
                  <p>{{ monitoring? 'Oui': 'Non' }}</p>
                  </div>
              </div>
              
              <div class="row info">
                  <div class="col-12">
                  <h6> Accompagné l'animal avec ma nourriture </h6>
                  <p>{{ demande?.withFood? 'Oui' : 'Non' }}</p>
                  </div>
              </div>
              <div class="row info">
                  <div class="col-12">
                  <h6> Promenade </h6>
                  <p>{{ promenade? 'Oui' : 'Non' }}</p>
                  </div>
              </div>
              <div class="row info" v-if="promenade">
                  <div class="col-12">
                  <h6> Fréquence de promenade par jour </h6>
                  <p>{{ demande?.nbPromenade }}</p>
                  </div>
              </div>
              <div class="row info">
                  <div class="col-12">
                  <h6> Status </h6>
                  <p>{{ status }}</p>
                  </div>
              </div>
              
              <div class="row info">
                  <div class="col-12">
                  <h6> Information Supplémentaire </h6>
                  <p>{{ complement }}</p>
                  </div>
              </div>
              <div class="row" style="margin-top: 50px" v-if="status === 'Pending'">
                  <div class="col-12 text-center actions">
                      <a 
                          href="javascript:void(0)"
                          @click.prevent="confirm"
                          class="btn btn-success btn-lg"
                      >
                          Confirmer
                      </a>
                      <a 
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#add-motif-rejet"
                          class="btn btn-dark btn-lg"
                      >
                          Rejetter
                      </a>
                  </div>
              </div>
          </template>
          </detail-sanitaire>
          <Modal
              title="Motif du rejet de demande"
              id="add-motif-rejet"
              :footer="false"
          >
              <div class="row">
                  <div class="col-12">
                      <div class="form-group">
                          <label for="add-motif">Motif du rejet de la demande de garde</label>
                          <textarea class="form-control" row="2" v-model="motif" />
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <div class="form-group actions text-right">
                          <a href="javascript:void(0)" @click.prevent="reject" class="btn btn-primary">Rejetter</a>
                          <a href="javascript:void(0)" @click.prevent="cancleReject"  class="btn btn-black">Annuler</a>
                      </div>
                  </div>
              </div>
  
          </Modal>
      </div>
    </div>
  </template>
  
  <script>
  import Navbar from '../../components/navbar.vue';
  import DetailSanitaire from '../../components/sanitaire/detailSanitaire.vue'
  import Modal from '../../components/modal.vue'
  import { mapGetters, mapMutations } from 'vuex';
  import moment from 'moment'
import { CONFIRM_DEMANDE, REJECT_DEMANDE } from '../../graphql/garderie';
  export default {
      components: {Navbar, DetailSanitaire, Modal},
      data(){
          return {
              navbarItems: [
                  {   
                      libelle: "Garderie", 
                      route: 'garderie'
                  },
                  {
                      libelle: 'Demandes de garde',
                      route: 'garderie-demandes'
                  },
                  {
                      libelle: 'Detail'
                  }
              ],
              pageIcon: 'la-calendar',
              pageTitle: 'Détail de la demande',
              pageDescription: 'Détail de la demande de garde du client CASEAC',
              motif: null
              
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE'
          }),
          confirm(){
              this.$apollo.mutate({
                  mutation: CONFIRM_DEMANDE,
                  variables: {
                      "uid": this.demande.uid
                  },
                  update: (data) => {
                      if(data) console.log(`Demande ${this.demande.uid} confirmed successfully`)
                      else console.log(`Error in confirming demande ${this.demande.uid}`)
                  }
              }).then(() => {
                  this.$router.push({name: 'garderie-demandes'})
              }).catch((error) => {
                  console.error(error)
              })
          },
          cancleReject(){
              this.motif =  null
              this.done()
          },
          reject(){
              this.$apollo.mutate({
                  mutation: REJECT_DEMANDE,
                  variables: {
                      "uid": this.demande.uid,
                      "motif": this.motif
                  },
                  update: (data) => {
                      if(data) console.log(`Demande ${this.demande.uid} rejected successfully`)
                      else console.log(`Error in rejecting demande ${this.demande.uid}`)
                  }
              }).then(async () => {
                  this.done()
                  // this.$router.push({name: 'rdv_appointment'})
              }).catch((error) => {
                  console.error(error)
              })
          }
      },
      computed: {
          ...mapGetters({
              demandes: 'garderie/demandes',
              animaux: 'identification/animaux'
          }),
          gStartDate(){
              moment.locale('fr')
              return moment(this.demande.startDate).format('dddd DD-MM-YYYY')
          },
          gEndDate(){
              moment.locale('fr')
              return moment(this.demande.endDate).format('dddd DD-MM-YYYY')
          },
          monitoring(){return this.hasDemande?  this.demande.monitoring : false},
          promenade(){return this.hasDemande?   this.demande.promenade: false},
          nbPromenade(){return this.hasDemande? this.demande.nbPromenade : 0},
          demande(){
              return this.demandes.find(item => item.uid === this.$route.params.uid)
          },
          hasDemande(){
              return this.demande !== null && this.demande !== undefined
          },
          animal(){
              if(this.hasDemande) return this.animaux.find(item => item.uid === this.demande.animal)               
              return null
          },
          subject(){
            if(this.animal) return `Demande de garde de ${this.animal.name}`
            return '-'
          },
          createAt(){
              return (this.hasDemande)? this.demande.createdAt : null
          },
          
          status(){
              return (this.hasDemande)? this.demande.status : null
          },
          complement(){
              return (this.hasDemande)? this.demande.complement : null
          },
          event(){
              return (this.hasDemande)? this.demande.event : null
          }
      }
  }
  </script>