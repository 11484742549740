<template>
  <div>
    <li
        class="menu"
        v-if="is_admin"
      >
        <router-link
          :to="{name: 'chat'}"
          aria-expanded="false"
          class="dropdown-toggle"
          @click="resetUnreadMessages"
        >
          <div class="">
            <i class="lab la-rocketchat" />
            <span>Chat</span>
          </div>
          <div v-if="showUnreadMessageBox">
              <span class="menu-badge badge-danger">{{ unreadMessages }}</span>
          </div>
        </router-link>
    </li>

    <li
      class="menu"
      v-if="is_veto || is_admin"
    >
      <router-link
        :to="{name: 'direct_sms'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-sms" />
          <span>Campagne SMS</span>
        </div>
      </router-link>
    </li>
    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#config_messagerie"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-cog" />
          <span>Configuration</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_messagerie"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-messagerie-subject' }">
            Sujet du Chat
          </router-link>
        </li>
      </ul>
    </li>
    
    
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ADD, DELETE, UPDATE } from '../../../constants/app';
import { SUBSCRIBER_CHAT_SESSION, SUBSCRIBER_CHAT_SUBJECT } from '../../../graphql/chat';
import socket from '../../../plugins/socket';
export default {
  data(){
    return {
      
    }
  },
  methods: {
      ...mapMutations({
          addChatSubject: 'chat/ADD_SUBJECT',
          updateChatSubject: 'chat/UPDATE_SUBJECT',
          deleteChatSubject: 'chat/DELETE_SUBJECT',
          updateSession: 'chat/UPDATE_SESSION',
          addSession: 'chat/ADD_SESSION',
          setOnlineUsers: 'chat/SET_CONNECTED_USERS',
          disconnetUser: 'chat/DISCONNECT_USER',
          incrementUnreadMessages: 'chat/INCREMENT_UNREAD_MESSAGES',
          resetUnreadMessages: 'chat/RESET_UNREAD_MESSAGES',
          done: 'DONE'
      }),
      
      
  },
    watch: {
        me: {
            handler(){
                if(this.me){
                    socket.emit("identify", { uid: this.me.uid, role: "agent" });
                }
            },
            immediate: true
        },
        unreadMessages(){
          console.log(`Unread messages change to ${this.unreadMessages}`)
        },
        
    },
    mounted(){
        socket.on('connected_users', (payload) => {
            this.setOnlineUsers(Object.values(payload))
        })
        socket.on('disconnected_user', (payload) => {
            console.log(`Disconnect of user ${payload.uid}`)
            this.disconnetUser(payload.uid)
        })
        
        socket.on("new_message", (payload) => {
          console.log(`New message from ${payload.sender}: ${payload.content}`)
          this.incrementUnreadMessages()
        })
        socket.on("disconnect", () => {
            console.log("Socket disconnected")
        })
    },
    apollo: {
        $subscribe: {
            chat_subject: {
                query: SUBSCRIBER_CHAT_SUBJECT,
                result({data}){
                    let s = data.chat_subject
                    if(s.type === ADD) this.addChatSubject(s.data)
                    if(s.type === DELETE) this.deleteChatSubject(s.data)
                    if(s.type === UPDATE) this.updateChatSubject(s.data)
                }
            },
            chat_session: {
              query: SUBSCRIBER_CHAT_SESSION,
              result({data}){
                let s = data.chat_session
                if(s.type === UPDATE) this.updateSession(s.data)
                if(s.type === ADD) this.addSession(s.data)
              }
            }

        }
    },
    computed: {
       ...mapGetters({
            me: 'auth/me',
            is_admin: 'auth/is_admin',
            is_veto: 'auth/is_veterinaire',
            is_super_admin: 'auth/is_super_admin',
            is_assistan_veto: 'auth/is_assistant_veterinaire',
            is_assistan_veto_major: 'auth/is_assistant_veto_major',
            is_labo_user: 'auth/is_labo_user',
            is_technicien_imagerie: 'auth/is_technicien_imagerie',
            unreadMessages: 'chat/unreadMessages'
        }),
        showUnreadMessageBox(){
          return this.unreadMessages > 0 
        }
    },

}
</script>