<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="widget-content searchable-container grid">
          <div class="">
            <div class="chat-container">
              <div class="hamburger">
                <i class="las la-bars fadeIn" />
              </div>
              <div class="user-container">
                <div class="own-details">
                  <avatar :user-uid="me.uid" size="xl" />
                  <h3>{{ fullname }}</h3>
                  <p>{{ email }}</p>
                  <!-- <div class="dropdown chat-own-setting mt-1">
                    <a
                      class="dropdown-toggle"
                      href="#"
                      role="button"
                      id="customDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ><i class="las la-cog font-20" /></a>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="customDropdown"
                      style="will-change: transform;"
                    >
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Profile</a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Chats</a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Notifications</a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Add People</a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >Help</a>
                    </div>
                  </div> -->
                </div>
                <online-users />
                <div class="user-list-box">
                  <div class="search">
                    <i class="las la-search toggle-search" />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      v-model="queryName"
                    >
                  </div>
                  <div class="people">
                    <chat-user
                      v-for="(user, index) in filterUsers"
                      :key="index"
                      :user="user"
                    />
                  </div>
                </div>
              </div>
              <chat-details v-if="activeUser !== null"/>

              <div class="chat-details empty" v-if="activeUser === null">
                <p>Please select a user to start chatting</p>
              </div>
              
              <user-details v-if="activeUser !== null" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ('../assets/css/apps/chat.css')
import navbar from '../components/navbar.vue'
import Avatar from '../components/defaultAvatar.vue'
import ChatUser from '../components/chat/user.vue'
import OnlineUsers from '../components/chat/OnlineUsers.vue'
import UserDetails from '../components/chat/userDetails.vue'
import ChatDetails from '../components/chat/chatDetails.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    components: {navbar, Avatar, ChatUser, OnlineUsers, UserDetails, ChatDetails},
    data(){
        return {
            navbarItems: [ { libelle: 'Chat' } ],
            pageIcon: 'la-rocketchat',
            pageTitle: 'Chat',
            pageDescription: 'Discussion instantanée', 
            queryName: null
        }
    },
    beforeMount(){ 
      this.queryName = null 
      const clientUID = this.$route.params.clientUID
      const sessionUID = this.$route.params.sessionUID
      if(clientUID) this.setActiveUser(clientUID)
      if(sessionUID) this.setCurrentSession(sessionUID)
    },
    methods: {
      ...mapMutations({
        setActiveUser:'chat/SET_ACTIVE_USER',
        setCurrentSession: 'chat/SET_CURRENT_SESSION'
      })
    },
    computed: {
      ...mapGetters({
        me: 'auth/me',
        users: 'auth/users',
        activeUser: 'chat/activeUser'
      }),
      filterUsers(){
        if(this.queryName === null || this.queryName === "") return this.otherUsers
        return this.otherUsers.filter(item => item.fullname.toLowerCase().includes(this.queryName.toLowerCase()))
      },
      fullname(){
        if(this.me !== null) return this.me.fullname
        return ''
      },
      email(){
        if(this.me !== null) return this.me.email
        return ''
      },
      otherUsers(){
        if(this.me !== null) return [...this.users].filter(item => item.uid !== this.me.uid).sort((a,b) => a.fullname.localeCompare(b.fullname))
        return this.users
      },
      hasMyPhoto(){
        if(this.me !== null) return this.me.photo !== null
        return false
      },
      photo(){
        if(this.hasMyPhoto) return JSON.parse(this.me.photo).url
        return null
      }
    }

}
</script>

<style>

</style>