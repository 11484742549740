<template>
    <div class="privacy-container">
      <h1>Politique de Confidentialité</h1>
      <p>Dernière mise à jour : 1er Octobre 2023</p>
  
      <section>
        <h2>Introduction</h2>
        <p>
          Bienvenue sur notre application de suivi vaccinal pour les animaux de compagnie. Nous nous engageons à protéger votre vie privée et à garantir la sécurité de vos informations personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, et protégeons vos données.
        </p>
      </section>
  
      <section>
        <h2>Informations que nous collectons</h2>
        <p>
          Nous collectons les informations suivantes pour fournir et améliorer nos services :
        </p>
        <ul>
          <li>Informations personnelles : nom, adresse e-mail, numéro de téléphone.</li>
          <li>Informations sur les animaux : nom, âge, race, historique vaccinal.</li>
          <li>Données de connexion : adresse IP, type de navigateur, pages visitées.</li>
        </ul>
      </section>
  
      <section>
        <h2>Utilisation des informations</h2>
        <p>
          Nous utilisons les informations collectées pour :
        </p>
        <ul>
          <li>Gérer et suivre les vaccinations de vos animaux de compagnie.</li>
          <li>Vous envoyer des rappels de vaccination et des notifications importantes.</li>
          <li>Améliorer notre application et nos services.</li>
          <li>Répondre à vos questions et fournir un support client.</li>
        </ul>
      </section>
  
      <section>
        <h2>Partage des informations</h2>
        <p>
          Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :
        </p>
        <ul>
          <li>Avec votre consentement explicite.</li>
          <li>Pour se conformer à une obligation légale.</li>
          <li>Pour protéger nos droits et notre propriété.</li>
        </ul>
      </section>
  
      <section>
        <h2>Sécurité des données</h2>
        <p>
          Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos informations contre tout accès non autorisé, perte, destruction ou altération.
        </p>
      </section>
  
      <section>
        <h2>Vos droits</h2>
        <p>
          Vous avez le droit d'accéder à vos informations personnelles, de les corriger, de les supprimer ou de limiter leur traitement. Pour exercer ces droits, veuillez nous contacter à l'adresse e-mail suivante : support@votreapp.com.
        </p>
      </section>
  
      <section>
        <h2>Modifications de la politique de confidentialité</h2>
        <p>
          Nous pouvons mettre à jour cette politique de confidentialité de temps en temps. Nous vous informerons de tout changement en publiant la nouvelle politique sur cette page.
        </p>
      </section>
  
      <section>
        <h2>Contact</h2>
        <p>
          Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à l'adresse e-mail suivante : support-caseac@agro-expertise.com.
        </p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Privacy',
  };
  </script>
  
  <style scoped>
  .privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1, h2 {
    color: #333;
  }
  
  p, ul {
    color: #555;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  </style>