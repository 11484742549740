<template>
  <div class="dropdown custom-dropdown-icon">
    <!-- {{ analyse }} -->
    <a
      class="dropdown-toggle"
      href="#"
      role="button"
      id="customDropdown"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span> Actions </span> 
      <i class="las la-angle-down" />
    </a>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="customDropdown"
    > 
      <router-link 
        v-if="!hasResultat && canUpdateDemande"
        :to="{ name: 'analyse_update', params: { uid: analyse.uid } }"
        class="dropdown-item"
      >
        <i class="las la-edit la-2x" />
        Modifier la demande
      </router-link>
      <a 
        class="dropdown-item" 
        href="javascript:void(0)" 
        data-toggle="modal"
        data-target="#print-fiche-analyse"
        v-if="hasFiche && hasResultat && canPrintResult && isPaid"
      > 
        <i class="las la-print la-2x" />
        Imprimer la fiche de resultat
      </a>
      <div class="dropdown-divider" />
      <a 
        v-if="hasFiche && !hasResultat && canAddResultat && isPaid"
        href="javascript:void(0)"
        class="dropdown-item"
        @click.prevent="initResultat"
        data-toggle="modal"
        data-target="#add-fiche-valeur"
      >   
        <i class="las la-plus-circle la-2x" />
        Ajouter les resultats de l'analyse
      </a>
      <a 
        class="dropdown-item"
        href="javascript:void(0)" 
        v-if="hasResultat && canAddResultat && isPaid"
        data-toggle="modal"
        data-target="#update-fiche-valeur"
        @click.prevent="setExistingResultat"
      > 
        <i class="las la-edit la-2x" />
        Modifier les resultats de l'analyse
      </a>
      
      <a 
        class="dropdown-item"
        @click.prevent="deletingResultat" 
        href="javascript:void(0)" 
        v-if="hasResultat && canDeleteResultat && isPaid"
      > 
        <i class="las la-times-circle la-2x" />
        Supprimer le resultat
      </a>
      <div class="dropdown-divider" />
      <a
        v-if="!hasFichiers && canAddFileSupplementaire && isPaid"
        href="javascript:void(0)"
        class="dropdown-item"
        data-toggle="modal"
        data-target="#add-analyse-result"
      > 
        <i class="las la-folder-plus la-2x" />
        Envoyer les resultats au propriétaire 
      </a>
      <a 
        v-if="hasFichiers && canDeleteFileSupplementaire && isPaid"
        class="dropdown-item"
        href="javascript:void(0)"  
        @click.prevent="deletingResultFiles"
      > 
        <i class="las la-times-circle la-2x" />
        Supprimer les resultats du proprietaire
      </a>
        
      <div class="dropdown-divider" />
      <!-- <a 
        href="javascript:void(0)" 
        v-if="(hasFiche && hasResultat && sendResultToProprio) | (!hasFiche && hasFichiers && sendResultToProprio)"
        class="dropdown-item"
      > 
        <i class="las la-paper-plane la-2x" />
        Envoyer resultat au proprietaire 
      </a>
      <div class="dropdown-divider" /> -->
      <a
        class="dropdown-item"
        href="javascript::void(0)"
        @click.prevent="deleting"
        style="color: #BB1B1B"
        v-if="canDeleteDemande"
      >
        <i class="las la-trash la-2x" />
        {{ deleteTitle }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { DELETE_ANALYSE, DELETE_RESULTAT_ANALYSE } from '../../../graphql/sanitaire'
import { DELETE_RESULTAT_ANALYSE_FICHE } from '../../../graphql/ficheAnalyse'

export default {
    props: {
        hasFiche: {type: Boolean, required: true},
        hasResultat: {type: Boolean, required: true},
        hasFichiers: {type: Boolean, required: true},
        analyse: {type: Object, required: true},
        demand: {type: Object, required: true}
    },
    methods: {
        ...mapMutations({
            initResultat: 'analyse/INIT_RESULTAT',
            setResultat: 'analyse/SET_RESULTAT',
        }),
        setExistingResultat(){
            let r = this.resultats.find(item => item.analyse === this.analyse.uid)
            if(r !== null && r !== undefined){
                this.initResultat()
                this.setResultat(JSON.parse(r.data))
            }
        },
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_ANALYSE,
                variables: {
                    "uid": this.analyse.uid
                },
                update: (data) => {
                    if(data) console.log(`Analyse ${this.analyse.uid} deleted successfully`)
                    else console.log(`Error in deletting analyse ${this.analyse.uid}`)
                }
              }).then(() => {
                this.$router.push({name: 'analyses'})
              })
        },
        deletingResultFiles(){
            let r = this.fichiers.find(item => item.analyse === this.analyse.uid)
            this.$apollo.mutate({
                mutation: DELETE_RESULTAT_ANALYSE,
                variables: {
                    "uid": r.uid
                },
                update: (data) => {
                    if(data) console.log(`Analyse result files ${r.uid} of analyse ${this.analyse.uid} deleted successfully`)
                    else console.log(`Error in deletting analyse result filte ${this.analyse.uid}`)
                }
              }).then(() => {
                // this.$router.push({name: 'analyses'})
              })
        },
        deletingResultat(){
          let r = this.resultats.find(item => item.analyse === this.analyse.uid)
          this.$apollo.mutate({
            mutation: DELETE_RESULTAT_ANALYSE_FICHE,
            variables: {
                "uid": r.uid
            },
            update: (data) => {
                if(data) console.log(`Analyse result ${r.uid} of analyse ${this.analyse.uid} deleted successfully`)
                else console.log(`Error in deletting result analyse ${this.analyse.uid}`)
            }
          }).then(() => {
            console.log("success")
          })
        },
    },
    computed: {
        ...mapGetters({
            fichiers: 'sanitaire/resultatAnalyses',
            resultats: 'analyse/resultatAnalyses',
            is_super_admin: 'auth/is_super_admin',
            is_admin: 'auth/is_admin',
            is_veterinaire: 'auth/is_veterinaire',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
            is_labo_user: 'auth/is_labo_user',
            is_technicien_imagerie: 'auth/is_technicien_imagerie'
        }),
        deleteTitle(){
          if(this.hasResultat || this.hasFichiers) return 'Supprimer l\'analyse'
          return 'Supprimer la demande'
        },
        isMyDemande(){
            return true
        },
        canUpdateDemande(){
          return this.is_super_admin || this.is_veterinaire
        },
        canDeleteDemande(){
          return this.is_admin || this.is_veterinaire
        },
        canAddResultat(){
          return this.is_super_admin || 
            this.is_labo_user || 
            this.is_technicien_imagerie
        },
        isPaid(){
          return this.analyse.status === 'Paid' | this.demand.derogation
        },
        canDeleteResultat(){
          return this.is_super_admin || 
            this.is_labo_user || 
            this.is_technicien_imagerie || 
            this.is_veterinaire
        },
        canAddFileSupplementaire(){
          return this.is_super_admin ||  
          this.is_technicien_imagerie || 
          this.is_assistant_veto_major || 
          this.is_veterinaire ||
          this.is_labo_user
        },
        canDeleteFileSupplementaire(){
          return this.is_super_admin ||  
            this.is_technicien_imagerie || 
            this.is_veterinaire || 
            this.is_assistant_veto_major || 
            this.is_labo_user
        },
        canPrintResult(){
          return this.is_admin || 
            this.is_labo_user || 
            this.is_technicien_imagerie || 
            this.is_veterinaire ||
            // this.is_assistant_veterinaire || 
            this.is_assistant_veto_major
        },
        sendResultToProprio(){
          return this.is_admin || 
            this.is_veterinaire
        }
    }

}
</script>

<style lang="scss" scoped>
.dropdown-menu{
  i{
    position: relative;
    bottom: -5px;
    margin-right: 5px;
  }
}
</style>