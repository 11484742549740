<template>
    <div class="card" style="margin-bottom: 10px; margin-right: 10px; border-radius: 20px;">
        <div style="padding: 5px 20px">
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
                <img :src="animalPhoto" alt="Photo de l'animal" style="width: 70px; height: 70px;" />
                <div style="margin-left: 10px;">
                    <h6>{{ animalName }}</h6>
                    <div style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
                        <i class="fas fa-paw"></i> {{ animalEspece }}
                        <span style="margin-left: 5px;">{{ animalRace }}</span>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
        
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';
const animalPlaceholder = require('../../../assets/img/animalPlaceholder.png')
export default {
    props: {
        animal: {type: Object, required: true}
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            especes: 'identification/especes',
            races: 'identification/races',
            robes: 'identification/robes',
        }),
        animalPhoto(){
            const photo = this.animal.photo
            return photo? JSON.parse(photo).url : animalPlaceholder
        },
        animalEspece(){
            const s = this.especes.find(e => e.uid === this.animal.espece)
            return s? s.libelle : 'Inconnu'
        },
        animalRace(){
            const r = this.races.find(e => e.uid === this.animal.race)
            return r? r.libelle : 'Inconnu'
        },
        animalName(){
            return this.animal.name
        },
        animalSex(){
            return this.animal.sexe
        },
        
    }

}
</script>