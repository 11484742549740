<template>
  <div style="position: relative; border: 1px solid rgba(0,0,0,0.15); margin: 5px; padding: 10px; border-radius: 5px;" v-if="isRappelValid">
    {{ label }}
    <a v-if="!rappel.done && !accompished" @click.prevent="deleteRappel" class="btn btn-sm btn-danger" style="position: absolute; right: 5px; top: 5px">
        <i class="icofont icofont-close"></i>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RAPPEL_DELAYS } from '../../constants/events';
import { DELETE_RAPPEL_EVENT } from '../../graphql/events';
export default {
    props: {
        uid: {type: String, required: true},
        accompished: {type: Boolean, default: false}
    },
    data(){
        return {
            // Your component data here
        }
    },
    methods: {
        deleteRappel(){
            this.$apollo.mutate({
                mutation: DELETE_RAPPEL_EVENT,
                variables: {
                    "uid": this.uid
                },
                update: () => {
                    console.log(`Rappel event successfully on uid ${this.uid} deleted`)
                }
              }).catch((error) => {
                console.log(error)
            })
        }
    },
    computed: {
        ...mapGetters({
            rappels: 'events/rappels',
        }),
        rappel(){
            return this.rappels.find(r => r.uid === this.uid)
        },
        isRappelValid(){
            return!!this.rappel
        },
        label(){
            if(this.isRappelValid){
                const r = RAPPEL_DELAYS.find(item => item.value === this.rappel.timeBefore)
                return r? r.label : 'Unknown'
            }
            return '-'
        }
    }
}
</script>