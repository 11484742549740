<template>
    <div>
      {{ cabine }}
    </div>
  </template>
  
  
  
  <script>
  import { mapGetters } from 'vuex'
  export default {
      props: {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      
      computed: {
        ...mapGetters({
          cabines: 'garderie/cabines'
        }),
        cabine(){
            const c = this.cabines.find(item => item.uid === this.object.cabine)
            return c? c.libelle : 'Cabine non trouvé'
        }
      }
  
  }
  
  </script>
  <style lang="scss">
  
  </style>