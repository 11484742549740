<template>
    <div>
      {{ type }}
    </div>
  </template>
  
  
  
  <script>
  import { mapGetters } from 'vuex'
  export default {
      props: {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      
      computed: {
        ...mapGetters({
          types: 'garderie/types'
        }),
        type(){
            const t = this.types.find(item => item.uid === this.object.type)
            return t? t.libelle : 'Type non trouvé'
        }
      }
  
  }
  
  </script>
  <style lang="scss">
  
  </style>