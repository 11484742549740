<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="layout-px-spacing detail-bill">
      <div
        class="row layout-spacing layout-top-spacing"
        v-if="hasFacture"
      >
        <div class="col-lg-12 col-md-12">
          <div class="row">
            <div class="col-12">
              <div class="widget">
                <div class="widget-header">
                  <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                      <label>Status:</label> <span
                        class="badge"
                        :class="facture.status === 'Paid'? 'badge-success' : 'badge-primary'"
                      >{{ facture.status }}</span>
                    </div>
                    <div class="col-xl-10 col-lg-10 col-md-8 col-sm-8 text-right actions">
                      <a
                        href="#"
                        class="btn btn-warning btn-sm"
                        @click.prevent="print"
                        v-if="facture.status !== `Pending` && myItems.length > 0"
                      >
                        <i class="icofont icofont-print" />
                        Imprimer
                      </a>
                      <a 
                        @click.prevent="validateFacture" 
                        href="#" 
                        class="btn btn-success btn-sm" 
                        :disabled="validating"
                        v-if="facture.status === `Pending` && myItems.length > 0"
                      >
                        <loading-spiner v-if="validating" />  
                        Valider
                      </a>
                      <a 
                        @click.prevent="sendFacture" 
                        href="#" 
                        class="btn btn-secondary btn-sm" 
                        :disabled="sendingToUser"
                        v-if="facture.status === `Validated`"
                      >   
                        <loading-spiner v-if="sendingToUser" />
                        <i
                          v-else
                          class="icofont icofont-thin-double-right"
                        />
                        Envoyer au client
                      </a>
                      <a 
                        @click.prevent="cancelFacture" 
                        href="#" 
                        class="btn btn-dark btn-sm items-center" 
                        :disabled="deleting"
                        v-if="facture.status === `Validated`"
                      >
                        <loading-spiner v-if="deleting" />
                        <i
                          class="icofont icofont-close"
                          v-else
                        />
                        Annuler
                      </a>
                      <a 
                        href="#" 
                        class="btn btn-danger btn-sm"
                        @click.prevent="factureItem = null"
                        data-toggle="modal"
                        data-target="#delete-facture-item"
                        v-if="facture.status === `Pending` && myItems.length > 0"
                      >
                        <i class="icofont icofont-close" />
                        Supprimer element 
                      </a>
                      <a 
                        href="#" 
                        class="btn btn-primary btn-sm"
                        @click.prevent="initElementAddForm"
                        data-toggle="modal"
                        data-target="#add-facture-item"
                        v-if="facture.status === 'Pending'"
                      >
                        <i class="icofont icofont-plus-circle" />
                        Ajouter element
                      </a>
                    </div>
                  </div>
                </div>
                <div class="widget-content grid">
                  <div class="row">
                    <div
                      class="col-12"
                      id="facture-details"
                    >
                      <div
                        class="row"
                        id="facture-content"
                      >
                        <div class="col-12">
                          <facture-header :facture="facture" />
                          <div class="row informations">
                            <div class="col-7">
                              <div class="facture-info">
                                <div>
                                  <label>Facture N°:</label>
                                  {{ facture.invoiceNumber }}
                                </div>
                                <div>
                                  <label>Date d'emission: </label>
                                  {{ facture.createdAt|systemDateTime }}
                                </div>
                                <div>
                                  <label>Date d’échéance: </label>
                                  {{ facture.createdAt|systemDateTime }}
                                </div>
                              </div>
                            </div>
                            <div class="col-5">
                              <div class="client-info">
                                <div class="client-fullname">
                                  {{ clientFullname }}
                                </div>
                                <div class="client-telephone">
                                  <label>Téléphone: </label>{{ clientTelephone }}
                                </div>
                                <div class="client-address">
                                  <label>Email: </label>{{ clientEmail }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row content">
                            <div class="col-12">
                              <table
                                class="table table-bordered table-condensed"
                                style="font-size:12px;"
                              >
                                <thead>
                                  <tr>
                                    <th>Service</th>
                                    <th>Quantité</th>
                                    <th>P.U.</th>
                                    <th>Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in myItems"
                                    :key="item.uid"
                                  >
                                    <td>{{ item.libelle }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.unitPrice }}</td>
                                    <td>{{ item.quantity * item.unitPrice }}</td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="3"
                                      style="font-size: 14px; font-weight: 700"
                                    >
                                      Total
                                    </td>
                                    <td style="font-size: 14px; font-weight: 700">
                                      {{ facture.amount }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-12 text-lg">
                              <p>Arrêté la présente facture à <strong>{{ facture.amount }} XOF</strong> - ({{ facture.amount|toLetter|capitalize }} XOF)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row"
                        id="payment-content"
                      >
                        <div class="col-12" style="margin-top: 30px">
                          <h5>Information du paiement</h5>
                          <div class="row">
                            <div
                              class="col-12" style="margin-top: 20px"
                            >
                              {{ billData }}
                            </div>
                          </div>
                          <div
                            class="overlay"
                            v-if="loadingPaiementInfo"
                          >
                            <div class="spinner" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-else
      >
        <p class="alert alert-danter">
          Facture non trouvée!
        </p>
      </div>
    </div>
    <Modal
      id="add-facture-item"
      title="Ajouter une ligne a la facture"
      :footer="false"
      size="medium"
    >
      <form
        @submit.prevent="addFactureItem"
        class="form-horizontal"
      >
        <div
          class="row"
          v-if="has_error"
        >
          <div class="col-12">
            <div class="alert alert-danter">
              <p> {{ error_msg }}</p>    
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="libelle">Libelle</label>
              <input
                type="text"
                id="libelle"
                class="form-control"
                v-model="libelle"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="quantity">Quantité</label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                class="form-control"
                v-model="quantity"
              >
            </div>
          </div>
          <div class="col-8">
            <div class="form-group">
              <label for="price">Prix Unitaire</label>
              <input
                class="form-control"
                name="price"
                id="price"
                v-model="price"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group text-right">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="addingItem"
              >
                <loading-spiner v-if="addingItem" /> Ajouter
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>

    <Modal
      id="delete-facture-item"
      size="sm"
      title="Supprimer un element de la facture"
      :footer="false"
    >
      <form
        @submit.prevent="deleteItem"
        class="form-horizontal"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="facture-item">Element de la facture</label>
              <select
                class="form-control"
                v-model="factureItem"
                id="facture-item"
                name="facture-item"
                v-select="{placeholder: 'Selectionnez l\'element à supprimer'}"
              >
                <option
                  v-for="item in myItems"
                  :key="item.uid"
                  :value="item.uid"
                >
                  {{ item.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group text-right">
              <button
                type="submit"
                class="btn btn-danger"
                :disabled="deletingItem"
              >
                <loading-spiner v-if="deletingItem" /> Supprimer
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Navbar from '../../components/navbar.vue';
import Modal from '../../components/modal.vue';
import FactureHeader from '../../components/market/facture/header.vue'
import LoadingSpiner from '../../components/loadingSpiner.vue';
import { Select } from '../../directive/select';
import {mapGetters, mapMutations} from 'vuex'
import axios from 'axios'
import { Printd } from 'printd'
import { ADD_FACTURE_ITEM, DELETE_FACTURE, DELETE_FACTURE_ITEM, SEND_FACTURE, VALIDATE_FACTURE } from '../../graphql/market';
import { BASE_URL } from '../../constants/app';
export default {
    components: {Navbar, Modal, FactureHeader, LoadingSpiner},
    directives: {Select},
    data(){
        return {
            navbarItems: [
              {
                libelle: 'Market Place',
                route: 'market-dashboard'
              }, {
                libelle: 'Facturation',
                route: 'market-billing'
              },
              {libelle: 'Detail'}
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Facturation',
            pageDescription: 'Detail de la facture sur CASEAC',
            libelle: null,
            quantity: null,
            price: null,
            has_error: false,
            error_msg: null,
            factureItem: null,
            validating: false,
            sendingToUser: false,
            addingItem: false,
            deletingItem: false,
            deleting: false,
            loadingPaiementInfo: false,
            billData: null
        }
    },
    watch:{
        facture: {
            handler(){
                this.loadBillDetail()
            },  
            deep: true
        }
    },
    mounted(){
        if(this.factureIsPaid || this.factureIsSentToClient){
            // loadPaiment information
            this.loadBillDetail()
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE'
        }),

        loadBillDetail(){
            this.loadingPaiementInfo = true
            this.billData = null
            axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
            axios.get(`${BASE_URL}/api/v1/bill/${this.$route.params.uid}/payment`).then(({data}) => {
                console.log(data)
                this.billData = data
            }).finally(() => {
                this.loadingPaiementInfo = false
            })
        },
        initElementAddForm(){
            this.libelle = null
            this.quantity = null
            this.price = null
            this.has_error = false
            this.error_msg = null
        },
        async addFactureItem(){
            this.addingItem = true
            let data = {
                libelle: this.libelle,
                quantity: parseInt(this.quantity),
                unitPrice: parseFloat(this.price),
                facture: this.facture.uid
            }
            try {
                await this.$apollo.mutate({
                    mutation: ADD_FACTURE_ITEM,
                    variables: {
                        item: { ...data }
                    },
                    update: (cache, { data: { addFactureItem } }) => {
                        console.log(addFactureItem);
                        console.log(`Facture item added successfully with uid ${addFactureItem}`);
                    }
                });
                this.done();
                this.initElementAddForm();
            } catch (error) {
                console.error(error);
                this.has_error = true;
                this.error_msg = error.message || 'An error occurred while adding the facture item.';
            }
            this.addingItem = false
        },
        async cancelFacture(){
            this.deleting = true
            try {
                await this.$apollo.mutate({
                    mutation: DELETE_FACTURE,
                    variables: {
                        uid: this.facture.uid
                    },
                    update: (cache, { data: { deleteFacture } }) => {
                        if(deleteFacture)
                        console.log(`Facture ${this.facture.uid} deleted successfully!`);
                    }
                });
            } catch (error) {
                console.error(error);
            }
            this.deleting = false
            // redirect user to list of facture
            this.$router.push({ name: 'market-billing'})
        },
        async validateFacture(){
            this.validating = true
            try {
                await this.$apollo.mutate({
                    mutation: VALIDATE_FACTURE,
                    variables: {
                        uid: this.facture.uid
                    },
                    update: (cache, { data: { validateFacture } }) => {
                        if(validateFacture)
                        console.log(`Facture ${this.facture.uid} validated successfully!`);
                    }
                });
            } catch (error) {
                console.error(error);
            }
            this.validating = false
        },

        async sendFacture(){
            this.sendingToUser = true
            try {
                await this.$apollo.mutate({
                    mutation: SEND_FACTURE,
                    variables: {
                        uid: this.facture.uid
                    },
                    update: (cache, { data: { sendFacture } }) => {
                        if(sendFacture)
                        console.log(`Facture ${this.facture.uid} sent for payment successfully!`);
                    }
                });
            } catch (error) {
                console.error(error);
            }
            this.sendingToUser = false
        },
        async deleteItem(){
            this.deletingItem = true
            try {
                await this.$apollo.mutate({
                    mutation: DELETE_FACTURE_ITEM,
                    variables: {
                        uid: this.factureItem
                    },
                    update: (cache, { data: { deleteFactureItem } }) => {
                        if(deleteFactureItem)
                        console.log(`Facture item deleted successfully with uid ${this.factureItem}`);
                    }
                });
                this.done();
                this.factureItem = null;
            } catch (error) {
                console.error(error);
                this.has_error = true;
                this.error_msg = error.message || 'An error occurred while adding the facture item.';
            }
            this.deletingItem = false
        },
        print(){
          const styles = [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css',
            'https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css',
            'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css',
            `#facture-details {
                padding: 50px 150px;
            }

            #facture-details #facture-content {
                padding-top: 20px;
                border: solid 1px rgba(0, 0, 0, 0.12);
            }

            #facture-details #facture-content .informations {
                padding: 50px 20px 30px 20px;
                font-size: 11px;
            }

            #facture-details #facture-content .informations label {
                font-size: 12px;
                font-weight: 700;
                color: rgba(0, 0, 0, 0.72);
                margin-right: 10px;
            }

            #facture-details #facture-content .informations .facture-info {
                /* Empty block */
            }

            #facture-details #facture-content .informations .client-info .client-fullname {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            #facture-details #facture-content .content {
                padding: 20px;
            }
            `
          ]
          const d = new Printd()
          d.print( document.getElementById('facture-details') , styles)
          d.onAfterPrint((event) => {
            console.log('After print trigger with event', event)
            this.done()
          })
        }
    },
    computed: {
        ...mapGetters({
            factures: 'market/factures',
            items: 'market/factureItems',
            users: 'auth/users',
            token: 'auth/token'
        }),
        client(){ return this.users.find(item => item.uid === this.facture.user)},
        clientFullname(){return this.client? this.client.fullname: '-'},
        clientTelephone(){return this.client? this.client.telephone: '-'},
        clientEmail(){return this.client? this.client.email: '-'},
        myItems(){
            if(!this.hasFacture) return []
            return [...this.items].filter(item => item.facture === this.facture.uid)
        },
        facture(){
            return this.factures.find(facture => facture.uid === this.$route.params.uid)
        },
        hasFacture(){
            return  this.facture !== null && this.facture !== undefined
        },
        factureIsPaid(){
            return this.hasFacture && this.facture.status === 'Paid'
        },
        factureIsSentToClient(){
          return this.hasFacture && this.facture.status === 'Sent'
        },
        billRecipt(){
            if(this.hasFacture && this.factureIsPaid && this.billData !== null) return JSON.parse(this.billData.data)
            return null
        }
    }
}
</script>

<style lang="scss">
.widget {
    position: relative;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left-color: #000;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
    }
}





@keyframes spin {
    to { transform: rotate(360deg); }
}

.detail-bill{
    .widget-header{
        // padding: 10px 20px;
        border-bottom: 1px solid rgba(0,0,0,0.05);
    }
    #facture-details{
        padding: 50px 150px;
        // border: solid 1px rgba(0,0,0,0.12);
        #facture-content{
            border: solid 1px rgba(0,0,0,0.12);
            .informations{
                padding: 50px 20px 30px 20px;
                font-size: 11px;
                label{
                    font-size: 12px;
                    font-weight: 700;
                    color: rgba(0,0,0,.72);
                    margin-right: 10px;
                }
                .facture-info{

                }
                .client-info{
                    .client-fullname{
                        font-size: 14px;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                }
            }
            .content{
                padding: 20px;
            }
        }

        #payment-content{

        }
    }
    .actions{
        margin-bottom: 20px;
        a{
            margin: 5px;
            font-size: 15px;
        }
    }
}
</style>