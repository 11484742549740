<template>
  <div style="border: 1px solid rgba(0,0,0,0.15); padding: 30px; margin: 20px 5px; border-radius: 5px;">
    <h5> Analyse {{  index }}</h5>
    <div class="row">
        <div :class="showCategorie? 'col-md-6':'col-md-12'">
          <div class="form-group">
            <label :for="'type'+index">Type d'Analyse</label>
            <select
              class="form-control"
              :name="'type'+index"
              :id="'type'+index"
              v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
              v-model="analyse.type"
            >
              <option value="" />
              <option
                v-for="(t, i) in types"
                :value="t.uid"
                :key="i"
              >
                {{ t.libelle }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-md-6"
          v-show="showCategorie"
        >
          <div class="form-group">
            <label :for="'categorie'+index">Categorie</label>
            <select
              class="form-control"
              :name="'categorie'+index"
              :id="'categorie'+index"
              v-select="{placeholder: 'Selectionnez la catégorie d\'analyse'}"
              v-model="analyse.categorie"
            >
              <option value="" />
              <option
                v-for="(c, i) in filterdCategories"
                :value="c.uid"
                :key="i"
              >
                {{ c.libelle }}
              </option>
            </select>
          </div>
        </div>
    </div>

    <div
        class="row"
        v-show="showCategorie && showTypeRecherche"
      >
        <div :class="showExamen? 'col-md-6':'col-md-12'">
          <div class="form-group">
            <label :for="'type-recherche'+index">Type de recherche</label>
            <select
              class="form-control"
              :name="'type-recherche'+index"
              :id="'type-recherche'+index"
              v-select="{placeholder: 'Selectionnez le type de recherche'}"
              v-model="analyse.typeRecherche"
            >
              <option value="" />
              <option
                v-for="(t, i) in filterTypeRecherches"
                :value="t.uid"
                :key="i"
              >
                {{ t.libelle }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-md-6"
          v-show="showExamen"
        >
          <div class="form-group">
            <label :for="'examen'+index">Examen</label>
            <select
              class="form-control"
              :name="'examen'+index"
              :id="'examen'+index"
              v-select="{placeholder: 'Selectionnez l\'examen'}"
              v-model="analyse.examen"
            >
              <option value="" />
              <option
                v-for="(e, i) in filterExamens"
                :value="e.uid"
                :key="i"
              >
                {{ e.libelle }}
              </option>
            </select>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        index: {type: Number, required: true}
    },
    data(){
        return {
            analyse: {
                type: null,
                categorie: null,
                typeRecherche: null,
                examen: null
            }
            
        }
    },
    watch: {
        analyse: {
            handler(){
                this.$emit('analyse', {order: this.index, analyse: this.analyse})
            },
            deep: true
        }
    },  
    computed: {
        ...mapGetters({
            types: 'sanitaire/typeAnalyses',
            categories: 'sanitaire/categorieAnalyses',
            typeRecherches: 'sanitaire/typeRecherches',
            examens: 'sanitaire/examens',
        }),
        showCategorie(){
            if(this.analyse.type !== null){
                let type = this.types.find(item => item.uid === this.analyse.type)
                return type.hasCategorie
            }
            return false
        },
        showTypeRecherche(){
            if(this.analyse.categorie !== null){
                let categorie = this.categories.find(item => item.uid === this.analyse.categorie)
                return categorie.hasRecherche
            }
            return false
        },
        showExamen(){
          if(this.analyse.typeRecherche !== null){
            let type = this.typeRecherches.find(item => item.uid === this.analyse.typeRecherche)
            return type.hasExamen
          }
          return false
        },
        filterdCategories(){
            if(this.analyse.type !== null) return [...this.categories].filter(item => item.typeAnalyse === this.analyse.type)
            return []
        },
        filterTypeRecherches(){
            if(this.analyse.categorie !== null) return [...this.typeRecherches].filter(item => item.categorieAnalyse === this.analyse.categorie)
            return []
        },
        filterExamens(){
            if(this.analyse.typeRecherche !== null) return [...this.examens].filter(item => item.typeRecherche === this.analyse.typeRecherche)
            return []
        }
    }
}
</script>