<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div
        class="col-3"
        :style="animalPhotoClass"
        @mouseenter="updatePhoto = true"
        @mouseleave="updatePhoto = false"
      >
        <div
          class="actions"
          style="position: absolute; top: 50%; left: 30%;"
          v-if="animal.active"
        >
          <a
            href="javascript:void(0)"
            class="btn btn-outline-secondary"
            @click.prevent="selectFile"
            v-if="canAddPhoto"
          >
            <i class="icofont icofont-pencil" />
          </a>
          <a 
            href="javascript:void(0)"
            class="btn btn-outline-danger"
            @click.prevent="deletePhoto"
            v-if="canAddPhoto && hasPhoto"
          > 
            <i class="icofont icofont-close" />
          </a>
        </div>
        
        
        <form
          action=""
          style="display: none;"
        >
          <input
            type="file"
            @change="animalChangePhoto"
            id="selectFile"
            ref="file"
          >
        </form>
      </div>
      <div class="col-9 animal-detail">
        <div class="card-box">
          <div class="ribbon-four" style="background-color: brown;" v-if="!animal.active">Mort : {{ dateDeces|showTime }}</div>
          <div class="ribbon-content">
            <h3 class="name text-danger">
              {{ nom }}
            </h3>
            <div class="row">
              <div class="col-6">
                <div><label for="code_iso">Code ISO</label> {{ codeIso }}</div>
                <div><label for="espece">Espèce</label> {{ espece }}</div>
                <div><label for="race">Race</label> {{ race }}</div>
              </div>
              <div class="col-6">
                <div><label for="taille">Robe</label> {{ robe }} </div>
                <div><label for="sexe">Sexe</label> {{ sexe }}</div>
                <div><label for="poids">Date Nais.</label> {{ dateNaissance|showTime }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 info-supplementaire">
                <h5>Information supplémentaire</h5>
                <p>{{ info }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <div class="col-6 proprio">
        <div class="card-box">
          <h3>Propriétaire</h3>
          <div class="row">
            <div class="col-12">
              <label for="fullname_proprio">Nom complet</label>
              {{ proprietaire?.fullname }}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="phone_proprio">Téléphone</label>
              {{ proprietaire?.telephone }}
            </div>
          </div>

          <div class="row">
            <div class="col-12">
                <label for="email_proprio">Email</label>
                {{ proprietaire?.email }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 accompagnant">
        <div class="card-box">
          <h3>Accompagnant</h3>
          <div class="row">
            <div class="col-12">
              <label for="fullname_proprio">Nom complet</label>
              {{ accompagnant?.fullname }}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="phone_proprio">Téléphone</label>
              {{ accompagnant?.telephone }}
            </div>
          </div>

          <div class="row">
            <div class="col-12">
                <label for="email_proprio">Email</label>
                {{ accompagnant?.email }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ('../../../assets/css/ui-elements/ribbons.css')
import {mapGetters} from 'vuex'
import navbar from '../../../components/navbar.vue'
import { BASE } from '../../../constants/app'
import { EDIT_ANIMAL } from '../../../graphql/identification'
import axios from 'axios'
const animalPlaceholder = require('../../../assets/img/animalPlaceholder.png')
export default{
    components: {navbar},
    data(){
        return {
          updatePhoto: false,
          animalPhoto: null,
            navbarItems: [
              {
                  libelle: 'Identification'
              },
              {
                libelle: 'Animaux',
                route: 'animaux'
              },
                {
                libelle: 'Détail d\'un animal'
              }
            ],
            pageIcon: 'la-dog',
            pageTitle: 'Détail de l\' animal',
            pageDescription: 'Detail  de l\'animal  sur CASEAC',
        
        }
    },
    methods: {
      selectFile(){
        document.getElementById("selectFile").click()
      },
      animalChangePhoto(){
        this.animalPhoto = this.$refs.file.files[0];
        const formData = new FormData();
        formData.append('file', this.animalPhoto);
        // formData.append('oldfile', this.photoName);
        const headers = { 
          'Content-Type': 'multipart/form-data',
          'authorization': 'Bearer '+ this.token
         };
        let url =  (process.env.NODE_ENV === 'production')? `https://${BASE}/upload`: `http://${BASE}/upload`
        axios.post(url, formData, { headers }).then(({data}) => {
          this.$apollo.mutate({
                mutation: EDIT_ANIMAL,
                variables: {
                  "uid": this.animal.uid,
                    "animal": {
                        telephone_proprio: this.proprietaire.telephone,
                        fullname_proprio: this.proprietaire.fullname,
                        email_proprio: this.proprietaire.email,
                        photo: JSON.stringify(data)
                    }
                },
                update: () => {
                  console.log('Photo animal update successfully')
                }
            })
          console.log(data)
        });
        console.log('changing animal photo')
      },
      deletePhoto(){
        const headers = { 
          'Content-Type': 'multipart/form-data',
          'authorization': 'Bearer '+ this.token
         };
        console.log(headers)
      }
    },
    computed: {
        ...mapGetters({
          token: 'auth/token',
            animaux: 'identification/animaux',
            deces: 'identification/declarationDeces',
            races: 'identification/races',
            especes: 'identification/especes',
            robes: 'identification/robes',
            proprietaires: 'auth/proprietaires',
            accompagnants: 'auth/accompagnateurs',
            is_veterinaire: 'auth/is_veterinaire',
            is_admin: 'auth/is_admin',
            is_assistant_veto_major: 'auth/is_assistant_veto_major'
        }),
        animal(){
            return [...this.animaux].find(item => item.uid === this.$route.params.uid)
        },
        hasAnimal(){
          return this.animal !== undefined && this.animal !== null
        },
        
        nom(){
          if(this.hasAnimal) {
            if(this.animal.name !== null)
              return this.animal.name
            return '???'
          }
          return '-'
        },
        codeIso(){
          if(this.hasAnimal) return this.animal.codeIso
          return '-'
        },
        dateNaissance(){
          if(this.hasAnimal) return this.animal.dateNaissance
          return null
        },
        dateDeces(){
          if(!this.hasAnimal | this.animal.active) return null
          const dead = this.deces.find(item => item.animal === this.animal.uid)
          if(dead) return dead.dateDeces
          return null
        },
        espece(){
            if(this.hasAnimal){
              let e = this.especes.find(item => item.uid === this.animal.espece)
              if(e) return e.libelle + ' ['+e.code+']'
              return '-'
            }
            return null
        },
        race(){
            if(this.hasAnimal){
                return [...this.races].find(item => item.uid === this.animal.race).libelle
            }
            return null
        },
        robe(){
            if(this.hasAnimal){
                return [...this.robes].find(item => item.uid === this.animal.robe).libelle
            }
            return null
        },
        sexe(){
            if(this.hasAnimal){
                if(this.animal.sexe === 'm') return 'Mâle'
                return 'Femelle'
            }
            return '-'
        },
        info(){
          if(this.hasAnimal) return this.animal.signe
          return '-'
        },
        hasPhoto(){
          return (this.hasAnimal && this.animal.photo !== null)
        },
        photo(){
          return (this.hasPhoto)?  JSON.parse(this.animal.photo).url : animalPlaceholder
        },
        photoName(){
          if(this.hasAnimal && this.animal.photo !== null) { let p = JSON.parse(this.animal.photo); return p.name }
          return null
        },
        animalPhotoClass(){
          return {
            "background-image": "url("+this.photo+")",
            "background-repeat": "no-repeat",
            "background-size": "contain",
            "background-position": "center center",
            "border-radius": "5px"
          }
        },
        proprietaire(){
          return this.proprietaires.find(item => item.uid === this.animal.proprio)
        },
        accompagnant(){
          return this.accompagnants.find(item => item.uid === this.animal.accompagnant)
        },
        canAddPhoto(){
          return this.updatePhoto && (this.is_admin || this.is_veterinaire || this.is_assistant_veto_major)
        }
    }
}
</script>
<style lang="scss" scoped>
.animal-detail{
  label{
    width: 25%;
  }
  .name{
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .info-supplementaire{
    h5{
      font-size: 16px;
      margin: 20px 0px;
    }
    p{
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
.proprio, .accompagnant{
  h3{
    font-size: 20px;
    margin-bottom: 20px;
  }
  label{
    width: 25%;
  }
}
</style>