import { login, sigup, signOut } from '../../plugins/authService'
import { TYPE_ADMIN,  TYPE_SUPER_ADMIN, TYPE_PROPRIO, TYPE_LABORATOIRE, TYPE_TECHNICIEN_IMAGERIE, TYPE_VETERIANIRE, TYPE_ASSISTANT_VETERINAIRE, TYPE_ASSISTANT_VETERINAIRE_MAJOR, TYPE_ACCOMPAGNANT, TYPE_ELEVEUR, TYPE_REVENDEUR } from '../../constants/app'

const state = {
  me: null,
  switchAccount: false,
  switchAccountTo: null,
  token: login() || null,
  connected: true,
  typeComptes: [],
  pays: [],
  users: [],
  sms: [],
  directSms: []
}

const getters = {
  me: state => { 
    if(state.switchAccount) return state.switchAccountTo
    return state.me
  },
  switchAccountTo: state => state.switchAccountTo,
  switchAccount: state => state.switchAccount,
  token: state => state.token,
  connected: state => state.connected,
  typeComptes: state => state.typeComptes,
  pays: state => state.pays,
  typeUtilisateur: state =>{
    if(state.switchAccount) return state.typeComptes.find(item => item.uid === state.switchAccountTo.typeCompte)
    return state.typeComptes.find(item => item.uid === state.me.typeCompte)
  } ,
  is_admin: (state) => {
    let type = (state.switchAccount) ? state.typeComptes.find(item => item.uid === state.switchAccountTo.typeCompte) : state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_ADMIN || type.code === TYPE_SUPER_ADMIN
    return false
  },
  is_super_admin: state => {
    let type = (state.switchAccount) ? state.typeComptes.find(item => item.uid === state.switchAccountTo.typeCompte) : state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_SUPER_ADMIN
    return false
  },
  is_veterinaire: state => {
    let type = (state.switchAccount) ? state.typeComptes.find(item => item.uid === state.switchAccountTo.typeCompte) : state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_VETERIANIRE
    return false
  },
  is_assistant_veterinaire: state =>{
    let type = (state.switchAccount) ? state.typeComptes.find(item => item.uid === state.switchAccountTo.typeCompte) : state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_ASSISTANT_VETERINAIRE
    return false
  },
  is_assistant_veto_major: state =>{
    let type = (state.switchAccount) ? state.typeComptes.find(item => item.uid === state.switchAccountTo.typeCompte) : state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_ASSISTANT_VETERINAIRE_MAJOR
    return false
  },
  is_labo_user: state => {
    let type = (state.switchAccount) ? state.typeComptes.find(item => item.uid === state.switchAccountTo.typeCompte) : state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_LABORATOIRE
    return false
  },
  is_technicien_imagerie: state => {
    let type = (state.switchAccount) ? state.typeComptes.find(item => item.uid === state.switchAccountTo.typeCompte) : state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_TECHNICIEN_IMAGERIE
    return false
  },
  users: state => [...state.users].sort((a,b) => a.fullname.localeCompare(b.fullname)),
  proprietaires: state => {
    let type = state.typeComptes.find(item => item.code === TYPE_PROPRIO)
    if(type!== undefined) return state.users.filter(item => item.typeCompte === type.uid)
    return []
  },
  accompagnateurs: state => {
    let type = state.typeComptes.find(item => item.code === TYPE_ACCOMPAGNANT)
    if(type!== undefined) return state.users.filter(item => item.typeCompte === type.uid)
    return []
  },
  laboUsers: state =>  {
    let type = state.typeComptes.find(item => item.code === TYPE_LABORATOIRE)
    if(type!== undefined) return state.users.filter(item => item.typeCompte === type.uid)
    return []
  },
  activAgents: state => {
    let types = state.typeComptes.filter(item => ![TYPE_ACCOMPAGNANT, TYPE_PROPRIO, TYPE_ELEVEUR, TYPE_REVENDEUR].includes(item.code))
    const codes = types.map(t => t.uid)
    return state.users.filter(item => item.active &&  codes.includes(item.typeCompte))
  },
  sms: state => state.sms,
  directSms: state => state.directSms
}

const actions = {
    
}

const mutations = {
  SET_ME: (state, me) => {
    state.me = me
  },
  SET_SWITCH_ACCOUNT_TO: (state, account) => {
    state.switchAccount = !(account.uid === state.me.uid)
    state.switchAccountTo = account
  },
  LOGIN_USER: (state, token) =>{
    state.token = token,
    sigup(token)
  },
  LOGOUT_USER: (state) => {
    state.me = null,
    state.token = null,
    signOut()
  },
  SET_CONNECTED: (state, etat) => {
    state.connected = etat
  },
  SET_TYPE_COMPTES: (state, typecomptes) => {
      state.typeComptes = typecomptes
  },
  ADD_TYPE_COMPTE: (state, type) => {
    state.typeComptes.push(type)
  },
  UPDATE_TYPE_COMPTE: (state, type) => {
    state.typeComptes = state.typeComptes.filter(item => item.uid !== type.uid)
    state.typeComptes.push(type)
  },
  DELETE_TYPE_COMPTE: (state, type) => {
    state.typeComptes = state.typeComptes.filter(item => item.uid !== type.uid)
  },
  SET_PAYS: (state, pays) => {
    state.pays = pays
  },
  SET_USERS: (state, users) => {
      state.users = users
  },
  ADD_USER: (state, user) => {
      state.users.push(user)
  },
  UPDATE_USER: (state, user) => {
      state.users = state.users.filter(item => item.uid !== user.uid)
      state.users.push(user)
  },
  DELETE_USER: (state, user) => {
    state.users = state.users.filter(item => item.uid !== user.uid)
  },
  SET_SMS: (state, list) => {
    state.sms = list
  },
  ADD_SMS: (state, msg) => {state.sms.push(msg)},

  SET_DIRECT_SMS: (state, list) => { state.directSms = list},
  ADD_DIRECT_SMS: (state, msg) => {state.directSms.push(msg)},
  DELETE_DIRECT_SMS: (state, msg) => {state.directSms = state.directSms.filter(item => item.uid !== msg.uid)}
  // ADD_PRESENCE: (state, presence) => {
  //   state.presences.push(presence)
  // },
  // UPDATE_PRESENCE: (state, presence) => {
  //   state.presences = state.presences.filter(item => item.uid !== presence.uid)
  //   state.presences.push(presence)
  // }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
