import gql from 'graphql-tag'


export const ADD_TYPE_ROBE = gql`
    mutation Mutation($type: TypeRobeInput!) {
        addTypeRobe(type: $type)
    }
`

export const UPDATE_TYPE_ROBE = gql`
    mutation Mutation($uid: String!, $type: TypeRobeInput!) {
        updateTypeRobe(uid: $uid, type: $type)
    }
`

export const DELETE_TYPE_ROBE = gql`
mutation Mutation($uid: String!) {
    deleteTypeRobe(uid: $uid)
}
`

export const SUBSCRIBE_TYPE_ROBE = gql`
subscription Subscription {
    typerobe {
        type
        data {
            uid
            libelle
        }
    }
}
`

export const ADD_ROBE = gql`
    mutation Mutation($robe: RobeInput!) {
        addRobe(robe: $robe)
    }
`

export const UPDATE_ROBE = gql`
    mutation Mutation($uid: String!, $robe: RobeInput!) {
        updateRobe(uid: $uid, robe: $robe)
    }
`
export const DELETE_ROBE = gql`
mutation Mutation($uid: String!) {
    deleteRobe(uid: $uid)
}
`

export const SUBSCRIBE_ROBE = gql`
subscription Subscription {
    robe {
        type
        data {
            uid
            libelle
            code
            espece
            type
        }
    }
}
`

export const ADD_ESPECE = gql`
mutation Mutation($espece: EspeceInput) {
        addEspece(espece: $espece)
    } 
`
export const EDIT_ESPECE = gql`
mutation Mutation($uid: String!, $espece: EspeceInput) {
        updateEspece(uid: $uid, espece: $espece)
    }
`
export const DELETE_ESPECE = gql`
mutation Mutation($uid: String!) {
  deleteEspece(uid: $uid)
}
`
export const SUBSCRIBE_ESPECE = gql`
subscription Subscription {
  espece {
    type
    data {
      uid
      libelle
      code
      active
    }
  }
}
`



export const ADD_RACE = gql`
mutation Mutation($race: RaceInput!) {
        addRace(race: $race)
    } 
`
export const EDIT_RACE = gql`
mutation Mutation($uid: String!, $race: RaceInput!) {
        updateRace(uid: $uid, race: $race)
    }
`
export const DELETE_RACE = gql`
mutation Mutation($uid: String!) {
  deleteRace(uid: $uid)
}
`
export const SUBSCRIBE_RACE = gql`
subscription Subscription {
  race {
    type
    data {
        uid
        libelle
        code
        espece
    }
  }
}
`

export const ADD_ALIMENTATION = gql`
mutation Mutation($alimentation: AlimentationInput!) {
    addAlimentation(alimentation: $alimentation)
    } 
`
export const EDIT_ALIMENTATION = gql`
mutation Mutation($uid: String!, $alimentation: AlimentationInput!) {
    updateAlimentation(uid: $uid, alimentation: $alimentation)
    }
`
export const DELETE_ALIMENTATION = gql`
mutation Mutation($uid: String!) {
    deleteAlimentation(uid: $uid)
}
`
export const SUBSCRIBE_ALIMENTATION = gql`
subscription Subscription {
  alimentation {
    type
    data {
        uid
        libelle
    }
  }
}
`


export const ADD_MOTIF_DECES = gql`
mutation Mutation($motif: MotifDecesInput!) {
  addMotifDeces(motif: $motif)
    } 
`
export const EDIT_MOTIF_DECES = gql`
mutation Mutation($uid: String!, $motif: MotifDecesInput!) {
  updateMotifDeces(uid: $uid, motif: $motif)
    }
`
export const DELETE_MOTIF_DECES = gql`
mutation Mutation($uid: String!) {
  deleteMotifDeces(uid: $uid)
}
`
export const SUBSCRIBE_MOTIF_DECES = gql`
subscription Subscription {
  motif_deces {
    type
    data {
        uid
        libelle
        code
    }
  }
}
`

export const ADD_ACTION_POST_MORTEM = gql`
mutation Mutation($action: ActionPostMortemInput!) {
  addActionPostMortem(action: $action)
    } 
`
export const EDIT_ACTION_POST_MORTEM = gql`
mutation Mutation($uid: String!, $action: ActionPostMortemInput!) {
  updateActionPostMortem(uid: $uid, action: $action)
    }
`
export const DELETE_ACTION_POST_MORTEM = gql`
mutation Mutation($uid: String!) {
  deleteActionPostMortem(uid: $uid)
}
`
export const SUBSCRIBE_ACTION_POST_MORTEM = gql`
subscription Subscription {
  action_post_mortem {
    type
    data {
        uid
        libelle
        code
        prix
        currency
    }
  }
}
`

/** DECLARATION DE DECES */


export const ADD_DECLARATION_DECES = gql`
mutation Mutation($declaration: DeclarationDecesInput!) {
  addDeclarationDeces(declaration: $declaration)
    } 
`
export const CANCEL_DECLARATION_DECES = gql`
mutation Mutation($uid: String!) {
  cancelDeclarationDeces(uid: $uid)
}
`
export const SUBSCRIBE_DECLARATION_DECES = gql`
subscription Subscription {
  declaration_deces {
    type
    data {
        uid
        motif
        animal
        info
        createBy
        createdAt
        dateDeces
        actionsPostMortem
    }
  }
}
`

/**
 * ANIMAL
 */
export const ADD_ANIMAL = gql`
    mutation Mutation($animal: AnimalInput!) {
        addAnimal(animal: $animal)
    } 
`
export const EDIT_ANIMAL = gql`
    mutation Mutation($uid: String, $animal: AnimalUpdateInput!) {
        updateAnimal(uid: $uid, animal: $animal)
    }
`
export const DELETE_ANIMAL = gql`
  mutation Mutation($uid: String!){
  deleteAnimal(uid: $uid)
}
`
export const SUBSCRIBE_ANIMAL = gql`
subscription Subscription {
  animal {
    type
    data {
      uid
      codeIso
      espece
      robe
      race
      proprio
      accompagnant
      sexe
      signe
      dateNaissance
      active
      name
      photo
      createdAt
      parrain
    }
  }
}
`


/** CONSTANTE */
export const ADD_CONSTANTE = gql`
mutation Mutation($constante: ConstanteInput!) {
  addConstante(constante: $constante)
}`

export const UPDATE_CONSTANTE = gql`
mutation Mutation($uid: String!, $constante: ConstanteInput!) {
  updateConstante(uid: $uid, constante: $constante)
}
`
export const DELETE_CONSTANTE = gql`
mutation Mutation($uid: String!) {
  deleteConstante(uid: $uid)
}
`

export const SUBSCRIBE_CONSTANTE = gql`
subscription Subscription {
  constante {
    type
    data {
      uid
      animal
      poid
      temperature
      fr
      fc
      createBy
      createdAt
      trc
      operationDate
    }
  }
}
`