<template>
  <div class="product-images" style="position: relative;">
    <img
      :src="currentPhoto"
      class="main-image"
      alt="Product Image"
    >
    <a 
      class="btn btn-outline-danger btn-rounded" 
      style="position: absolute; top: 10px; right: 20px;" 
      href="javascript:void(0)"
      @click.prevent="deleteProductPhoto"
    >
      <i class="icofont icofont-close" />
    </a>
    <div class="thumbnails">
      <a
        v-for="(p, index) in photos"
        :key="index"
        @click.prevent="updateCurrentPhoto(p.url)"
      >
        <img
          :src="p.url"
          class="thumbnail"
          alt="Thumbnail"
        />
        <span style="position: absolute; top: 5px;">
          <i class="icofont icofont-times icofont-2x" />
        </span>
      </a>
      <a
        v-if="photos.length < 4"
        class="thumbnail add-photo"
      > 
        <add-product-photo
          :index="0"
          @photo-add="addProductPhoto"
          width="80px"
        />
      </a>
    </div>
  </div>
</template>
  
  <script>
  import { ADD_PRODUCT_PHOTO, DELETE_PRODUCT_PHOTO } from '../../../graphql/market';
  import AddProductPhoto from '../addProductPhoto.vue';
  export default {
    props: {
      photos: { type: Array, required: true },
      defaultPhoto: { type: String, required: true },
      product: {type: Object, required: true}
    },
    components:{ AddProductPhoto },
    data() {
      return {
        currentPhoto: this.defaultPhoto,
        selectedPhoto: null
      };
    },
    watch: {
      currentPhoto(){
        this.selectedPhoto = this.setSelectedPhoto(this.currentPhoto);
      }
    },
    mounted(){
      this.selectedPhoto = this.setSelectedPhoto(this.currentPhoto);
    },
    methods: {
      updateCurrentPhoto(photoUrl) {
        this.currentPhoto = photoUrl;
      },
      setSelectedPhoto(url){
        return this.photos.find(item => item.url === url)
      },
      addProductPhoto(payload){
        try {
            this.$apollo.mutate({
                mutation: ADD_PRODUCT_PHOTO, 
                variables: {
                    photo: JSON.stringify(payload),
                    uid: this.product.uid
                },
                update: (cache, { data: { addProductPhoto } }) => {
                    console.log(addProductPhoto);
                    console.log(`Photo successfull add on project with status ${addProductPhoto}`);
                }
            });
            
        } catch (error) {
            console.error(error);
        }
      },
      deleteProductPhoto(){
        try {
            this.$apollo.mutate({
                mutation: DELETE_PRODUCT_PHOTO, 
                variables: {
                    photo: JSON.stringify(this.selectedPhoto),
                    uid: this.product.uid
                },
                update: (cache, { data: { deleteProductPhoto } }) => {
                    console.log(deleteProductPhoto);
                    console.log(`Photo successfull add on project with status ${deleteProductPhoto}`);
                }
            });
            
        } catch (error) {
            console.error(error);
        }
      }
      
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .product-images {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .main-image {
      width: 300px;
      height: 300px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  
    .thumbnails {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 10px;
      cursor: pointer;
  
      a {
        margin-right: 10px;
        .thumbnail {
          width: 60px !important;
          height: 60px !important;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        &.add-photo { display: flex; align-items: center; justify-content: center; font-size: 24px; color: #007bff; border: 2px dashed #007bff; cursor: pointer; transition: color 0.3s, border-color 0.3s; } 
        &.add-photo:hover { color: #0056b3; border-color: #0056b3; }
      }
    }
  }
  </style>
  