<template>
    <div class="actions">
      <router-link
        :to="{name: 'rdv_appointment_detail', params: {uid: object.uid} }"
        class="btn btn-secondary btn-sm"
      >
        <i class="icofont icofont-eye" />
      </router-link>
      
      <a
        href="javascript:void(0)"
        class="btn btn-third btn-sm"
        @click.prevent="deleting"
        v-if="is_super_admin"
      > <i class="icofont icofont-close" /></a>
    </div>
  </template>
  
  
  
  <script>
  import { mapGetters } from 'vuex'
    import { DELETE_APPOINTMENT } from '../../../graphql/events';
  export default {
      props: {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
        deleting(){
            this.$apollo.mutate({
                  mutation: DELETE_APPOINTMENT,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`Appointment ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting appointment ${this.object.uid}`)
                  }
                })
          }
      },
      computed: {
        ...mapGetters({
          is_super_admin: 'auth/is_super_admin',
          is_admin: 'auth/is_admin',
          is_veto: 'auth/is_veterinaire',
          is_assistant_veto_major: 'auth/is_assistant_veto_major'
        })
      }
  
  }
  
  </script>
  <style lang="scss">
  
  </style>