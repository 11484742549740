<template>
    <li 
        class="list-group-item d-flex justify-content-between align-items-center" 
        v-if="hasSubject" 
        @click.prevent="selectClosedSession"
        style="cursor: pointer;"
    >
        <div class="d-flex" style="flex-direction: row; align-items: center; justify-items: flex-start;">
            <avatar :user-uid="sender? sender.uid : 'Unknown'"  size="xs"/>
            <div style="margin-left: 10px;">
                <h5 class="text-muted strong font-12">{{ subject.libelle }}</h5>
                <p class="text-muted font-11">{{ lastMessage?.content}}</p>
                <small class="text-muted font-10 text-right">{{ lastMessage?.createdAt |time }}</small>
            </div>
        </div>
        <span class="badge badge-primary badge-pill">{{ messageCount }}</span>
    </li>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { GET_CLIENT_LAST_MESSAGE, COUNT_CLIENT_SESSION_MESSAGES } from '../../../graphql/chat';
import Avatar from '../../defaultAvatar.vue'
export default {
    props: {
        session: {type: Object, required: true}
    },
    components: {Avatar},
    data(){
        return {
            loadLastMessage: true,
            lastMessage: null,
            messageCount: null,
            error_msg: null
        }
    },
    mounted() { this.loadLastMessage = false},
    watch: {
        activeUser(){
            this.loadLastMessage = true
        },
        messageCount(){
            console.log(`Session ${this.session.ui} have ${this.messageCount} messages`)
        }
    },
    apollo: {
        loadUserLastMessage: {
          query: GET_CLIENT_LAST_MESSAGE,
          skip () {
            return this.loadLastMessage
          },
          variables(){
            return {
                "session": this.session.uid,
            }
          },
          error (error) {
            this.error_msg = JSON.stringify(error.message)
            this.loadLastMessage = true
          },
          update ({lastSessionMessage}) {
            if(lastSessionMessage){
              this.lastMessage = lastSessionMessage
            }
            this.loadLastMessage = true
          }
        },
        loadMessageCount: { 
            query: COUNT_CLIENT_SESSION_MESSAGES, 
            skip() { return this.loadLastMessage }, 
            variables() { return { "session": this.session.uid, } }, 
            error(error) { 
                this.error_msg = JSON.stringify(error.message) 
                console.error(this.error_msg)
                this.loadLastMessage = true 
            }, 
            update({ countSessionMessages }) { 
                console.log(`Session ${this.session.uid} have ${countSessionMessages} messages`)
                if (countSessionMessages) { 
                    this.messageCount = countSessionMessages 
                } 
                this.loadLastMessage = true 
            }
            
        }
    },
    methods: {
        ...mapMutations({
            setCurrentSession: 'chat/SET_CURRENT_SESSION'
        }),
        selectClosedSession(){
            this.setCurrentSession(this.session.uid)
            // this.$router.push({name: 'chat.messages', params: { session: this.session.uid }})
        }
    },
    computed: {
       ...mapGetters({
            subjects: 'chat/subjects',
            users: 'auth/users',
            activeUser: 'chat/activeUser'
        }),
        subject(){
            return this.subjects.find(s => s.uid === this.session.subject) 
        },
        hasSubject(){
            return this.subject !== null && this.subject !== undefined
        },
        sender(){
            if(this.lastMessage === null || this.lastMessage === undefined) return null
            return this.users.find(item => item.uid === this.lastMessage.sender)
        },
        senderFullname(){
            if(this.sender!== null){
                return this.sender.fullname
            }
            return 'Annonyme'
        }
    },
}
</script>