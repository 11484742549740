<template>
  <div v-if="hasCategory">
    {{ category.libelle }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        object: {type: Object, required: true}
    },
    computed: {
        ...mapGetters({
            categories: 'market/categories'
        }),
        category(){
            return this.categories.find(t => t.uid === this.object.categorie);
        },
        hasCategory(){
            return!!this.category;
        }
    }

}
</script>