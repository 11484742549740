<template>
  <div class="layout-spacing">
    <a class="widget quick-category">
      <div class="quick-category-head">
        <span class="quick-category-icon qc-success-teal rounded-circle">
          <i class="las la-users" />
        </span>
        <div class="ml-auto" />
      </div>
             
      <div class="quick-category-content">
        <h3 class="text-success-teal">{{ utilisateurs.length }}</h3>
        <p class="font-17 text-success-teal mb-0">Utilisateurs</p>
        <div class="chart"> 
          <apexchart
            type="donut"
            width="210"
            :options="chartOptions"
            :series="chartData"
          />
        </div>
      </div>
    </a>
  </div>
</template>
  
  <script>
  import {mapGetters} from 'vuex'
  import apexchart from 'vue-apexcharts'
  export default {
      components: {apexchart},
      data(){
          return {
              chartTypes: [],
              chartData: []
          }
      },
      watch: {
          updateChart: {
              handler(){
                  this.initChart()
              },
              deep: true
          }
      },
      mounted(){
          this.initChart()
      },  
      methods: {
          initChart(){
              this.chartData = []
              this.chartTypes = []
              this.typeUsers.forEach(item => {
                  this.chartTypes.push(item.libelle)
                  this.chartData.push([...this.utilisateurs].filter(i => i.typeCompte === item.uid).length)
              })
          }
      },
      computed: {
          ...mapGetters({
              utilisateurs: 'auth/users',
              typeUsers: 'auth/typeComptes',
          }),
          updateChart(){
              return {u: this.utilisateurs, t: this.typeUsers}
          },
          chartOptions(){
              return {
                  chart: {
                      width: 210,
                      type: 'donut',
                      
                  },
                  plotOptions: {
                      pie: {
                          donut: {
                              labels: {
                                  show: false
                              }
                          },
                          startAngle: 0,
                          endAngle: 360
                      }
                  },
                  labels: this.chartTypes,
                  dataLabels: {
                      enabled: true
                  },
                  fill: {
                      type: 'gradient',
                  },
                  legend: {
                      show: false,
                      formatter: function(val, opts) {
                          return val + " - " + opts.w.globals.series[opts.seriesIndex]
                      }
                  },
                  title: {
                      enabled: false,
  
                  },
                  responsive: [{
                      breakpoint: 480,
                      options: {
                          chart: {
                          width: 200
                          },
                          legend: {
                          position: 'bottom'
                          }
                      }
                  }]
              }
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  
  .quick-category-content{
      position: relative;
      .chart{
          position: absolute;
          top: -90px;
          left: 25%;
          min-height: 300px;
      }
  }
  
  </style>