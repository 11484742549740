<template>
    <div>
      <navbar
        :items="navbarItems"
        :fonticon="pageIcon"
        :navbartitle="pageTitle"
        :navbardescription="pageDescription"
      />
      <div class="card-box">
          <data-table
          :data-source="sortedDemandes"
          :headers="headers"
          able-class="table-striped table-hover"
      >
        <template slot="actions">
          
          <a 
              href="javascript:void(0)" 
              class="btn btn-outline-primary btn-sm"
              @click.prevent="filter=null"
          >
              Tout ({{ demandes.length }})
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-outline-warning btn-sm"
            @click.prevent="filter='Pending'"
          >
            En attente ({{ pendingDemandes.length }})
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-outline-secondary btn-sm"
            @click.prevent="filter='Confirmed'"
          >
            Confirmed ({{ confirmDemandes.length }})
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-outline-success btn-sm"
            @click.prevent="filter='Honor'"
          >
            Honor ({{ honorDemandes.length }})
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-outline-dark btn-sm"
            @click.prevent="filter='Rejected'"
          >
            Cancel ({{ rejectDemandes.length }})
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-outline-danger btn-sm"
            @click.prevent="filter='No-show'"
          >
            No-show ({{ noShowDemandes.length }})
          </a>
        </template>
      </data-table>
      </div>
      
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import moment from 'moment'
  import Navbar from '../../components/navbar.vue';
  import DataTable from '../../components/dataTable/local.vue'
  import { BOOLEAN_TYPE, COMPONENT_TYPE, DATE_TYPE, NUMERIC_TYPE, TEXT_TYPE } from '../../components/dataTable/dataType';
  const Animal = () => import('../../components/sanitaire/animal.vue')
  const Action = () => import('../../components/garderie/demande/actions.vue')
  export default {
      components: {Navbar, DataTable},
      data(){
          return {
              navbarItems: [
                  {   
                      libelle: "Garderie", 
                      route: 'garderie'
                  },
                  {
                      libelle: 'Demandes de garde'
                  }
              ],
              pageIcon: 'la-calendar',
              pageTitle: 'Garderie',
              pageDescription: 'List des demandes de garde CASEAC',
              filter: null
              
          }
      },
      computed: {
          ...mapGetters({
              demandes: 'garderie/demandes',
              is_super_admin: 'auth/is_super_admin',
          }),
          sortedDemandes(){
              if(this.filter !== null) return [...this.demandes].filter(item => item.status === this.filter).sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
              return [...this.demandes].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
          },
          confirmDemandes(){
              return [...this.demandes].filter(item => item.status === 'Confirmed')
          },
          pendingDemandes(){
              return [...this.demandes].filter(item => item.status === 'Pending')
          },
          rejectDemandes(){
              return [...this.demandes].filter(item => item.status === 'Rejected')
          },
          noShowDemandes(){
              return [...this.demandes].filter(item => item.status === 'No-show')
          },
          honorDemandes(){
            return [...this.demandes].filter(item => item.status === 'Honor')
          },
          
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                  {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                  {label: 'Date de début', name: 'startDate', type: DATE_TYPE, sortable: true},
                  {label: 'Date de fin', name: 'endDate', type: DATE_TYPE, sortable: true},
                  {label: 'Retour Vidéo', name: 'monitoring', type: BOOLEAN_TYPE},
                  {label: 'Ma nourriture', name: 'withFood', type: BOOLEAN_TYPE},
                  {label: 'Promenade', name: 'promenade', type: BOOLEAN_TYPE},
                  {label: 'Freq. Promenade', name: 'nbPromenade', type: NUMERIC_TYPE},
                  {label: 'Status', name: 'status', type: TEXT_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Action}
                  
              ]
          }
      }
  }
  </script>
  <style lang="scss" scoped>
    .actions{
      a{
        font-size: 10px !important;
        padding: 2px !important;
      }
      
    }
  </style>