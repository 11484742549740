<template>
  <div class="chat-user-details visible">
    <div class="hide-chat-user-details">
        <i class="las la-arrow-left" />
    </div>
    <div class="other-details" v-if="hasUser">
        <avatar :user-uid="activeUser" size="xl"/>
        <h3>{{ user.fullname }}</h3>
        <p>{{ user.telephone }}</p>
    </div>
    <div class="other-section">
        <h5 class="chat-headings"> Discussions Fermées 
            <a
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#chatAllClosedDiscussion"
            >
                <span class="pointer"  v-if="closedSessions.length > 0">
                    Voir Tout
                </span>
            </a>
        </h5>
        <div v-if="closedSessions.length === 0">
            <p class="text-danger font-10 items-center justify-center">Aucune discussion fermée pour le moment.</p>
        </div>
        <closed-session v-for="s in closedSessions" :key="s.uid" :session="s"/>
    </div>
    <!-- <div class="single-chat-option">
        <a><i class="las la-envelope" /> Send Email</a>
    </div>
    <div class="single-chat-option">
        <a><i class="las la-volume-mute" /> Mute Notificatios</a>
    </div> -->
    <!-- <div class="single-chat-option">
        <a><i class="las la-eye-slash" /> Hide Medias</a>
    </div>
    <div class="single-chat-option">
        <a><i class="las la-thumbtack" /> Pin to top</a>
    </div>
    <div class="single-chat-option">
        <a><i class="las la-cloud-download-alt" /> Export Chat</a>
    </div> -->
    <div class="single-chat-option" v-if="is_super_admin">
        <a class="text-danger"><i class="las la-eraser" /> Clear All Messages</a>
    </div>
    <!-- <div class="single-chat-option" v-if="is_super_admin">
        <a class="text-danger"><i class="las la-trash-alt" /> Delete User</a>
    </div> -->
    <div class="single-chat-option" v-if="is_super_admin">
        <a class="text-danger"><i class="las la-ban" /> Block User</a>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClosedSession from './closedSession.vue'
import Avatar from '../defaultAvatar.vue'
export default {
    components: {Avatar, ClosedSession },
    
    computed: {
       ...mapGetters({
            users: 'auth/users',
            sessions: 'chat/sessions',
            activeUser: 'chat/activeUser',
            is_super_admin: 'auth/is_super_admin'
       }),
       user(){
        return this.users.find(user => user.uid === this.activeUser);
       },
       hasUser(){
        return this.user !== null && this.user !== undefined;
       },
       closedSessions(){
        return this.sessions.filter(session => session.status === 'Closed').slice(0,5);
       }
    },

}
</script>