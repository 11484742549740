<template>
    <div>
        <navbar
            :items="navbarItems"
            :fonticon="pageIcon"
            :navbartitle="pageTitle"
            :navbardescription="pageDescription"
        />
        <div class="layout-px-spacing detail-bill">
        <div
            class="row layout-spacing layout-top-spacing"
            v-if="hasOrder"
        >
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <div class="col-12">
                        <div class="widget">
                            <div class="widget-header">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                                        <label>Status:</label> 
                                        <span
                                            class="badge"
                                            :class="order.status === 'Paid'? 'badge-success' : 'badge-primary'"
                                        >
                                            {{ order.status }}
                                        </span>
                                    </div>
                                    <div class="col-xl-10 col-lg-10 col-md-8 col-sm-8 text-right actions">
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="widget-content grid">
                                <div class="row">
                                    <div
                                        class="col-12"
                                        id="facture-details"
                                    >
                                        <div
                                            class="row"
                                            id="facture-content"
                                        >
                                            <div class="col-12">
                                                <order-header/>
                                                <div class="row informations">
                                                    <div class="col-7">
                                                        <div class="facture-info">
                                                            <div>
                                                                <label>Commande N°:</label>
                                                                {{ order.orderNumber }}
                                                            </div>
                                                            <div>
                                                                <label>Date de commande: </label>
                                                                {{ order.createdAt|systemDateTime }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="client-info">
                                                            <div class="client-fullname">
                                                                {{ clientFullname }}
                                                            </div>
                                                            <div class="client-telephone">
                                                                <label>Téléphone: </label>{{ clientTelephone }}
                                                            </div>
                                                            <div class="client-address">
                                                                <label>Email: </label>{{ clientEmail }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row content">
                                                    <div class="col-12">
                                                        <table
                                                            class="table table-bordered table-condensed"
                                                            style="font-size:12px;"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>Produit</th>
                                                                    <th>Quantité</th>
                                                                    <th>P.U.</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <order-item v-for="(item, index) in orderDetails" :key="index" :order-item="item" />
                                                            <tr>
                                                                <td
                                                                colspan="3"
                                                                style="font-size: 14px; font-weight: 700"
                                                                >
                                                                Total
                                                                </td>
                                                                <td style="font-size: 14px; font-weight: 700">
                                                                {{ order.amount }}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="row"
            v-else
        >
            <p class="alert alert-danter">
            Commande non trouvée!
            </p>
        </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Navbar from '../../components/navbar.vue';
import OrderHeader from '../../components/market/order/header.vue'
import OrderItem from '../../components/market/order/OrderItem.vue'
export default {
    components: {Navbar, OrderHeader, OrderItem},
    data(){
        return{
            navbarItems: [
              {
                libelle: 'Market Place',
                route: 'market-dashboard'
              }, 
              {
                libelle: 'Commandes',
                route: "market-order"
              },
              {
                libelle: 'Détails'
              }
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Détails',
            pageDescription: 'Détails de la commande',
        }
    },
    computed: {
        ...mapGetters({
            orders: 'market/orders',
            products: 'market/products',
            users: 'auth/users',
        }),
        client(){ return this.users.find(item => item.uid === this.order.user)},
        clientFullname(){return this.client? this.client.fullname: '-'},
        clientTelephone(){return this.client? this.client.telephone: '-'},
        clientEmail(){return this.client? this.client.email: '-'},
        order(){
            return this.orders.find(item => item.uid === this.$route.params.uid)
        },
        hasOrder(){
            return this.order  !== null && this.order !== undefined
        },
        orderDetails(){
            return this.hasOrder ? JSON.parse(this.order.details) : []
        },
        orderIsPaid(){
            return this.hasOrder && this.order.status === 'Paid'
        },
    }
}   
</script>

<style lang="scss" scoped>
.widget {
    position: relative;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left-color: #000;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
    }
}
@keyframes spin {
    to { transform: rotate(360deg); }
}
.detail-bill{
    .widget-header{
        // padding: 10px 20px;
        border-bottom: 1px solid rgba(0,0,0,0.05);
    }
    #facture-details{
        padding: 50px 150px;
        // border: solid 1px rgba(0,0,0,0.12);
        #facture-content{
            border: solid 1px rgba(0,0,0,0.12);
            .informations{
                padding: 50px 20px 30px 20px;
                font-size: 11px;
                label{
                    font-size: 12px;
                    font-weight: 700;
                    color: rgba(0,0,0,.72);
                    margin-right: 10px;
                }
                .facture-info{

                }
                .client-info{
                    .client-fullname{
                        font-size: 14px;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                }
            }
            .content{
                padding: 20px;
            }
        }

        #payment-content{

        }
    }
    .actions{
        margin-bottom: 20px;
        a{
            margin: 5px;
            font-size: 15px;
        }
    }
}
</style>