import gql from 'graphql-tag'


export const ADD_CHAT_SUBJECT = gql`
mutation Mutation($subject: ChatSubjectInput!){
    addChatSubject(subject: $subject)
}
`
export const UPDATE_CHAT_SUBJECT = gql`
  mutation Mutation($uid: String!, $subject: ChatSubjectInput!){
    updateChatSubject(uid: $uid, subject: $subject)
  }
`
export const DELETE_CHAT_SUBJECT = gql`
mutation Mutation($uid: String!) {
    deleteChatSubject(uid: $uid)
}
`

export const SUBSCRIBER_CHAT_SUBJECT = gql`
subscription Subscription {
  chat_subject {
    type
    data {
        uid
        libelle
        code
        agents
    }
  }
}
`

export const START_CHAT_SESSION = gql`

mutation Mutation($client: String!, $subject: String!) {
    startChatSession(client: $client, subject: $subject)
}`

export const CLOSE_CURRENT_SESSION = gql`
mutation Mutation($uid: String!) {
    closeChatSession(uid: $uid)
}
`

export const SUBSCRIBER_CHAT_SESSION = gql`
subscription Subscription {
  chat_session {
    type
    data {
        uid
        client
        agent
        subject
        status
        attempts
        handled
    }
  }
}
`

export const GET_CLIENT_LAST_HANDLED_SESSION = gql`
query LastHandledChatSession($client: String!) {
  lastHandledChatSession(client: $client) {
    uid
    client
    agent
    subject
    status
    attempts
    handled
  }
}
`

export const GET_CLIENT_LAST_MESSAGE = gql`
query LastSessionMessage($session: String!) {
  lastSessionMessage(session: $session) {
    uid
    session
    sender
    content
    createdAt
  }
}
`
export const COUNT_CLIENT_SESSION_MESSAGES = gql`
  query ClientSessionMessagesCount($session: String!) {
    countSessionMessages(session: $session)
  }`

export const GET_CLIENT_SESSIONS = gql`
query ClientSessions($client: String!) {
  clientSessions(client: $client) {
    uid
    client
    agent
    subject
    status
    attempts
    handled
  }
}
`
export const GET_CLIENT_MESSAGES = gql`
query ChatMessages($session: String!) {
  chatMessages(session: $session) {
    uid
    session
    sender
    content
    createdAt
  }
}
`