<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedVaccinations"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'veille-sanitaire'}"
          href="javascript:void(0)"
          class="btn btn-outline-third  btn-lg"
          v-if="is_super_admin || is_veterinaire || is_assistant_veto || is_assistant_veto_major || is_admin"
        >
          Veille sanitaire
        </router-link>
        <router-link
          :to="{name: 'add-vaccination'}"
          class="btn btn-outline-primary btn-lg"
          v-if="is_super_admin || is_veterinaire || is_assistant_veto || is_assistant_veto_major"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
  </div>
</template>
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import moment from 'moment'
  import DataTable from '../../../components/dataTable/local.vue'
  import navbar from '../../../components/navbar.vue'
  import {  TEXT_TYPE, DATE_TYPE, COMPONENT_TYPE} from '../../../components/dataTable/dataType'
  const Animal = () => import('../../../components/sanitaire/animal.vue')
  const CreatedBy = () => import('../../../components/sanitaire/createdBy.vue')
  const Vaccin = () => import('../../../components/sanitaire/vaccination/vaccin.vue')
  const Maladie = () => import('../../../components/sanitaire/vaccination/maladie.vue')
  const Actions = () => import('../../../components/sanitaire/vaccination/actions.vue')
  export default {
      components: {navbar, DataTable },
      data(){
          return {
            navbarItems: [
              {
                  libelle: 'Sanitaire'
              },
              {
                libelle: 'Vaccination',
              }
            ],
            pageIcon: 'la-id-card',
            pageTitle: 'Vaccination',
            pageDescription: 'Liste des vaccinations',
        
        
            maladie: null,
            vaccin: null,
            animal: null,
            info: null,  
            has_error: false,
            error_msg: null
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE'
          }),
          
      },
      computed: {
          ...mapGetters({
              vaccinations: 'sanitaire/vaccinations',
              is_super_admin: 'auth/is_super_admin',
              is_admin: 'auth/is_admin',
              is_veterinaire: 'auth/is_veterinaire',
              is_assistant_veto: 'auth/is_assistant_veterinaire',
              is_assistant_veto_major: 'auth/is_assistant_veto_major'
          }),
          sortedVaccinations(){
            return [...this.vaccinations].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin },
                  {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                  {label: 'Maladie', name: 'maladie', type: COMPONENT_TYPE, component: Maladie},
                  {label: 'Vaccin', name: 'vaccin', type: COMPONENT_TYPE, component: Vaccin},
                  {label: 'Date de vaccination', name: 'dateVaccination', type: DATE_TYPE, sortable: true},
                  {label: 'Date de Rappel', name: 'dateRappel', type: DATE_TYPE, sortable: true},
                  {label: 'Createur', name: 'createBy', type: COMPONENT_TYPE, component: CreatedBy},
                  {label: 'Date création', name: 'createdAt', type: DATE_TYPE, sortable: true},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
              ]
          }
      }
  }
  </script>