<template>
  <div>
    <div class="row">
        <div class="col-12" style="padding: 20px">
            <div class="form-group">
                <label class="checkbox checkbox-outline checkbox-outline-bold checkbox-primary">
                    <input
                        type="checkbox"
                        name="hospitalisation"
                        v-model="isHospitalisation"
                    >
                    <span style="margin-right: 20px" />{{ libelle }}
                </label>
            </div>
        </div>
    </div>
    <div class="row" v-if="isHospitalisation">
        <div class="col-6">
            <div class="form-group">
                <label for="hStartDate">Date début hospitalisation</label>
                <datepicker
                  id="hStartDate"
                  name="hStartDate"
                  input-class="form-control required"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  v-model="hospitalisation.startDate"
                />
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label for="hEndDate">Date fin hospitalisation</label>
                <datepicker
                  id="hEndDate"
                  name="hEndDate"
                  input-class="form-control required"
                  :disabled-dates="disabledEndDates"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  v-model="hospitalisation.endDate"
                />
        </div>
    </div>
    <div class="row" v-if="isHospitalisation">
        <div class="col-12" style="padding: 20px 30px">
            <div class="form-group">
                <label class="checkbox checkbox-outline checkbox-outline-bold checkbox-primary">
                <input
                  type="checkbox"
                  name="monitoring"
                  v-model="hospitalisation.monitoring"
                >
                <span style="margin-right: 20px" />Flux vidéo</label>
            </div>
        </div>
        <!-- <div class="col" style="padding: 20px">
            <div class="form-group">
                <label class="checkbox checkbox-outline checkbox-outline-bold checkbox-primary">
                    <input
                    type="checkbox"
                    name="withFood"
                    v-model="hospitalisation.withFood"
                    >
                    <span style="margin-right: 20px" />Proprietaire apporte sa propre nourriture
                </label>
    
            </div>
        </div> -->
    </div>
  </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
// import moment from 'moment'
export default {
    props: {
        libelle: {type: String, required: true}
    },
    components: { Datepicker },
    data(){
        return {
            isHospitalisation: false,
            hospitalisation:{
                startDate: null,
                endDate: null,
                monitoring: false,
                withFood: false,
                promenade: false,
                nbPromenade: 0
            }
        }
    },
    mounted(){
        this.$emit('hospitalisation', {...this.hospitalisation, hospitalisation: this.isHospitalisation });
    },
    watch: {
        hospitalisation: {
            handler() {
                this.$emit('hospitalisation', {...this.hospitalisation, hospitalisation: this.isHospitalisation });
            },
            deep: true
        }
        
    },
    computed: {
        disabledEndDates(){
            return {
                to: new Date(this.hospitalisation.startDate)
            }
        }
    }
}
</script>