<template>
  <div style="max-height: 400px; overflow-y: auto;">
    <h5 style="margin-bottom: 10px">Liste de ses animaux ({{ clientAnimaux.length }})</h5>
    <animal v-for="(an, index) in clientAnimaux" :key="index" :animal="an" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Animal from './animal.vue';
export default {
    props: {
        uid: {type: String, required: true}
    },
    components: { Animal },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux'
        }),
        clientAnimaux(){
            return this.animaux.filter(a => a.proprio === this.uid || a.accompagnant === this.uid)
        }
    }

}
</script>