<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-12">
        <form
          @submit.prevent="addingProduct"
          class="form-horizontal"
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="type">Type de produit</label>
                <select
                  name="type"
                  id="type"
                  v-model="type"
                  class="form-control"
                  v-select="{placeholder: 'Selectionnez le type de produit'}"
                >
                  <option value="" />
                  <option
                    v-for="(t, index) in types"
                    :value="t.uid"
                    :key="index"
                  >
                    {{ t.libelle }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="categorie">Catégorie de produit</label>
                <select
                  name="categorie"
                  id="categorie"
                  class="form-control"
                  v-model="categorie"
                  v-select="{placeholder: 'Selectionnez le type de produit'}"
                >
                  <option value="" />
                  <option
                    v-for="(c, index) in filtredCategories"
                    :value="c.uid"
                    :key="index"
                  >
                    {{ c.libelle }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="libelle">Libelle</label>
                <input
                  type="text"
                  name="libelle"
                  id="libelle"
                  class="form-control"
                  v-model="libelle"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                  rows="4"
                  type="text"
                  name="description"
                  id="description"
                  class="form-control"
                  v-model="description"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="amount">Prix (XOF)</label>
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  class="form-control"
                  v-model="amount"
                >
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="quantity">Quantité</label>
                <input
                  type="text"
                  name="quantity"
                  id="quantity"
                  class="form-control"
                  v-model="quantity"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="quantity">Photos</label>
              </div>
              <add-product-photo
                v-for="index in 4"
                :index="index"
                :key="index"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="tags">Tags</label>
                <select
                  multiple
                  name="tags"
                  id="tags"
                  class="form-control"
                  v-model="tags"
                  v-select="{placeholder: 'Selectionnez les tags du produit'}"
                >
                  <option value="" />
                  <option
                    v-for="(f, index) in filtredTags"
                    :value="f.uid"
                    :key="index"
                  >
                    {{ f.libelle }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group text-right">
                <button
                  type="submit"
                  class="btn btn-primary"
                >
                  Publier
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Select } from '../../directive/select';
import Navbar from '../../components/navbar.vue';
import AddProductPhoto from '../../components/market/addProductPhoto.vue';
import { ADD_PRODUCT } from '../../graphql/market';
export default {
    components: {Navbar, AddProductPhoto},
    directives: {Select},
    data(){
        return {
            navbarItems: [
              {
                libelle: 'Market Place',
                route: 'market-home'
              },
              {
                libelle: "Produits",
                route: 'market-products'
              },
              {
                libelle: 'Ajout Produit'
              }
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Ajout de produit',
            pageDescription: 'Ajouter un produit sur l\'espace E-Market de CASEAC',
            type: null,
            filtredCategories: [],
            filtredTags:[],
            categorie: null,
            libelle: null,
            tags: [],
            amount: null,
            quantity: null,
            description: null,
        }
    },
    watch: {
        type(){
            this.filtredCategories = [...this.categories].filter(item => item.type === this.type)
            this.filtredTags = [...this.filters].filter(item => item.type === this.type )
        }
    },
    beforeMount(){
        this.initForm()
    },
    methods: {
        ...mapMutations({
            setPhotos: 'market/SET_PRODUCT_PHOTOS'
        }),
        initForm(){
            this.libelle = null
            this.description =  null
            this.price = null
            this.quantity = null
            this.type = null
            this.categorie = null
            this.tags = []
            this.photos = null
            this.setPhotos([])
        },
        addingProduct(){
            let data = {
                libelle: this.libelle,
                description: this.description,
                price: parseFloat(this.amount),
                quantity: parseInt(this.quantity),
                type: this.type,
                categorie: this.categorie,
                tags: JSON.stringify(this.tags),
                photos: JSON.stringify(this.productPhos)
            }
            console.log(`Product: ${this.libelle} amount ${this.amount}`)
            this.$apollo.mutate({
                mutation: ADD_PRODUCT,
                variables: {
                    "product": {
                        ...data
                    }
                },
                update: (data) => {
                    console.log(`Event add successfully on uid ${data.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.$router.push({name: 'market-home'})
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            types: 'market/typeProducts',
            categories: 'market/categories',
            filters: 'market/filtres',
            productPhos: 'market/productPhotos'
        })
    }
}
</script>

<style>

</style>