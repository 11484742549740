<template>
    <div>
      <li class="menu">
        <router-link
          :to="{name: 'rendez_vous'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-calendar" />
            <span>Les Rendez-vous</span>
          </div>
        </router-link>
      </li>
      <li class="menu">
        <router-link
          :to="{name: 'rdv_appointment'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-calendar" />
            <span>Demande de rendez-vous</span>
          </div>
        </router-link>
      </li>
      <li
        class="menu"
        v-if="is_admin"
      >
        <a
          href="#config_rdv"
          data-toggle="collapse"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-cog" />
            <span>Configuration</span>
          </div>
          <div>
            <i class="las la-angle-right sidemenu-right-icon" />
          </div>
        </a>
        <ul
          class="collapse submenu list-unstyled"
          id="config_rdv"
          data-parent="#accordionExample"
        >
          <li>
            <router-link :to="{name: 'admin-time-slot' }">
              Crenaux de RDV
            </router-link>
          </li>
          <li>
          <router-link :to="{name: 'admin-type-event' }">
            Type d'evenement
          </router-link>
        </li>
        </ul>
      </li>
    </div>
  </template>
    
    <script>
    import { mapGetters, mapMutations } from 'vuex';
    import { ADD, DELETE, UPDATE } from '../../../constants/app';
    import { SUBSCRIBE_DELETE_ALL_EVENTS, SUBSCRIBER_APPOINTMENT, SUBSCRIBER_EVENT, SUBSCRIBER_RAPPEL_EVENT, SUBSCRIBER_TIME_SLOT, SUBSCRIBER_TYPE_EVENT } from '../../../graphql/events';
    export default {
        computed: {
            ...mapGetters({
                is_admin: 'auth/is_admin',
            })
        },
        methods: {
          ...mapMutations({
             addTimeSlot: "events/ADD_TIME_SLOT",
             updateTimeSlot: "events/UPDATE_TIME_SLOT",
             deleteTimeSlot: "events/DELETE_TIME_SLOT",
             addAppointment: 'events/ADD_APPOINTMENT',
             updateAppointment: 'events/UPDATE_APPOINTMENT',
             deleteAppointment: 'events/DELETE_APPOINTMENT',
             addEvent: 'events/ADD_EVENT',
             updateEvent: 'events/UPDATE_EVENT',
             deleteEvent: 'events/DELETE_EVENT',
             deleteAllEvents: 'events/DELETE_ALL_EVENTS',
             addTypeEvent: 'events/ADD_TYPE_EVENT',
             updateTypeEvent: 'events/UPDATE_TYPE_EVENT',
             deleteTypeEvent: 'events/DELETE_TYPE_EVENT',
             addRappelEvent: 'events/ADD_RAPPEL',
             deleteRappelEvent: 'events/DELETE_RAPPEL'
          })
        },
        apollo: {
            $subscribe: {
                type_event: {
                  query: SUBSCRIBER_TYPE_EVENT,
                  result({data}){
                      let m = data.type_event
                      if(m.type === ADD) this.addTypeEvent(m.data)
                      if(m.type === DELETE) this.deleteTypeEvent(m.data)
                      if(m.type === UPDATE) this.updateTypeEvent(m.data)
                  }
                },
                event: {
                  query: SUBSCRIBER_EVENT,
                  result({data}){
                      let e = data.event
                      if(e.type === ADD) this.addEvent(e.data)
                      if(e.type === UPDATE) this.updateEvent(e.data)
                      if(e.type === DELETE) this.deleteEvent(e.data)
                  }
                },
                time_slot: {
                    query: SUBSCRIBER_TIME_SLOT ,
                    result({data}){
                        let pa = data.time_slot
                        if(pa.type === ADD) this.addTimeSlot(pa.data)
                        if(pa.type === UPDATE) this.updateTimeSlot(pa.data)
                        if(pa.type === DELETE) this.deleteTimeSlot(pa.data)
                    }
                },
                appointment: {
                    query: SUBSCRIBER_APPOINTMENT ,
                    result({data}){
                        let ap = data.appointment
                        if(ap.type === ADD) this.addAppointment(ap.data)
                        if(ap.type === UPDATE) this.updateAppointment(ap.data)
                        if(ap.type === DELETE) this.deleteAppointment(ap.data)
                    }
                },
                rappel_event: {
                  query: SUBSCRIBER_RAPPEL_EVENT ,
                    result({data}){
                        let ap = data.rappel_event
                        if(ap.type === ADD) this.addRappelEvent(ap.data)
                        if(ap.type === DELETE) this.deleteRappelEvent(ap.data)
                    }
                },
                all_event: {
                  query: SUBSCRIBE_DELETE_ALL_EVENTS,
                  result({data}){
                    let r = data.all_event
                    if(r.type === 'DELETE_ALL') this.deleteAllEvents()
                  }
                }
              
            }
        }
    }
    </script>
    
    <style>
    
    </style>