<template>
  <div class="weekly-bestsellers">
            <div class="t-item">
                <div class="t-company-name">
                    <div class="t-icon">
                        <div class="image-container">
                            <img v-if="hasPrincipalPhoto" class="rounded-circle avatar-xs" :src="principalPhoto" alt="profile">
                        </div>
                    </div>
                    <div class="t-name">
                        <h4>{{ product.libelle }}</h4>
                        <p class="meta-date">{{ type.libelle }} - {{ category.libelle }}</p>
                    </div>
                </div>
                <div class="t-rate rate-inc">
                    <p><span>{{ productItem.quantity }}</span></p>
                </div>
            </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        productItem: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            products: 'market/products',
            types: 'market/typeProducts',
            categories: 'market/categories'
        }),
        product(){
            return this.products.find(item => item.uid === this.productItem.product)
        },

        principalPhoto(){
            const photos = JSON.parse(this.product.photos)
            if(photos.length > 0)
                return photos[0].url
            return null
        },
        hasPrincipalPhoto(){
            return this.principalPhoto !== null 
        },
        type(){
            return this.types.find(item => item.uid === this.product.type)
        },
        category () {
            return this.categories.find(item => item.uid === this.product.categorie)
        }
    }
}
</script>