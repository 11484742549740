<template>
  <div class="weekly-bestsellers">
    <div class="t-item">
      <div
        class="t-company-name justify-center items-center"
        style="width: 100%"
      >
        <div class="t-icon">
          <div class="image-container">
            <img
              v-if="photo !== null"
              class="rounded-circle avatar-md"
              :src="photo"
              alt="profile"
            >
            <i
              v-else
              class="las la-user la-2x"
            />
          </div>
        </div>
        <div class="t-name">
          <h4>{{ fullname }}</h4>
          <p class="meta-date">
            {{ telephone }}
          </p>
        </div>
      </div>
      <apexchart
        style="font-size: 10px;"
        type="pie"
        width="80"
        :options="chartOptions"
        :series="chartAnimaux"
        v-if="isAnimaux"
      />
      <div class="t-rate rate-inc">
        <p><span>{{ userAnimaux.length }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apexchart from 'vue-apexcharts'
export default {
    props: {
      client: {type: Object, required: true},
      filter: {type: String, required: true}
    },
    components: {apexchart},
    data(){
        return {
            chartEspeces: [],
            chartAnimaux: [],
            totalAnimaux: 0
        }
    },
    mounted(){
        this.initChart()
    },
    methods: {
        initChart(){
            this.chartAnimaux = []
            this.chartEspeces = []
            this.especes.forEach((item) => {
                this.chartEspeces.push(item.libelle)
                this.chartAnimaux.push(this.userAnimaux.filter((i) => i.espece === item.uid && i.proprio === this.client.uid).length)
            })
        }
    },  
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux',
            especes: 'identification/especes'
        }),
        userAnimaux(){
            return [...this.animaux].filter(item => item.proprio === this.client.uid)
        },
        hasClient(){ return this.client !== null && this.client !== undefined},
        fullname(){
            if(this.hasClient) return this.client.fullname
            return '-'
        },
        email(){
            if(this.hasClient) return this.client.email
            return '-'
        },
        telephone(){
            if(this.hasClient) return this.client.telephone
            return '-'
        },
        photo(){
            if(this.hasClient && this.client.photo !== null && this.client.photo !== undefined) return JSON.parse(this.client.photo).url
            return null
        },
        isAnimaux(){return this.filter === 'nb_animaux'},
        chartOptions(){
            return {
                chart: {
                    width: 80,
                    type: 'pie',
                },
                labels: this.chartEspeces,
                dataLabels:{
                    enabled: false
                },
                legend: {
                    show: false
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 100
                        },
                        legend: {
                            show: false
                        }
                    }
                }]
            }
        }
    }

}
</script>

<style>

</style>