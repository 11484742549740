<template>
    <div>
      <h4>Type de garde</h4>
        
      <data-table
        :data-source="sortedCabines"
        :headers="headers"
        table-class="table-striped table-hover"
      >
        <template slot="actions">
          <a
            href="javascript:void(0)"
            class="btn btn-outline-primary btn-icon"
            data-toggle="modal"
            data-target="#add-cabine"
          >
            <i class="icofont icofont-plus" />
          </a>
        </template>
      </data-table>
            
      <modal
        title="Ajouter une cabine"
        id="add-cabine"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="adding"
        >
          <div
            class="alert alert-danger"
            v-if="has_error"
          >
            {{ error_msg }}
          </div>
          <div class="form-group">
            <label for="add-libelle">Nom</label>
            <input
              id="add-libelle"
              name="add-libelle"
              type="text"
              class="form-control"
              v-model="libelle"
            >
          </div>

          <div class="form-group">
            <label for="add-type">Type de cabine</label>
            <select v-model="type" v-select="{placeholder: 'Choississez le type de cabine'}" class="form-control" id="add-type" name="add-type">
                <option></option>
                <option v-for="t in types" :key="t.uid" :value="t.uid">{{ t.libelle }}</option>
            </select>
          </div>
          
          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </form>
      </modal>
    
      <modal
        title="Modification d'une cabine"
        id="update-cabine"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="editing"
        >
          <div
            class="alert alert-danger"
            v-if="has_error"
          >
            {{ error_msg }}
          </div>
          <div class="form-group">
            <label for="update-libelle">Nom</label>
            <input
              id="update-libelle"
              name="update-libelle"
              type="text"
              class="form-control"
              v-model="libelle"
            >
          </div>
          <div class="form-group">
            <label for="update-type">Type de cabine</label>
            <select v-model="type" v-select="{placeholder: 'Choississez le type de cabine'}" class="form-control" id="update-type" name="update-type">
                <option></option>
                <option v-for="t in types" :key="t.uid" :value="t.uid">{{ t.libelle }}</option>
            </select>
          </div>
          <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="libre"
                            id="update-libre"
                        >
                        <label
                            class="form-check-label"
                            for="update-libre"
                        >
                            Libre?
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="monitoring"
                            id="update-camera"
                        >
                        <label
                            class="form-check-label"
                            for="update-camera"
                        >
                            Sous vidéo surveillance?
                        </label>
                    </div>
                </div>
            </div>
          </div>
          <div class="form-group" v-if="monitoring">
            <label for="update-url">URL Flux vidéo</label>
            <input class="form-control" name="update-url" id="update-url" v-model="url"/>
          </div>
          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </form>
      </modal>
    </div>
  </template>
    
    <script>
    import {mapGetters, mapMutations} from 'vuex'
    import DataTable from '../../../components/dataTable/local.vue'
    import { BOOLEAN_TYPE, COMPONENT_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType'
    import Modal from '../../../components/modal.vue'
    import { Select } from '../../../directive/select'
    import { ADD_CABINE, UPDATE_CABINE } from '../../../graphql/garderie'
    const Type = () => import('../../../components/admin/garderie/cabineType.vue')
    const Actions = () => import('../../../components/admin/garderie/cabineActions.vue')
    export default {
        components: { DataTable, Modal},
        directives: {Select},
        data(){
            return {
                libelle: null,
                type: null,
                libre: false,
                monitoring: false,
                url: null,
                has_error: false,
                error_msg: null
            }
        },
        watch: {
            selectedType: {
                handler(){
                    if(this.selectedType !== null) {
                        this.libelle = this.selectedType.libelle
                        this.type = this.selectedType.type
                        this.libre = this.selectedType.libre
                        this.monitoring = this.selectedType.monitoring
                        this.url = this.selectedType.url
                    }
                },
                deep: true
            }
        },
        methods: {
            ...mapMutations({
                done: 'DONE',
                setSelectedObject: 'SET_SELECTED_OBJECT'
            }),
            initForm(){
                this.setSelectedObject(null)
                this.libelle = null
                this.type = null
                this.libre = false
                this.monitoring = false
                this.url = null
                this.has_error = false
                this.error_msg = null
            },
            adding(){
                let data = {
                    libelle: this.libelle,
                    type: this.type
                }
                this.$apollo.mutate({
                    mutation: ADD_CABINE,
                    variables: {
                        "cabine": {...data}
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Cabine de garde ${this.libelle} add successfully`)
                    }
                  }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            },
            editing(){
                let data = {
                    libelle: this.libelle,
                    type: this.type,
                    libre: this.libre,
                    monitoring: this.monitoring,
                    url: this.url
                }
                this.$apollo.mutate({
                    mutation: UPDATE_CABINE,
                    variables: {
                        "cabine": {...data},
                        "uid": this.selectedType.uid
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Type de garde ${this.libelle} on ${this.selectedType.uid} updated successfully`)
                    }
                  }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
        },
        computed: {
            ...mapGetters({
                types: 'garderie/types',
                cabines: 'garderie/cabines',
                selectedType: 'selectedObject'
            }),
            sortedCabines(){
                return [...this.cabines].sort((a,b) => a.libelle.localeCompare(b.libelle))
            },
            headers(){
                return [
                    {label: 'ID', name: 'uid', type: TEXT_TYPE},
                    {label: 'Nom', name: 'libelle', type: TEXT_TYPE},
                    {label: 'Type', name: 'type', type: COMPONENT_TYPE, component: Type},
                    {label: 'Libre', name: 'libre', type: BOOLEAN_TYPE},
                    {label: 'Sous Caméra', name: 'monitoring', type: BOOLEAN_TYPE},
                    {label: 'Url', name: 'url', type: TEXT_TYPE},
                    {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
                ]
            }
        }
    }
    </script>
    
    <style>
    
    </style>