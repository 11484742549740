<template>
  <div class="row">
    <div class="col-4">
        <img :src="clientAvatar" width="200" height="200" style="border-radius: 10px;" />
    </div>
    <div class="col-8">
        <h3>{{ clientName }}</h3>
        <p>Email : {{ clientEmail }}</p>
        <p>Téléphone: {{ clientPhone }}</p>
        <p>Adresse: {{ clientAddress }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import md5 from 'md5';
export default {
    props:{
        uid: {type: String, required: true},
    },
    computed: {
        ...mapGetters({
            users: 'auth/users'
        }),
        client(){
            return this.users.find(u => u.uid === this.uid)
        },
        hasClient(){
            return!!this.client
        },
        clientName(){
            return this.hasClient? this.client.fullname : 'Loading...'
        },
        clientEmail(){
            return this.hasClient? this.client.email : 'Loading...'
        },
        clientPhone(){
            return this.hasClient? this.client.telephone : 'Loading...'
        },
        clientAddress(){
            return this.hasClient? this.client.address : 'Loading...'
        },
        clientAvatar(){
            if(this.hasClient){
                const photo = this.client.photo
                if(photo){
                    return JSON.parse(photo).url
                }
                const hash = md5(this.uid.trim().toLowerCase());
                return `https://www.gravatar.com/avatar/${hash}?d=identicon&f=y`;
            }
            const hash = md5('unknown');
            return `https://www.gravatar.com/avatar/${hash}?d=identicon&f=y`;
        }
    }
}
</script>