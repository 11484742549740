
const load  = (component) => {
    return () => import(`../views/${component}.vue`)
}


export default [
    {
        path: '/garde',
        name: 'garde-dashboard',
        component: load('Garderie/Index')
    },

    /** Admin */

    {
        path: '/admin/garderie/type',
        name: 'admin-garderie-type',
        component: load('Admin/Garderie/Type')
    },
    {
        path: '/admin/garderie/cabine',
        name: 'admin-garderie-cabine',
        component: load('Admin/Garderie/Cabine')
    },
    {
        path: '/admin/garderie/activity',
        name: 'admin-garderie-activity',
        component: load('Admin/Garderie/Activity')
    },
    {
        path: '/admin/garderie/pension',
        name: 'admin-garderie-pension',
        component: load('Admin/Garderie/Pension')
    },
    {
        path: '/garderie',
        name: 'garderie',
        component: load('Garderie/Demandes')
    },
    {
        path: '/garderie/demandes',
        name: 'garderie-demandes',
        component: load('Garderie/Demandes')
    },
    {
        path: '/garderie/demande/:uid',
        name: 'garderie-demandes-detail',
        component: load('Garderie/DetailDemande')
    },
    {
        path: '/garderie/gardes',
        name: 'garde',
        component: load('Garderie/Garde')
    },
    {
        path: '/garderie/garde/:uid',
        name: 'garderie-garde-detail',
        component: load('Garderie/GardeDetail')
    },
    {
        path: '/garderie/gardes/add',
        name: 'garde-add',
        component: load('Garderie/AddGarde')
    },
    {
        path: '/garderie/garde/:uid',
        name: 'garde-detail',
        component: load('Garderie/GardeDetail')
    },
]