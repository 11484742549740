<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="col-12">
      <form
        class="form-horizontal"
        @submit.prevent="addingAnimal"
      >
        <div 
          class="alert alert-danger" 
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <fieldset class="card-box" style="padding: 30px;"> 
          <legend style="padding-top: 50px;">Information sur l'animal</legend>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label
                  for="espece"
                  class="required"
                > Espèce</label>
                <select
                  class="form-control"
                  name="espece"
                  id="espece"
                  v-select="{placeholder: 'Selectionnez l\'espèce'}"
                  v-model="espece"
                  required
                >
                  <option value="" />
                  <option
                    v-for="(e, index) in especes"
                    :value="e.uid"
                    :key="index"
                  >
                    {{ e.libelle }} ({{ e.code|capitalize }})
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label
                  for="nom"
                  class="required"
                >Nom</label>
                <input
                  type="text"
                  class="form-control"
                  name="nom"
                  id="nom"
                  v-model="name"
                  required
                >
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label
                  for="code"
                  class="required"
                >Code ISO</label>
                <input
                  type="text"
                  class="form-control"
                  name="code"
                  id="code"
                  v-model="code"
                  required
                >
              </div>
            </div>
            
            <div class="col-md-5">
              <div class="form-group">
                <label
                  for="dateNaissance"
                  class="required"
                >Date de naissance</label>
                <datepicker
                  id="dateNaissance"
                  name="dateNaissance"
                  input-class="form-control required"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  v-model="dateNaissance"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label
                  for="race"
                  class="required"
                >Race</label>
                <select
                  class="form-control"
                  name="race"
                  id="race"
                  v-select="{placeholder: 'Selectionnez la race'}"
                  v-model="race"
                  required
                >
                  <option value="" />
                  <option
                    v-for="(r, index) in filtredRaces"
                    :value="r.uid"
                    :key="index"
                  >
                    {{ r.libelle }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label
                  for="robe"
                  class="required"
                >Robe</label>
                <select
                  name="robe"
                  id="robe"
                  class="form-control"
                  v-select="{placeholder: 'Selectionnez la robe'}"
                  v-model="robe"
                  required
                >
                  <option value="" />
                  <option
                    v-for="(rb, index) in filtredRobes"
                    :value="rb.uid"
                    :key="index"
                  >
                    {{ rb.libelle }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label
                  for="sexe"
                  class="required"
                >Sexe</label>
                <select
                  name="sexe"
                  id="sexe"
                  class="form-control"
                  v-select="{placeholder: 'Selectionnez le sexe'}"
                  v-model="sexe"
                  required
                >
                  <option value="" />
                  <option
                    v-for="(s, index) in sexes"
                    :value="s.value"
                    :key="index"
                  >
                    {{ s.libelle }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="signe">Signes particuliers</label>
                <textarea
                  rows="3"
                  class="form-control"
                  name="signe"
                  id="signe"
                  v-model="signe"
                />
              </div>
            </div>
          </div>
        </fieldset>
        <div class="row">
          <div class="col-6">
            <fieldset class="card-box" style="padding: 20px;">
              <legend style="padding-top: 50px; font-size: 20px;">
                Information sur le Proprietaire
              </legend>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="nom" class="required">Nom complet</label>
                    <input
                      type="text"
                      class="form-control"
                      name="fullname"
                      id="fullname"
                      required
                      v-model="fullname"
                    >
                  </div>
                </div>
              </div>

              <div class="row">

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="telephone" class="required">Téléphone</label>
                    <input
                      type="telephone"
                      class="form-control"
                      name="telephone"
                      id="telephone"
                      required
                      v-model="telephone"
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="card-box" style="padding: 20px;">
              <legend style="padding-top: 50px; font-size: 20px;">
                Information sur l'accompagnant
              </legend>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="fullname_accompagnant">Nom complet</label>
                    <input
                      type="text"
                      class="form-control"
                      name="fullname_accompagnant"
                      id="fullname_accompagnant"
                      v-model="fullname_accompagnant"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="phone_accompagnant">Telephone</label>
                    <input
                      type="telephone"
                      class="form-control"
                      name="phone_accompagnant"
                      id="phone_accompagnant"
                      v-model="phone_accompagnant"
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12 text-right">
            <button
              class="btn btn-primary"
              type="submit"
              :class="loading? 'disable': ''"
            >
              <span
                class="spinner-border m-1"
                role="status"
                v-if="loading"
              /> 
              <span v-else>Ajouter</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { Select } from '../../../directive/select'
import { ADD_ANIMAL } from '../../../graphql/identification'
import navbar from '../../../components/navbar.vue'
export default {
    directives: {Select},
    components: {navbar, Datepicker},
    data(){
        return {
            navbarItems: [
              {
                libelle: 'Identification'
              },
              {
                libelle: 'Animaux',
                route: 'animaux'
              },
              {
                libelle: 'Ajout d\'animal'
              }
            ],
            pageIcon: 'la-dog',
            pageTitle: 'Ajouter un animal',
            pageDescription: 'Identification de l\'animal permet de le suivre sur CASEAC',
        
            code: null,
            name: null,
            espece: null,
            robe: null,
            race: null,
            sexe: null,
            sexes: [{value: 'f', libelle: 'Femelle'}, {value: 'm', libelle: 'Mâle'}],
            dateNaissance: null,
            fullname: null,
            telephone: null,
            signe: null,

            fullname_accompagnant: null,
            phone_accompagnant: null,

            has_error: false,
            error_msg: null
        }
    },
    methods: {
      ...mapMutations({
        setLoading: 'SET_LOADING'
      }),
        initForm(){
            this.code =  null
            this.name =  null
            this.espece =  null
            this.robe =  null
            this.race = null
            this.sexe =  null
            this.dateNaissance =  null
            this.fullname =  null
            this.telephone = null
            this.signe = null

            this.fullname_accompagnant = null,
            this.phone_accompagnant = null,
            this.has_error = false
            this.error_msg = null
            this.setLoading(false)
        },
        addingAnimal(){
          this.setLoading(true)
          let data = {
            codeIso: this.code,
            name: this.name,
            espece: this.espece,
            sexe: this.sexe,
            race: this.race,
            robe: this.robe,
            dateNaissance: this.dateNaissance,
            fullname_proprio: this.fullname,
            telephone_proprio: this.telephone,

            fullname_accompagnant: this.fullname_accompagnant,
            phone_accompagnant: this.phone_accompagnant,
            
            signe: this.signe
          }
          this.$apollo.mutate({
              mutation: ADD_ANIMAL,
              variables: {
                "animal": {
                  ...data
                }
              },
              update: () => {
                this.initForm()
                this.$router.push({name: 'animaux'})
              }
          }).catch((error) => {
            this.setLoading(false)
            this.has_error = true
            this.error_msg = error
          })
        }
    },
    computed: {
        ...mapGetters({
            especes: 'identification/activeEspeces',
            races: 'identification/races',
            typeRobes: 'identification/typeRobes',
            robes: 'identification/robes',
            done: 'done',
            loading: 'loading'
        }),
        filtredRaces(){
            if(this.espece !== null){
              return [...this.races].filter(item => item.espece === this.espece)
            }
            return []
        },
        filtredRobes(){
            if(this.espece !== null){
              return [...this.robes].filter(item => item.espece === this.espece)
            }
            return []
        }
    }
}
</script>

<style>

</style>