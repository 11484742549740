<template>
<div class="chat-details">
    <!-- <div class="search mb-3">
        <i class="las la-search toggle-search" />
        <input
        type="text"
        class="form-control"
        placeholder="Rechercher un message"
        v-model="messageSearchQuery"
        >
    </div> -->
    <div class="chat-details-header">
        <div class="chat-with">
            <p>Discuter avec</p>
            <h3>{{ user.fullname }}</h3>
        </div>
        <div class="chat-details-options">
            <loadingSpiner v-if="isLoadingSessions"/>
            <div v-else>
                <chat-active-session v-for="(s, index) in openSessions" :key="index" :session="s" />
            </div>
            <audio
                ref="audio"
                src="@/assets/audio/notification1.mp3"
            />
        </div>
        <div class="chat-with-options">
            <!-- <span class="start-video-call" title="Video Call"><i class="las la-video" /></span> -->
            <!-- <span class="start-call" title="Call"><i class="las la-phone" /></span> -->
            <div class="dropdown chat-with-op mt-1">
                <a class="dropdown-toggle" href="#" role="button" id="customDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="las la-ellipsis-v" />
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="customDropdown" style="will-change: transform;" >
                    <a class="dropdown-item" href="javascript:void(0);">  Mute </a>
                    <a 
                        class="dropdown-item" 
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#chatCreateDiscusion"
                    >  
                        Créer une discussion 
                    </a>
                    <!-- <a class="dropdown-item" href="javascript:void(0);">Report</a> -->
                    <!-- <a class="dropdown-item" href="javascript:void(0);">Export Chat</a> -->
                    <a 
                        class="dropdown-item" 
                        href="javascript:void(0);" 
                        @click.prevent="closeCurrentSession" 
                        v-show="currentSession"
                    > 
                        Close discussion 
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="chatting-container">
        <loadingSpiner v-if="isLoadingMessages"/>
        <div v-for="(s, index) in groupeMessages" :key="index">
            <div class="chatting-time">
                <p>
                    <span>{{ s.date|date }}</span>
                </p>
            </div>
            <chat-message  v-for="m in s.messages" :key="m.uid" :message="m"/>
        </div>
        <div style="position: relative; bottom: 5px; left: 10px; padding: 10px;" v-if="showTyping">
            <p class="text-muted font-11"> {{ typingMessage }} </p>
        </div>
    </div>
    <div class="chat-input-container" v-show="currentSessionIsOpen">
        <div class="cic pointer">
            <i class="las la-grin-beam" />
            <input
                class="chat-text-input"
                placeholder="Ecrivez votre message ici"
                v-model="message"
                @input="typing" 
                @blur="stopTyping"
                @keyup.enter="sendMessage"
            >
        </div>
        <label
            for="file-input"
            class="mb-0"
        >
            <a
                class="send"
                title="Attach a file"
            >
                <i class="las la-paperclip" />
            </a>
        </label>
        <!-- <input
            id="file-input"
            type="file"
            style="display: none;"
        > -->
        <a
            class="send chat-send"
            title="Send"
            @click.prevent="sendMessage"
        >
            <i class="las la-paper-plane" />
        </a>
    </div>
    <modal
        id="chatAllClosedDiscussion"
        :title="'Toutes les discussions fermées'"
        :footer="false"
    >
        <div class="widget-content widget-content-area">
            <ul class="list-group">
                <closed-session-list-item v-for="s in closedSessions" :key="s.uid" :session="s" />
            </ul>
        </div>
    </modal>
    <modal
        id="chatCreateDiscusion"
        :title="'Sujet de discussion'"
        :footer="false"
    >
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="subject">Sujet de discussion</label>
                    <select 
                        class="form-control"
                        id="subject"
                        name="subject"
                        v-select="{placeholder: 'Selectionnez le sujet de discussion'}" 
                        v-model="selectedSubject"
                    >
                        <option></option>
                        <option v-for="s in subjects" :key="s.uid" :value="s.uid">{{ s.libelle }}</option>
                    </select>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-12 ">
                <div class="form-group text-right">
                    <a
                        class="btn btn-primary btn-sm"
                        href="javascript:void(0)"
                        @click.prevent="startDiscussionWithClient"
                    >
                        Demarrer la discussion
                    </a>
                </div>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { CLOSE_CURRENT_SESSION, GET_CLIENT_MESSAGES, GET_CLIENT_SESSIONS, START_CHAT_SESSION } from '../../graphql/chat';
import moment from 'moment'
import { Select } from '../../directive/select';
import ChatMessage from './chatMessage.vue'
import ChatActiveSession from './chatActiveSession.vue';
import socket from '../../plugins/socket'
import LoadingSpiner from '../loadingSpiner.vue';
import Modal from '../modal.vue';
import ClosedSessionListItem from './closedSessions/oneClosedSessionListItem.vue'
export default {
    components: {ChatMessage, ChatActiveSession, LoadingSpiner, Modal, ClosedSessionListItem},
    directives: { Select },
    data(){
        return {
            loading: false,
            loadMessage: true,
            loadSession: true,
            isLoadingSessions: false, 
            isLoadingMessages: false,
            messages: [], 
            message: null,
            messageSearchQuery: '',
            selectedSubject: null,
            showTyping: false,
            typingMessage: null
        }
    },
    mounted(){
        this.loadSession = false
        this.scrollToBottom()
        socket.on('receive_message', (payload) => {
            if(this.currentSession === payload.session){
                this.messages.push(payload)
                this.scrollToBottom()
            }
            if(payload.sender !== this.me.uid) this.playSound();
        });
        socket.on('typing', (payload) => {
            if(payload.senderUID !== this.me.uid){
                this.showTyping = true
                const user = this.users.find(item => item.uid === payload.senderUID)
                if(user) this.typingMessage = `${user.fullname} est en train d'écrire...`
                else this.typingMessage = `${payload.senderUID} est en train d'écrire...`
            }
        })
        socket.on('stop_typing', (payload) => {
            if(payload.senderUID !== this.me.uid){
                this.showTyping = false
                this.typingMessage = ''
            }
        })
    },
    watch: {
        activeUser(){
            this.setSessions([])
            this.messages = []
            this.setCurrentSession(null)
            if(this.activeUser !== null && this.activeUser !== undefined){
                this.loadSession = false
            }
        },
        currentSession(){
            this.messages = []
            if(this.currentSession !== null && this.currentSession !== undefined){
                this.loadMessage = false
                const session = this.sessions.find(item => item.uid === this.currentSession)
                if(session){
                    socket.emit('join_room', {sessionUID: this.currentSession, userUID: this.me.uid });
                    this.scrollToBottom()
                }
            }
        },
        messages: {
            handler () {
                this.scrollToBottom()
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            setSessions: 'chat/SET_SESSIONS',
            setCurrentSession: 'chat/SET_CURRENT_SESSION',
            done: 'DONE'
        }),
        scrollToBottom(){
            const getScrollContainer = document.querySelector('.chatting-container'); //Scroll bottom when chat initiate
            getScrollContainer.scrollTop = getScrollContainer.scrollHeight;
        },
        typing() { 
            socket.emit('typing', { senderUID: this.me.uid, sessionUID: this.currentSession }); 
        }, 
        stopTyping() { 
            socket.emit('stop_typing', { senderUID: this.me.uid, sessionUID: this.currentSession }); 
        },
        sendMessage(){
            if( !this.message || this.message.trim() === ''){
                return
            }
            socket.emit("send_message", { sessionUID: this.currentSession, senderUID: this.me.uid, message: this.message.trim() }); 
            this.messages.push()
            this.message = ''
        },
        startDiscussionWithClient(){
            console.log(`Start Discussion with ${this.activeUser} on subject ${this.selectedSubject}`)
            this.$apollo.mutate({
                mutation: START_CHAT_SESSION,
                variables: {
                    "client": this.activeUser,
                    "subject": this.selectedSubject
                },
                update: (cache, { data: { startChatSession } }) => {
                    console.log(startChatSession);
                    console.log(`Session with  uid : ${startChatSession} start successfully`);
                    this.setCurrentSession(startChatSession)
                    this.done()
                }
            })
        },
        closeCurrentSession(){
            console.log(this.currentSession)
            this.$apollo.mutate({
                mutation: CLOSE_CURRENT_SESSION,
                variables: {
                    "uid": this.currentSession
                },
                update: (cache, { data: { closeChatSession } }) => {
                    console.log(closeChatSession);
                    console.log(`Session with  uid : ${this.currentSession} closed with response ${closeChatSession}`);
                    this.setCurrentSession(null)
                }
            })
        },
        playSound() { this.$refs.audio.play(); }
    },
    apollo: {
        loadSessions: {
            query: GET_CLIENT_SESSIONS,
            skip () {
                return this.loadSession
            },
            variables(){
                return {
                    "client": this.user.uid,
                }
            },
            loadingKey: 'isLoadingSessions',
            error (error) {
                this.error_msg = JSON.stringify(error.message)
                this.loadSession = true
            },
            update ({clientSessions}) {
                this.setSessions(clientSessions)
                this.loadSession = true
            },
            fetchPolicy: 'network-only'
        },
        loadMessages: {
            query: GET_CLIENT_MESSAGES,
            skip () {
                return this.loadMessage
            },
            variables(){
                return {
                    "session": this.currentSession,
                }
            },
            loadingKey: 'isLoadingMessages',
            error (error) {
                this.error_msg = JSON.stringify(error.message)
                this.loadMessage = true
            },
            update ({chatMessages}) {
                this.messages = chatMessages.sort((a,b) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
                this.loadMessage = true
            },
            fetchPolicy: 'network-only'
        }
    },
    computed: {
       ...mapGetters({
            users: 'auth/users',
            activeUser: 'chat/activeUser',
            me: 'auth/me',
            currentSession: 'chat/currentSession',
            sessions: 'chat/sessions',
            subjects: 'chat/subjects'
       }),
       user(){
        return this.users.find(user => user.uid === this.activeUser);
       },
       hasUser(){
        return this.user !== null && this.user !== undefined;
       },
       openSessions(){
        return [...this.sessions].filter(item => item.status === "Active")
       },
       closedSessions(){
        return [...this.sessions].filter(item => item.status === "Closed")
       },
       currentSessionIsOpen(){
            if(this.currentSession){
                const session = this.sessions.find(item => item.uid ===  this.currentSession)
                if(session) return session.status === 'Active'
                return false
            }
            return false
       },
       groupeMessages(){
        let result = []
        for(const message of this.messages) { 
            const dateKey = message.createdAt.split('T')[0]
            // Extraire la date sans l'heure 
            if (!result[dateKey]) { 
                result[dateKey] = []
            } 
            result[dateKey].push(message)
        }
        const groupedMessagesArray =  Object.keys(result).map(date => ({ 
            date, 
            messages: result[date] 
        })); 
        return groupedMessagesArray
       }
    },

}
</script>