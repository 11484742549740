<template>
<div class="col-xl-5 col-lg-12 col-md-12">
    <div class="profile-info">
        <h5>Information géneral</h5>
        <div class="single-profile-info">
        <h6>Adresse</h6>
        <p>{{ address }}.</p>
        </div>
        <div class="single-profile-info">
        <h6>Email</h6>
        <p><a :href="'mailto:'+email">{{ email }}</a></p>
        </div>
        <div class="single-profile-info">
        <h6>Téléphone</h6>
        <p>{{ telephone }}</p>
        </div>
        <div class="single-profile-info">
        <h6>Dernière connexion</h6>
        <p>{{ lastConnexion|systemDateTime }}</p>
        </div>
                
        <!-- <div class="single-profile-info">
                    <h6>Skills</h6>
                    <span class="badge badge-primary"> UI/UX </span>
                    <span class="badge badge-info"> Photoshop </span>
                    <span class="badge badge-secondary"> Angular </span>
                    <span class="badge outline-badge-danger"> Java </span>
                </div> -->
        <div class="single-profile-info social">
        <h6>Social</h6>
        <a
            href="https://www.facebook.com/"
            target="_blank"
        ><i class="lab la-facebook text-primary" /></a>
        <a
            href="https://www.twitter.com/"
            target="_blank"
        ><i class="lab la-twitter text-info" /></a>
        <a
            href="https://www.linkedin.com/"
            target="_blank"
        ><i class="lab la-linkedin text-primary" /></a>
        <a
            href="https://www.instagram.com/"
            target="_blank"
        ><i class="lab la-instagram text-danger" /></a>
        <a
            href="https://www.github.com/"
            target="_blank"
        ><i class="lab la-github text-black" /></a>
        </div>
    </div>
</div>
</template>

<script> 
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            me: 'auth/me'
        }),
        hasUser(){
            return this.me !== null
        },
        email(){
          if(this.hasUser) return this.me.email
          return '-'
        },
        telephone(){
          if(this.hasUser) return this.me.telephone
          return '-'
        },
        lastConnexion(){
          if(this.hasUser) return this.me.lastConnexion
          return null
        },
        address(){
          if(this.hasUser) return this.me.address
          return '-'
        }
    }

}
</script>