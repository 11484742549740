<template>
  <div>
    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#config_user"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-user" />
          <span>Utilisateur</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_user"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-user-type-compte' }">
            Type Compte
          </router-link>
        </li>
      </ul>
    </li>

    <li
      class="menu"
      v-if="is_admin"
    >
      <router-link
        :to="{name: 'admin-sms'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-sms" />
          <span>SMS</span>
        </div>
      </router-link>
    </li>
    <li class="menu">
      <a
        href="#config_id"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-passport" />
          <span>Identification</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_id"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-espece' }">
            Espèces
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-race' }">
            Race
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-robe' }">
            Robe
          </router-link>
        </li>
        
        
        <li>
          <router-link :to="{name: 'admin-alimentation'}">
            Aliment
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-action-postmortem'}">
            Action Post Mortem
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-motif-deces'}">
            Motif de décès
          </router-link>
        </li>
      </ul>
    </li>
    <li class="menu">
      <a
        href="#config_sanitaire"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-h-square" />
          <span>Sanitaire</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_sanitaire"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-motif-consultation'}">
            Motif Consultation
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-mode-vie' }">
            Mode de vie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-parasite'}">
            Type de parasite
          </router-link>
        </li>
                
        <li>
          <router-link :to="{name: 'admin-anti-parasitaire'}">
            Anti-Parasitaire
          </router-link>
        </li>

        <li>
          <router-link :to="{name: 'admin-vaccin'}">
            Vaccin
          </router-link>
        </li>

        <li>
          <router-link :to="{name: 'admin-famille-medicament'}">
            Famille Medicament
          </router-link>
        </li>

        <li>
          <router-link :to="{name: 'admin-medicament'}">
            Medicament
          </router-link>
        </li>
                
        <li>
          <router-link :to="{name: 'admin-type-maladie'}">
            Type de maladie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-maladie'}">
            Maladie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-analyse'}">
            Type d'analyse
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-categorie-analyse'}">
            Categorie d'analyse
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-recherche-analyse'}">
            Type de recherche
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-examen-analyse'}">
            Examen
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-pronostic'}">
            Type de pronostic
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-chirurgie'}">
            Type de chirurgie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-toilettage-activity'}">
            Activité de toilettage
          </router-link>
        </li>
      </ul>
    </li>
    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#config_questionnaire"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-h-square" />
          <span>Q/R Anamnese</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_questionnaire"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-questionnaire-section'}">
            Section
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-questionnaire'}">
            Questionnaire
          </router-link>
        </li>
      </ul>
    </li>

    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#fiche_analyse"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-h-square" />
          <span>Fiche Analyse</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="fiche_analyse"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-type-fiche-analyse'}">
            Type Fiche Analyse
          </router-link>
        </li>
      
        <li>
          <router-link :to="{name: 'admin-fiche-analyse'}">
            Fiche Analyse
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-section-fiche-analyse'}">
            Section 
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-parametre-fiche-analyse'}">
            Parametre
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-reference-fiche-analyse'}">
            Reférence
          </router-link>
        </li>
      </ul>
    </li>
  </div>
</template>

<script>
import {mapGetters, mapMutations } from 'vuex'
export default {

    data(){
        return {

        }
    },
    
    methods: {
      ...mapMutations({
        
      })
       
    },
    computed: {
        ...mapGetters({
            is_admin: 'auth/is_admin',
            is_super_admin: 'auth/is_super_admin'
        })
    }
}
</script>

<style>

</style>