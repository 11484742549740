const state = {
    types: [],
    typeCabines: [],
    cabines: [],
    demandes: [],
    activities: [],
    gardes: [],
    pensions: []
}

const getters = {
    types: state => state.types,
    typeCabines: state => state.typeCabines,
    cabines: state => state.cabines,
    demandes: state => state.demandes,
    activities: state => state.activities,
    gardes: state => state.gardes,
    pensions: state => state.pensions
}

const mutations = {
    SET_TYPES: (state, list) => {state.types = list},
    ADD_TYPE: (state, type) => {state.types.push(type)},
    UPDATE_TYPE: (state, type) => {state.types = state.types.filter(item => item.uid !== type.uid); state.types.push(type)},
    DELETE_TYPE: (state, type) => {state.types = state.types.filter(item => item.uid !== type.uid)},

    SET_TYPE_CABINES: (state, list) => {state.typeCabines = list},
    ADD_TYPE_CABINE: (state, type) => {state.typeCabines.push(type)},
    UPDATE_TYPE_CABINE: (state, type) => {state.typeCabines = state.typeCabines.filter(item => item.uid !== type.uid); state.typeCabines.push(type)},
    DELET_TYPE_CABINE: (state, type) => {state.typeCabines =  state.typeCabines.filter(item => item.uid !== type.uid)},

    SET_CABINES: (state, list) => {state.cabines = list},
    ADD_CABINE: (state, cabine) => {state.cabines.push(cabine)},
    UPDATE_CABINE: (state, cabine) => {state.cabines = state.cabines.filter(item => item.uid !== cabine.uid); state.cabines.push(cabine)},
    DELETE_CABINE: (state, cabine) => {state.cabines = state.cabines.filter(item => item.uid !== cabine.uid)},


    SET_DEMANDES: (state, list) => {state.demandes = list},
    ADD_DEMANDE: (state, d) => {state.demandes.push(d)},
    UPDATE_DEMANDE: (state, d) => {state.demandes = state.demandes.filter(item => item.uid !== d.uid); state.demandes.push(d)},
    DELETE_DEMANDE: (state, d) => {state.demandes = state.demandes.filter(item => item.uid !== d.uid)},

    SET_ACTIVITIES: (state, list) => {state.activities = list},
    ADD_ACTIVITY: (state, act) => {state.activities.push(act)},
    UPDATE_ACTIVITY: (state, act) => {state.activities = state.activities.filter(item => item.uid !== act.uid); state.activities.push(act)},
    DELETE_ACTIVITY: (state, act) => {state.activities = state.activities.filter(item => item.uid !== act.uid)},

    SET_GARDES: (state, list) => {state.gardes = list},
    ADD_GARDE: (state, garde) => {state.gardes.push(garde)},
    UPDATE_GARDE: (state, garde) => {state.gardes = state.gardes.filter(item => item.uid !== garde.uid); state.gardes.push(garde)},
    DELETE_GARDE: (state, garde) => {state.gardes = state.gardes.filter(item => item.uid !== garde.uid)},

    SET_PENSIONS: (state, list) => {state.pensions = list},
    ADD_PENSION: (state, pension) => {state.pensions.push(pension)},
    UPDATE_PENSION: (state, pension) => {state.pensions = state.pensions.filter(item => item.uid !== pension.uid); state.pensions.push(pension)},
    DELETE_PENSION: (state, pension) => {state.pensions = state.pensions.filter(item => item.uid !== pension.uid)}
 }



 
export default {
    namespaced: true,
    state,
    getters,
    mutations
}