<template>
  <div>
    <div v-for="(an, index) in demandAnalyses" :key="an" class="row" style="margin-bottom: 20px;">
      <div class="col-1">{{ index+1 }}</div>
      <div class="col-11">{{ getAnalyse(an) }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    methods: {
      getAnalyse(uid){
        const analyse = this.analyses.find(item => item.uid === uid)
        if(analyse !== null && analyse !== undefined){
          const type = this.types.find(item => item.uid === analyse.type)
          const categorie = this.categories.find(item => item.uid === analyse.categorie)
          const typeRecherche = this.typeRecherches.find(item => item.uid === analyse.typeRecherche)
          const examen = this.examens.find(item => item.uid === analyse.examen)
          return `${type.libelle} . ${categorie.libelle}  ${typeRecherche ? ' . ' + typeRecherche.libelle : ''} ${examen? ' . '+ examen.libelle: ''}`
        }
        return '-'
      }
    },
    computed:{
        ...mapGetters({
          // Define your getters in this object
          analyses: 'sanitaire/analyses',
          types: 'sanitaire/typeAnalyses',
          categories: 'sanitaire/categorieAnalyses',
          typeRecherches: 'sanitaire/typeRecherches',
          examens: 'sanitaire/examens'

        }),
        demandAnalyses(){
          return JSON.parse(this.object.analyses)
        }
    }
}
</script>

<style>

</style>