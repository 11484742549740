<template>
  <a class="widget quick-category">
    <div class="quick-category-head">
        <span class="quick-category-icon qc-success-teal rounded-circle">
            <i class="las la-box"></i>
        </span>
        <div class="ml-auto">
            <!-- <div class="quick-comparison qcompare-danger">
                <span>50%</span>
                <i class="las la-arrow-down"></i>
            </div> -->
        </div>
    </div>
    <div class="quick-category-content">
        <h3>{{ actifs }}/{{ abonnements.length }}</h3>
        <p class="font-17 text-success-teal mb-0">Abonnements</p>
    </div>
</a>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            abonnements: 'packs/abonnements',
        }),
        actifs(){
            return [...this.abonnements].filter(item => item.actif).length
        }
    }
}
</script>