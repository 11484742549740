<template>
    <div>
      
      <li class="menu">
        <router-link
          :to="{name: 'garde-dashboard'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="icofont icofont-dashboard"/>
            <span>Garderie Board</span>
          </div>
        </router-link>
      </li>
      <li class="menu">
        <router-link
          :to="{name: 'garderie-demandes'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-store" />
            <span>Demande de garde</span>
          </div>
        </router-link>
      </li>
      <li class="menu">
        <router-link
          :to="{name: 'garde'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-shopping-cart" />
            <span>Gardes</span>
          </div>
        </router-link>
      </li>
      
      <li
        class="menu"
        v-if="is_admin"
      >
        <a
          href="#config_garde"
          data-toggle="collapse"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-cog" />
            <span>Configuration</span>
          </div>
          <div>
            <i class="las la-angle-right sidemenu-right-icon" />
          </div>
        </a>
        <ul
          class="collapse submenu list-unstyled"
          id="config_garde"
          data-parent="#accordionExample"
        >
          <li>
            <router-link :to="{name: 'admin-garderie-type' }">
              Type de garde
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'admin-garderie-cabine' }">
              Cabine
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'admin-garderie-activity' }">
              Activités
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'admin-garderie-pension' }">
              Pension complète
            </router-link>
          </li>
          
        </ul>
      </li>
    </div>
  </template>
  
  <script>
  import { ADD, DELETE, UPDATE } from '../../../constants/app'
import { SUBSCRIBER_CABINE, SUBSCRIBER_DEMANDE, SUBSCRIBER_GARDE, SUBSCRIBER_GARDE_ACTIVITY, SUBSCRIBER_GARDE_PENSION, SUBSCRIBER_TYPE_GARDE } from '../../../graphql/garderie';
  import {mapGetters, mapMutations} from 'vuex'
  export default {
      data(){
          return {
  
          }
      },
      methods: {
          ...mapMutations({
              addType: 'garderie/ADD_TYPE',
              updateType: 'garderie/UPDATE_TYPE',
              deleteType: 'garderie/DELETE_TYPE',
  
              addCabine: 'garderie/ADD_CABINE',
              updateCabine: 'garderie/UPDATE_CABINE',
              deleteCabine: 'garderie/DELETE_CABINE',

              addDemande: 'garderie/ADD_DEMANDE',
              updateDemande: 'garderie/UPDATE_DEMANDE',
              deleteDemande: 'garderie/DELETE_DEMANDE',

              addActivity: 'garderie/ADD_ACTIVITY',
              updateActivity: 'garderie/UPDATE_ACTIVITY',
              deleteActivity: 'garderie/DELETE_ACTIVITY',

              addPension: 'garderie/ADD_PENSION',
              updatePension: 'garderie/UPDATE_PENSION',
              deletePension: 'garderie/DELETE_PENSION',

              addGarde: 'garderie/ADD_GARDE',
              updateGarde: 'garderie/UPDATE_GARDE',
              deleteGarde: 'garderie/DELETE_GARDE',
          })
      },  
  
      apollo: {
          $subscribe: {
              garde_cabine: {
                  query: SUBSCRIBER_CABINE ,
                  result({data}){
                      let c = data.garde_cabine
                      if(c.type === ADD) this.addCabine(c.data)
                      if(c.type === DELETE) this.deleteCabine(c.data)
                      if(c.type === UPDATE) this.updateCabine(c.data)
                  }
              },
              garde_type: {
                  query: SUBSCRIBER_TYPE_GARDE ,
                  result({data}){
                      let t = data.garde_type
                      if(t.type === ADD) this.addType(t.data)
                      if(t.type === DELETE) this.deleteType(t.data)
                      if(t.type === UPDATE) this.updateType(t.data)
                  }
              },
              garde_demande: {
                  query: SUBSCRIBER_DEMANDE,
                  result({data}){
                      let d = data.garde_demande
                      if(d.type === ADD) this.addDemande(d.data)
                      if(d.type === DELETE) this.deleteDemande(d.data)
                      if(d.type === UPDATE) this.updateDemande(d.data)
                  }
              },
              garde_activity: {
                  query: SUBSCRIBER_GARDE_ACTIVITY,
                  result({data}){
                      let d = data.garde_activity
                      if(d.type === ADD) this.addActivity(d.data)
                      if(d.type === DELETE) this.deleteActivity(d.data)
                      if(d.type === UPDATE) this.updateActivity(d.data)
                  }
              },
              garde: {
                query: SUBSCRIBER_GARDE,
                  result({data}){
                      let g = data.garde
                      if(g.type === ADD) this.addGarde(g.data)
                      if(g.type === DELETE) this.deleteGarde(g.data)
                      if(g.type === UPDATE) this.updateGarde(g.data)
                  }
              },
              garde_pension: {
                query: SUBSCRIBER_GARDE_PENSION,
                  result({data}){
                      let p = data.garde_pension
                      if(p.type === ADD) this.addPension(p.data)
                      if(p.type === DELETE) this.deletePension(p.data)
                      if(p.type === UPDATE) this.updatePension(p.data)
                  }
              }
              
          }
      },
      computed: {
          ...mapGetters({
              is_admin: 'auth/is_admin',
              is_super_admin: 'auth/is_super_admin',
              is_veterinaire: 'auth/is_veterinaire',
              is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
              is_assistant_veto_major: 'auth/is_assistant_veto_major',
          })
      }
  }
  </script>
  
  <style>
  
  </style>