<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
  </div>
</template>

<script>
import Navbar from '../../components/navbar.vue';

export default {
    components: {Navbar},
    data(){
        return {
            navbarItems: [
              {
                libelle: 'Market Place',
                route: 'market-dashboard'
              }, {
                libelle: 'Facturation',
                route: 'market-billing'
              },
              {libelle: 'Modification'}
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Facturation',
            pageDescription: 'Modification de la facture sur CASEAC',
            
        }
    }
}
</script>

<style>

</style>