<template>
    <div>
        <div :class="session.client === message.sender? 'other-chat-container slideInLeft' : 'own-chat-container slideInRight'" >
            <div :class="session.client === message.sender? 'other-chat': 'own-chat'">
                <p>{{ message.content }}</p>
                <!-- <p> Session Agent: {{ session.agent }}</p>
                <p> Session Client: {{ session.client }}</p>
                <p> Sender: {{ message.sender }}</p> -->
            </div>
            <span :class="session.client === message.sender? 'other-time': 'own-time'">{{ message.createdAt|time }}</span> 
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
// import moment from 'moment'
export default {
    props: {
        message: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            sessions: 'chat/sessions',
            currentSession: 'chat/currentSession'
        }),
        session(){
            return this.sessions.find(session => session.uid === this.currentSession)
        }
       

    }
}
</script>