<template>
    <div style="margin-top: 50px;">
        <div v-for="t in types" :key="t.uid" style="margin-bottom: 30px;">
            <h4>{{ t.libelle }}</h4>
            <div style="flex: 1; flex-direction: row; flex-wrap: wrap; ">
                <cabine v-for="c in cabines.filter(item => item.type === t.uid )" :key="c.uid" :cabineUid="c.uid" />
            </div>
        </div>
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex';
import cabine from '../../components/garderie/home/cabine.vue';
export default {
    components: {
        cabine
    },
    computed: {
        ...mapGetters({
            cabines: 'garderie/cabines',
            types: 'garderie/types'
        })
    }

}
</script>