<template>
    <div class="single-team" v-if="hasUser" :title="user.fullname" @click.prevent="selectedOnlineUser">
        <span class="team-user-img">
            <avatar :user-uid="userUid" size="xs" />
        </span>
        <span class="online"></span>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Avatar from '../defaultAvatar.vue'
export default {
    props: {
        userUid: {type: String, required: true}
    },
    components: {Avatar},
    methods: {
        ...mapMutations({
            setActiveUser: 'chat/SET_ACTIVE_USER'
        }),
        selectedOnlineUser(){
            this.setActiveUser(this.userUid)
        }
    },
    computed: {
        ...mapGetters({
            users: 'auth/users'
        }),
        user(){ return this.users.find(user => user.uid === this.userUid); },
        hasUser(){ return!!this.user; }
    }
}
</script>