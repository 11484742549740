<template>
    <img
        :src="userPhoto"
        class="img-thumbnail object-cover rounded-circle"
        :class="sizeClass"
        v-if="hasPhotoProfile"
    >
  <img v-else :src="photo" class="img-thumbnail object-cover rounded-circle" :class="sizeClass">
</template>

<script>
import md5 from 'md5';
import { mapGetters } from 'vuex'
export default {
    props:{
        userUid: {type: String, required: true},
        size: {type: String, default: 'xs'}
    },
    computed: {
        ...mapGetters({
            users: 'auth/users'
        }),
        user(){
            return this.users.find(user => user.uid === this.userUid);
        },
        hasUser(){
            return!!this.user;
        },
        userPhoto(){
            if(this.hasUser){
                const photo = this.user.photo
                if(photo){
                    return JSON.parse(photo).url
                }
            }
            return null
        },
        hasPhotoProfile(){
            return this.userPhoto!== null && this.userPhoto!== undefined
        },
        photo(){
            const hash = md5(this.userUid.trim().toLowerCase());
            return `https://www.gravatar.com/avatar/${hash}?d=identicon&f=y`;
        },
        sizeClass(){
            return `avatar-${this.size}`
        }
    }

}
</script>