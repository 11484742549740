<template>
  <div v-if="hasUser">{{ user.fullname }}</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {    
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {
        }
    },

    computed: {
        ...mapGetters({
            users: 'auth/users'
        }),
        user(){
            const u = this.users.find(item => item.uid === this.object.user)
            return u || null  // Return null if user not found in Vuex store
        },
        hasUser(){
            return this.user !== null && this.user !== undefined
        }
    },
    

}
</script>