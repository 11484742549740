import moment from 'moment'
 import { DEFAULT_LANGUAGE } from '../constants/app'
const SEXE = {
  m: 'Mâle',
  f: 'Femelle'
}

moment.locale(DEFAULT_LANGUAGE)

export const time = value => {
  if (value === null) { return '-' }
  let d = moment(value)
  return d.format('HH:mm')
}
export const date = value => {
  if (value === null) { return '-' }
  let d = moment(value)
  if(d.isSame(moment(), 'day')) return `Aujourd'hui`
  if(d.isSame(moment().subtract(1, 'day'))) return `Hier`
  return d.format('dddd D MMMM YYYY')
}
export const showTime = value => {
  if (value === null) { return '-' }
  let d = moment(value)
  let now = moment()
  if( now.format('X') < d.format('X') || now.format('X') - d.format('X')> 3600)  return d.format('llll:ss')
  return d.fromNow()

}

export const deepSearch = (object, search) =>{
  let elements = Object.values(object)
  for(var i = 0; i < elements.length; i++){
    if(elements[i] !== null && typeof elements[i] === 'string'  && elements[i].toLowerCase().includes(search.toLowerCase())){
      return true
    }
  }
  return false
}

export const humanReadableTime = value => {
  if (value === null) { return '-' }
  let d = moment(value);
  return d.fromNow()
}

export const age = value => {
  if (value === null) { return '-' }
  var diff = moment().diff(moment(value), 'milliseconds');
  var duration = moment.duration(diff);
  return duration.years() + ' ans '+ duration.months() + ' mois ' + duration.days() + ' Jours';
}

export const capitalize = value => {
  if (typeof value !== 'string') { return 'undefine' }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const systemReadableTime = value => {
  if (value === null) { return '-' }
  let d = moment(value);
  return d.format('L')
}

export const systemDateTime = value => {
  if (value === null) { return '-' }
  let d = moment(value);
  return d.format('llll:ss')
}

export const humanDateTime = value => {
  if (value === null) { return '-' }
  let d = moment(value);
  return d.format('DD-MM-YYYY HH:mm')
}

export const humanDate = value => {
  if (value === null) { return '-' }
  let d = moment(value);
  return d.format('DD-MM-YYYY')
}

export const truncate = (value, length) => {
  return value.substring(0, length)
}

export const libelle = (objet = {}) => {
  if (objet === null | objet === undefined) { return '-' }
  if (objet) { return objet.libelle }
  return 'Error in Objet'
}

export const sexe = (placeholder) => {
  if (placeholder !== 'f' && placeholder !== 'm') return 'error sexe'
  return SEXE[placeholder]
}

export const toLetter = (number) =>{
  const units = ["", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
  const teens = ["dix", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"];
  const tens = ["", "dix", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingt", "quatre-vingt-dix"];

  if (number === 0) return "zéro";

  if (number < 0) return "moins " + toLetter(Math.abs(number));

  let words = "";

  if (Math.floor(number / 1000000) > 0) {
      words += toLetter(Math.floor(number / 1000000)) + " million ";
      number %= 1000000;
  }

  if (Math.floor(number / 1000) > 0) {
      words += toLetter(Math.floor(number / 1000)) + " mille ";
      number %= 1000;
  }

  if (Math.floor(number / 100) > 0) {
      words += toLetter(Math.floor(number / 100)) + " cent ";
      number %= 100;
  }

  if (number > 0) {
      if (number < 20) {
          words += (words !== "" ? " " : "") + (number < 10 ? units[number] : teens[number - 10]);
      } else {
          words += (words !== "" ? " " : "") + tens[Math.floor(number / 10)];
          if (number % 10 > 0) {
              words += "-" + units[number % 10];
          }
      }
  }

  return words.trim();
}