<template>
  <div>
    <img :src="photo"  class="thumbnail" style="width: 70px; height: 70px;"/>
  </div>
</template>

<script>
export default {
    props: {
        object: {type: Object, required: true}
    },
    computed: {
        photo(){
            const firstPhoto = JSON.parse(this.object.photos)[0]
            return firstPhoto? firstPhoto.url : null;
        }
    }

}
</script>