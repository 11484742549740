<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div
      class="card-box"
      v-if="hasProduct"
    >
      <div class="row">
        <div class="col-10">

          <div class="product-detail">
            <product-images
              :photos="photos"
              :default-photo="photoPrincipale"
              :product="product"
            />
            <product-info
              :product="product"
              :type="type"
              :categorie="categorie"
              :prices="prices"
            />
            <!-- 
              <p>
                <strong>Tags:</strong> 
                <tag
                  v-for="(t, index) in productTags"
                  :key="index"
                  :tag-uid="t"
                />
              </p>
              <a
                href="javascript:void(0)"
                class="icon-button"
                data-toggle="modal"
                data-target="#add-tag"
              >
                <i class="las la-plus-circle" />
              </a>
            </div> -->
          </div>
        </div>
        <div class="col-2">
          <h5>Actions</h5>
          <div class="list-group">
            <a href="javascript:void(0)" class="list-group-item list-group-item-action">
              Modifier
            </a>
            <!-- <a href="#" class="list-group-item list-group-item-action">Dapibus ac facilisis in</a>
            <a href="#" class="list-group-item list-group-item-action">Morbi leo risus</a>
            <a href="#" class="list-group-item list-group-item-action">Porta ac consectetur ac</a> -->
            <a href="javascript:void(0)" class="list-group-item list-group-item-action" @click.prevent="deleteProduct">
              Supprimer
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" style="margin-top: 30px">
          <div class="product-description">
            <h2>Description</h2>
            <p>{{ product.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-box"
      v-else
    >
      <div class="text-center">
        <i class="las la-exclamation-triangle la-6x" />
        <p>
          Produit non trouvé. <router-link :to="{name: 'market-products'}">
            Retour aux produits
          </router-link>
        </p>
      </div>
    </div>
    
    <modal
      title="Ajout de Tag"
      id="add-tag"
      :footer="false"
    >
      <div class="form-header">
        <a
          href="javascript:void(0)"
          @click.prevent="addTag = !addTag"
        >
          <i
            class="las la-2x"
            :class="addTag ? 'la-times' : 'la-plus-circle'"
          />
        </a>
      </div>
      <div
        v-if="has_error"
        class="alert alert-danger"
      >
        <p>{{ error_msg }}</p>
      </div>
      <div v-show="!addTag">
        <div class="form-group">
          <label for="tag">Tag</label>
          <select
            class="form-control"
            v-select="{placeholder: 'Choisir le tag'}"
            v-model="selectingTag"
          >
            <option
              v-for="(t, index) in availableTags"
              :key="index"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group text-right">
          <a
            href="javascript:void(0)"
            @click.prevent="updateTagOnProduct"
            class="btn btn-primary btn-xs"
          >Enregistrer</a>
        </div>
      </div>
      <div v-show="addTag">
        <div class="form-group">
          <label for="add-tag">Nouveau Tag</label>
          <input
            type="text"
            name="add-tag"
            id="add-tag"
            class="form-control"
            v-model="newTagLibelle"
          >
        </div>
        <div class="form-group text-right">
          <a
            href="javascript:void(0)"
            @click.prevent="addNewTag"
            class="btn btn-danger btn-xs"
          >Ajouter</a>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../../components/modal.vue';
import Navbar from '../../components/navbar.vue';
import ProductImages from '../../components/market/product/productImages.vue'
import ProductInfo from '../../components/market/product/ProductInfo.vue'
// import PriceTr from '../../components/market/product/priceTr.vue';
// import Tag from '../../components/market/product/tag.vue'
import { ADD_FILTRE, DELETE_PRODUCT, UPDATE_PRODUCT } from '../../graphql/market';
const logo = require('../../assets/img/logo256.png')

export default {
    components: { 
      Navbar, 
      Modal, 
      // PriceTr, 
      // Tag, 
      ProductImages, 
      ProductInfo 
    },
    data() {
        return {
            navbarItems: [
                {
                  libelle: 'Market Place',
                  route: 'market-dashboard'
                },
                {
                    libelle: 'Produits',
                    route: 'market-products'
                },
                {
                    libelle: 'Détail du produit'
                }
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Market Place',
            pageDescription: 'Détail du produit',
            
            
            addTag: false,
            newTagLibelle: null,
            selectingTag: null,
            currentPhoto: logo // Initialisation de l'image principale
        }
    },
    methods: {
        ...mapMutations({
            setLoading: 'SET_LOADING',
            done: 'DONE'
        }),
        deleteProduct(){
          this.$apollo.mutate({
                mutation: DELETE_PRODUCT,
                variables: {
                    "uid": this.product.uid
                },
                update: ({ data }) => {
                    console.log(`Product ${this.product.libelle} deleted successfully `);
                    console.log(data);
                }
            }).then(() => {
              this.$router.push({name: 'market-home'})
            }).catch((error) => {
                console.error(error);
                this.has_error = true;
                this.error_msg = error;
            });
        },
        addNewTag() {
            this.$apollo.mutate({
                mutation: ADD_FILTRE,
                variables: {
                    "libelle": this.newTagLibelle,
                    "type": this.product.type
                },
                update: ({ data }) => {
                    console.log(`Tag add successfully on uid `);
                    console.log(data);
                }
            }).then(() => {
                this.newTagLibelle = null;
                this.has_error = false;
                this.error_msg = null;
                this.addTag = false;
            }).catch((error) => {
                console.error(error);
                this.has_error = true;
                this.error_msg = error;
            });
        },
        updateTagOnProduct() {
            let tags = JSON.parse(this.product.tags);
            tags.push(this.selectingTag);
            let data = {
                tags: JSON.stringify(tags)
            };
            console.log(data);
            this.$apollo.mutate({
                mutation: UPDATE_PRODUCT,
                variables: {
                    "uid": this.product.uid,
                    "product": data
                },
                update: ({ data }) => {
                    console.log(`Tag add successfully on uid `);
                    console.log(data);
                }
            }).then(() => {
                this.selectingTag = null;
                this.has_error = false;
                this.error_msg = null;
                this.addTag = false;
                this.done();
            }).catch((error) => {
                console.error(error);
                this.has_error = true;
                this.error_msg = error;
            });
        },
        
        updateCurrentPhoto(photoUrl) {
            this.currentPhoto = photoUrl;
        }
    },
    mounted(){
      if(this.hasPhoto) this.currentPhoto = this.photoPrincipale
    },
    computed: {
        ...mapGetters({
            types: 'market/typeProducts',
            categories: 'market/categories',
            filtres: 'market/filtres',
            products: 'market/products',
            productPrices: 'market/productPrices'
        }),
        product() {
            let p = this.products.find(item => item.uid === this.$route.params.uid);
            return p;
        },
        hasProduct() {
            return this.product !== null && this.product !== undefined;
        },
        hasPhoto() {
            if (this.hasProduct) {
                let photos = JSON.parse(this.product.photos);
                return photos.length > 0;
            }
            return false;
        },
        photoPrincipale() {
            if (this.hasPhoto) {
                let photos = JSON.parse(this.product.photos);
                return photos[0].url;
            }
            return logo;
        },
        
        photos() {
            if (this.hasPhoto) return JSON.parse(this.product.photos);
            return [];
        },
        categorie() {
            let c = this.categories.find(item => item.uid === this.product.categorie);
            if (c) return c.libelle;
            return '-';
        },
        type() {
            let t = this.types.find(item => item.uid === this.product.type);
            if (t) return t.libelle;
            return '-';
        },
        prices() {
            return [...this.productPrices].filter(item => item.product === this.product.uid);
        },
        availableTags() {
            return [...this.filtres].filter(item => item.type === this.product.type);
        },
        productTags() {
            let tags = JSON.parse(this.product.tags);
            return tags;
        }
    },
    watch: {
        product(newProduct) {
            if (newProduct) {
                this.currentPhoto = this.photoPrincipale;
            }
        }
    }
};
</script>


<style lang="scss" scoped>
.card-box {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}

.product-detail {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .product-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-image {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .thumbnails {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 10px;

      a {
        .thumbnail {
          width: 80px;
          height: 80px;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .product-info {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #333;

      strong {
        font-weight: bold;
        color: #555;
      }

      &.product-price {
        font-size: 18px;
        color: #007bff;
      }
    }

    .icon-button {
      margin-left: 10px;
      color: #007bff;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #0056b3;
      }
    }

    .price-table {
      margin-top: 20px;

      table {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}

.product-description {
  margin-top: 20px;

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }
}

.text-center {
  text-align: center;
  font-size: 18px;
  color: #333;

  i {
    color: #ff9800;
  }

  p {
    margin-top: 10px;
    font-size: 16px;
  }

  a {
    color: #007bff;

    &:hover {
      color: #0056b3;
    }
  }
}

.form-check-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
