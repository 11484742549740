<template>
  <div
    class="person"
    :class="isActiveUser?  'active': ''"
    :data-chat="dataChat"
    @click.prevent="handleUserClick"
  >
    <div class="user-info">
      <div class="user-head mr-2">
        <avatar :user-uid="user.uid" size="sm"/>
      </div>
      <div class="user-body">
        <h5
          class="text-truncate strong mb-0 mt-1 chat-user-name"
          :data-name="fullname"
        >
          {{ fullname }}
        </h5>
        <p class="text-muted font-11 text-truncate mb-0">
          {{ lastMessage ? lastMessage.content : '' }}
        </p>
      </div>
      <div class="user-footer ml-2 text-right">
        <span class="chat-time font-10 text-success-teal" v-if="lastMessage !== null">{{ lastMessage.createdAt |time }}</span>
        <div>
          <!-- <span class="pinned"><i class="las la-thumbtack" /></span> -->
          <!-- <span class="chat-count">99</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Avatar from '../defaultAvatar.vue'
import { GET_CLIENT_LAST_HANDLED_SESSION, GET_CLIENT_LAST_MESSAGE } from '../../graphql/chat';
import socket from '../../plugins/socket'
export default {
    props: {
        user: {type: Object, required: true}
    },
    components: {Avatar},
    data(){
        return {
          session: null,
          loadSession: true,
          loadLastMessage: true,
          lastSession: null,
          lastMessage: null
        }
    },
    mounted(){
      this.loadSession = false
      socket.on('receive_message', payload => {
        // console.log(this.userSessions)
        if(this.userSessions.map(item => item.uid).includes(payload.session)){
          this.lastMessage = payload
        }
      }) 
    },
    apollo: {
        loadUserLastMessage: {
          query: GET_CLIENT_LAST_MESSAGE,
          skip () {
            return this.loadLastMessage
          },
          variables(){
            return {
                "session": this.lastSession.uid,
            }
          },
          error (error) {
            this.error_msg = JSON.stringify(error.message)
            this.loadLastMessage = true
          },
          update ({lastSessionMessage}) {
            if(lastSessionMessage){
              this.lastMessage = lastSessionMessage
            }
            this.loadLastMessage = true
          }
        },
        loadProduts: {
            query: GET_CLIENT_LAST_HANDLED_SESSION,
            skip () {
                return this.loadSession
            },
            variables(){
                return {
                    "client": this.user.uid,
                }
            },
            error (error) {
                this.error_msg = JSON.stringify(error.message)
                this.loadSession = true
            },
            update ({lastHandledChatSession}) {
                if(lastHandledChatSession){
                  // console.log(lastHandledChatSession)
                  this.lastSession = lastHandledChatSession
                  this.loadLastMessage = false
                }
                this.loadSession = true
            }
        }
    },
    methods: {
      ...mapMutations({
        setCurrentSession: 'chat/SET_CURRENT_SESSION',
        setActiveUser: 'chat/SET_ACTIVE_USER'
      }),
      handleUserClick(){
        this.setActiveUser(this.user.uid)
      }
      
    },
    computed: {
        ...mapGetters({
          activeUser: 'chat/activeUser',
          sessions: 'chat/sessions'
        }),
        userSessions(){
          return [...this.sessions].filter(item => item.client === this.user.uid)
        },
        hasUser(){
            return this.user !== null && this.user !== undefined
        },
        dataChat(){
            if(this.hasUser) return 'person'+this.user.uid
            return 'person0'
        },
        fullname(){
            if(this.hasUser) return this.user.fullname
            return '-'
        },
        hasPhoto(){
            if(this.hasUser) return this.user.photo !== null
            return false
        },
        isActiveUser(){
          return this.activeUser === this.user.uid
        }
    }
}
</script>

<style>

</style>