<template>
    <div>
      <h4>Type de garde</h4>
        
      <data-table
        :data-source="pensions"
        :headers="headers"
        table-class="table-striped table-hover"
      >
        <template slot="actions">
          <a
            href="javascript:void(0)"
            class="btn btn-outline-primary btn-icon"
            data-toggle="modal"
            data-target="#add-pension"
          >
            <i class="icofont icofont-plus" />
          </a>
        </template>
      </data-table>
            
      <modal
        title="Ajout d'une pension de garde"
        id="add-pension"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="adding"
        >
          <div
            class="alert alert-danger"
            v-if="has_error"
          >
            {{ error_msg }}
          </div>
          <div class="form-group">
            <label for="add-espce">Espece</label>
            <select class="form-control" id="add-espece" name="add-espece" v-model="espece" v-select="{placeholder: 'Selectionnez l\'espèce'}">
              <option></option>
              <option v-for="e in especes" :key="e.uid" :value="e.uid">{{ e.libelle }}</option>
            </select>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="add-min-poids">Poids minimum (Kg)</label>
                <input
                  id="add-min-poids"
                  name="add-min-poids"
                  type="number"
                  v-model="minPoids"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="add-max-poids">Poids maximum (Kg)</label>
                <input
                  id="add-max-poids"
                  name="add-max-poids"
                  type="number"
                  v-model="maxPoids"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="add-amount">Montant (Fcfa)</label>
            <input
              id="add-amount"
              name="add-amount"
              type="number"
              class="form-control"
              v-model="amount"
            />
          </div>

          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </form>
      </modal>
    
      <modal
        title="Modification de la pension de garde"
        id="update-pension"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="editing"
        >
          <div
            class="alert alert-danger"
            v-if="has_error"
          >
            {{ error_msg }}
          </div>
          <div class="form-group">
            <label for="update-espce">Espece</label>
            <select 
              class="form-control" 
              id="update-espece"
              name="update-espece" 
              v-model="espece" 
              v-select="{placeholder: 'Selectionnez l\'espèce'}"
            >
              <option></option>
              <option v-for="e in especes" :key="e.uid" :value="e.uid">{{ e.libelle }}</option>
            </select>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="update-min-poids">Poids minimum (Kg)</label>
                <input
                  id="update-min-poids"
                  name="update-min-poids"
                  type="number"
                  v-model="minPoids"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="update-max-poids">Poids maximum (Kg)</label>
                <input
                  id="update-max-poids"
                  name="update-max-poids"
                  type="number"
                  v-model="maxPoids"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="update-amount">Montant (Fcfa)</label>
            <input
              id="update-amount"
              name="update-amount"
              type="number"
              class="form-control"
              v-model="amount"
            />
          </div>
          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </form>
      </modal>
    </div>
  </template>
    
    <script>
    import {mapGetters, mapMutations} from 'vuex'
    import DataTable from '../../../components/dataTable/local.vue'
    import { COMPONENT_TYPE, NUMERIC_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType'
    import Modal from '../../../components/modal.vue'
    import { Select } from '../../../directive/select'
    import { ADD_GARDE_PENSION, UPDATE_GARDE_PENSION } from '../../../graphql/garderie'
    const Actions = () => import('../../../components/admin/garderie/pensionAction.vue')
    const Espece = () => import('../../../components/admin/garderie/pensionEspece.vue')
    export default {
        components: { DataTable, Modal},
        directives: {Select},
        data(){
            return {
              espece: null,
              minPoids: null,
              maxPoids: null,
              amount: null,
              has_error: false,
              error_msg: null
            }
        },
        watch: {
            selectedType: {
                handler(){
                    if(this.selectedType !== null) {
                        this.espece = this.selectedType.espece
                        this.minPoids = this.selectedType.minPoids
                        this.maxPoids = this.selectedType.maxPoids
                        this.amount = this.selectedType.amount
                    }
                },
                deep: true
            }
        },
        methods: {
            ...mapMutations({
                done: 'DONE',
                setSelectedObject: 'SET_SELECTED_OBJECT'
            }),
            initForm(){
                this.setSelectedObject(null)
                this.espece = null
                this.minPoids = null
                this.maxPoids = null
                this.amount = null
                this.has_error = false
                this.error_msg = null
            },
            adding(){
                let data = {
                  espece: this.espece,
                  minPoids: parseFloat(this.minPoids),
                  maxPoids: parseFloat(this.maxPoids),
                  amount: parseFloat(this.amount)
                }
                this.$apollo.mutate({
                    mutation: ADD_GARDE_PENSION,
                    variables: {
                        "pension": {...data}
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Pension de garde ${this.espece} ${this.minPoids}-${this.maxPoids}: ${this.amount}Fcfa add successfully`)
                    }
                  }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            },
            editing(){
              let data = {
                  espece: this.espece,
                  minPoids: parseFloat(this.minPoids),
                  maxPoids: parseFloat(this.maxPoids),
                  amount: parseFloat(this.amount)
                }
                this.$apollo.mutate({
                    mutation: UPDATE_GARDE_PENSION,
                    variables: {
                        "pension": {...data},
                        "uid": this.selectedType.uid
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Pension de garde ${this.espece} ${this.minPoids}-${this.maxPoids}: ${this.amount}Fcfa on ${this.selectedType.uid} updated successfully`)
                    }
                  }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
        },
        computed: {
            ...mapGetters({
                pensions: 'garderie/pensions',
                especes: 'identification/especes',
                selectedType: 'selectedObject'
            }),
            
            headers(){
                return [
                    {label: 'ID', name: 'uid', type: TEXT_TYPE},
                    {label: 'Espèce', name: 'espece', type: COMPONENT_TYPE, component: Espece},
                    {label: 'Poids min(Kg)', name: 'minPoids', type: NUMERIC_TYPE},
                    {label: 'Poids Max(Kg)', name: 'maxPoids', type: NUMERIC_TYPE},
                    {label: 'Montant (Fcfa)', name: 'amount', type: NUMERIC_TYPE},
                    {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
                ]
            }
        }
    }
    </script>
    
    <style>
    
    </style>