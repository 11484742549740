<template>
  <div v-if="hasType">
    {{ type.libelle }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        object: {type: Object, required: true}
    },
    computed: {
        ...mapGetters({
            types: 'market/typeProducts'
        }),
        type(){
            return this.types.find(t => t.uid === this.object.type);
        },
        hasType(){
            return!!this.type;
        }
    }

}
</script>