<template>
    <div class="mr-3" v-if="hasUser">
        <avatar
            :user-uid="userUid"
            size="md"
        />
        <p class="font-10 strong text-center mb-0">
            {{user.fullname}}
        </p>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Avatar from '../../defaultAvatar.vue'
export default {
    props: {
        userUid: {type: String, required: true}
    },
    components: {
        Avatar
    },
    computed: {
        ...mapGetters({
            users: 'auth/users'
        }),
        user(){
            return this.users.find(user => user.uid === this.userUid)
        },
        hasUser(){
            return this.user !== null && this.user !== undefined
        }
    }
}
</script>