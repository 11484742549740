<template>
    <div>
      {{ espece }}
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return  {
  
          }
      },
      computed: {
          ...mapGetters({
              especes: 'identification/especes',
          }),
          espece(){
              let e = this.especes.find(item => item.uid === this.object.espece)
              return e? e.libelle: '-'
          }
      }
  
  }
  </script>