<template>
    <div class="actions">
      <a
        href="javascript:void(0)"
        class="btn btn-primary btn-icon"
        data-toggle="modal"
        data-target="#update-activity-garde"
        @click.prevent="setSelected"
      > 
        <i class="icofont icofont-pencil" />
      </a>
      <a
        href="javascript:void(0)"
        class="btn btn-danger btn-sm"
        @click.prevent="deleting"
      > 
        <i class="icofont icofont-close" />
      </a>
    </div>
  </template>
    
    <script>
    import { mapGetters, mapMutations } from 'vuex';
import { DELETE_GARDE_ACTIVITY  } from '../../../graphql/garderie';
    export default {
        props: {object: {type: Object, required: true}},
        data(){
            return  {
    
            }
        },
        methods: {
            ...mapMutations({
                setSelectedObject: 'SET_SELECTED_OBJECT'
            }),
            setSelected(){
                this.setSelectedObject(this.object)
            },
            deleting(){
                this.$apollo.mutate({
                    mutation: DELETE_GARDE_ACTIVITY,
                    variables: {
                        "uid": this.object.uid
                    },
                    update: (data) => {
                        if(data) console.log(`Activity de garde ${this.object.libelle} at ${this.object.uid} deleted successfully`)
                        else console.log(`Error in deletting activité de garde ${this.object.libelle}`)
                    }
                  }).then(() => {
                    
                  })
    
            }
        },
        computed: {
            ...mapGetters({
    
            })
        }
    }
    </script>
    
    <style>
    
    </style>