<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <form
      class="form-horizontal"
      @submit.prevent="adding"
    >
      <div
        class=" alert alert-danger"
        v-if="has_error"
      >
        <p>{{ error_msg }}</p>
      </div>
      <div class="form-group">
        <animal-detail :show-detail="true" />
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="dateOperation">Date de la demande</label>
            <datepicker
              id="dateOperation"
              name="dateOperation"
              input-class="form-control required"
              :disabled-dates="disabledOperationDate"
              format="dd/MM/yyyy"
              placeholder="dd/mm/yyyy"
              v-model="operationDate"
            />
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 40px; justify-items: center;">
        <div class="col-6">
          <h5>Les Analyses</h5>
        </div>
        <div class="col-6 actions text-right">
          <a class="btn btn-primary" @click.prevent="nbAnalyse++"><i class="icofont icofont-plus"></i></a>
          <a class="btn btn-danger" @click.prevent="decrementAnalyses"><i class="icofont icofont-close"></i></a>
        </div>
      </div>

      <add-analyse-form  v-for="k in nbAnalyse" :key="k" :index="k" @analyse="addAnalyse"/>
      
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="info">Information Supplementaire</label>
            <textarea
              class="form-control"
              name="infp"
              id="info"
              rows="5"
              v-model="infoSupplementaire"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button
            type="submit"
            class="btn btn-primary"
            :class="loading? 'disabled': ''"
          >
            <span
              class="spinner-border m-1"
              role="status"
              v-if="loading"
            /> 
            <span v-else> Demander </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import Datepicker from 'vuejs-datepicker'
import navbar from '../../../components/navbar.vue'
import AddAnalyseForm from '../../../components/sanitaire/analyse/addAnalyseForm.vue'
import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
import { Select } from '../../../directive/select'
import {ADD_ANALYSE} from '../../../graphql/sanitaire'
export default {
    components: {navbar, AnimalDetail, Datepicker, AddAnalyseForm},
    directives: {Select},
    data(){
        return {
          navbarItems: [
            {
                libelle: 'Sanitaire'
            },
            {
                libelle: 'Examen para-clinique',
                route: 'analyses'
            },
            {
              libelle: 'Demande d\'analyse'
            }
          ],
          pageIcon: 'la-flask',
          pageTitle: 'Demande d\'analyse',
          pageDescription: 'Ajouter une demande d\'analyse', 
            
          analyses: [],
          infoSupplementaire: null,
          operationDate: new Date(),
          has_error:false,
          error_msg: null,
          nbAnalyse: 1
        }
    },
    mounted(){
      this.initForm()
    },
    methods: {
        ...mapMutations({
          setLoading: 'SET_LOADING'
        }),
        decrementAnalyses(){
          if(this.nbAnalyse > 0){
            this.analyses = this.analyses.filter(item => item.order !== this.nbAnalyse)
            this.nbAnalyse--
          } 
        },  
        initForm(){
          this.setLoading(false)
          this.analyses = []
          this.infoSupplementaire =  null
          this.operationDate = new Date()
          this.has_error = false
          this.error_msg =  null
        },
        addAnalyse(payload){
          console.log(payload)
          this.analyses = this.analyses.filter(item => item.order !== payload.order)
          this.analyses.push(payload)
        },
        adding(){
            this.setLoading(true)
            let data = {
                animal: this.selectedAnimal,
                analyses: JSON.stringify(this.analyses),
                infoSupplementaire: this.infoSupplementaire,
                operationDate: this.operationDate
              }
              this.$apollo.mutate({
                mutation: ADD_ANALYSE,
                variables: {
                    "analyse": {
                        ...data
                    }
                },
                update: (data) => {
                    console.log(`Analyse add successfully on uid ${data.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.$router.push({name: 'analyses'})
            }).catch((error) => {
                this.setLoading(false)
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
      ...mapGetters({
          types: 'sanitaire/typeAnalyses',
          categories: 'sanitaire/categorieAnalyses',
          typeRecherches: 'sanitaire/typeRecherches',
          examens: 'sanitaire/examens',
          selectedAnimal: 'identification/selectedAnimal',
          animaux: 'identification/animaux',
          loading: 'loading'
      }),
      disabledOperationDate () {
          let animal = [...this.animaux].find(item => item.uid === this.selectedAnimal)
          if(animal !== null && animal !== undefined){
              return {
                  to: new Date(animal.dateNaissance),
                  from: new Date()
              }
          }
          return {
              to: new Date(),
              from: new Date()
          }
      },
    }
}
</script>

<style>

</style>