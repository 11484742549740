<template>
    <div>
      <h4>Type de garde</h4>
        
      <data-table
        :data-source="types"
        :headers="headers"
        table-class="table-striped table-hover"
      >
        <template slot="actions">
          <a
            href="javascript:void(0)"
            class="btn btn-outline-primary btn-icon"
            data-toggle="modal"
            data-target="#add-type-garde"
          >
            <i class="icofont icofont-plus" />
          </a>
        </template>
      </data-table>
            
      <modal
        title="Ajout du type de garde"
        id="add-type-garde"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="adding"
        >
          <div
            class="alert alert-danger"
            v-if="has_error"
          >
            {{ error_msg }}
          </div>
          <div class="form-group">
            <label for="add-libelle">Libelle</label>
            <input
              id="add-libelle"
              name="add-libelle"
              type="text"
              class="form-control"
              v-model="libelle"
            >
          </div>
          <div class="form-group">
            <label for="add-code">Code</label>
            <input
              id="add-code"
              name="add-code"
              type="text"
              class="form-control"
              v-model="code"
            >
          </div>
          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </form>
      </modal>
    
      <modal
        title="Modification du type de garde"
        id="update-type-garde"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="editing"
        >
          <div
            class="alert alert-danger"
            v-if="has_error"
          >
            {{ error_msg }}
          </div>
          <div class="form-group">
            <label for="update-libelle">Libelle</label>
            <input
              id="update-libelle"
              name="update-libelle"
              type="text"
              class="form-control"
              v-model="libelle"
            >
          </div>
          <div class="form-group">
            <label for="update-code">Code</label>
            <input
              id="update-code"
              name="update-code"
              type="text"
              class="form-control"
              v-model="code"
            >
          </div>
          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </form>
      </modal>
    </div>
  </template>
    
    <script>
    import {mapGetters, mapMutations} from 'vuex'
    import DataTable from '../../../components/dataTable/local.vue'
    import { COMPONENT_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType'
    import Modal from '../../../components/modal.vue'
    import { Select } from '../../../directive/select'
    import { ADD_TYPE_GARDE, UPDATE_TYPE_GARDE } from '../../../graphql/garderie'
    const Actions = () => import('../../../components/admin/garderie/typeActions.vue')
    export default {
        components: { DataTable, Modal},
        directives: {Select},
        data(){
            return {
                libelle: null,
                code: null,
                has_error: false,
                error_msg: null
            }
        },
        watch: {
            selectedType: {
                handler(){
                    if(this.selectedType !== null) {
                        this.libelle = this.selectedType.libelle
                        this.code = this.selectedType.code
                    }
                },
                deep: true
            }
        },
        methods: {
            ...mapMutations({
                done: 'DONE',
                setSelectedObject: 'SET_SELECTED_OBJECT'
            }),
            initForm(){
                this.setSelectedObject(null)
                this.libelle = null
                this.code = null
                this.has_error = false
                this.error_msg = null
            },
            adding(){
                this.$apollo.mutate({
                    mutation: ADD_TYPE_GARDE,
                    variables: {
                        "libelle": this.libelle,
                        "code": this.code
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Type de garde ${this.libelle} add successfully`)
                    }
                  }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            },
            editing(){
                this.$apollo.mutate({
                    mutation: UPDATE_TYPE_GARDE,
                    variables: {
                        "libelle": this.libelle,
                        "code": this.code,
                        "uid": this.selectedType.uid
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Type de garde ${this.libelle} on ${this.selectedType.uid} updated successfully`)
                    }
                  }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
        },
        computed: {
            ...mapGetters({
                types: 'garderie/types',
                selectedType: 'selectedObject'
            }),
            
            headers(){
                return [
                    {label: 'ID', name: 'uid', type: TEXT_TYPE},
                    {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                    {label: 'Code', name: 'code', type: TEXT_TYPE},
                    {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
                ]
            }
        }
    }
    </script>
    
    <style>
    
    </style>