<template>
  <div class="teams">
    <h5 class="chat-headings">Utilisateurs <span>Online {{ filterOnlineUsers.length }}</span></h5>
    <div class="team-container">
        <online-user v-for="u in filterOnlineUsers" :key="u" :user-uid="u" />
    </div>
</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import OnlineUser from './onlineUser.vue'
export default {
    components: {OnlineUser},
    mounted(){
        
    },
    methods: {
       ...mapMutations({ }),
       
    },
    computed:{
        ...mapGetters({
            onlineUsers: 'chat/connectedUsers',
            me: 'auth/me'
        }),
        filterOnlineUsers(){
            return Array.from(new Set(this.onlineUsers.map(item =>  item.uid)))//.filter(item => item !== this.me.uid)
        }
    }
}
</script>