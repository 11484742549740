<template>
    <div>
        <a
        href="javascript:void(0)"
        class="btn btn-primary btn-icon"
        data-toggle="modal"
        data-target="#update-chat-subject"
        @click.prevent="setSelected"
        > 
        <i class="icofont icofont-pencil" />
        </a>
      <a
        href="javascript:void(0)"
        class="btn btn-third btn-sm"
        @click.prevent="deleting"
      > 
        <i class="icofont icofont-close" />
      </a>
    </div>
  </template>
  
  <script>
  import { DELETE_CHAT_SUBJECT } from '../../graphql/chat';
  import { mapMutations } from 'vuex';
  export default {
      props: {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
        ...mapMutations({
              setSelectedObject: 'SET_SELECTED_OBJECT'
          }),
          setSelected(){
              this.setSelectedObject(this.object)
          },
          deleting(){
                this.$apollo.mutate({
                    mutation: DELETE_CHAT_SUBJECT,
                    variables: {
                        "uid": this.object.uid
                    },
                    update: (data) => {
                        if(data) console.log(`Chat subject ${this.object.uid } deleted successfully`)
                        else console.log(`Error in deletting chat subject ${this.object.uid}`)
                    }
                  })
            }
      }
  }
  </script>
  
  <style>
  
  </style>